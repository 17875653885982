.contact-wrapper {
  background-color: #fff;
  background-image: url('https://d365pq86x330zn.cloudfront.net/216i7661-6669-4116-6701-15551Bu6ii97.png');
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  box-sizing: border-box;
  padding: 30px;
}
.contact {
  max-width: 1160px;
  margin: auto;
  display: flex;
  align-items: center;
}
.contact-text {
  flex: 1 1 auto;
  width: 50%;
  padding: 10px;
}
.contact-text h2 {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 48px;
  color: #FF385C;
  margin: 0 0 25px;
}
.contact-text p {
  font-weight: 600;
  font-size: 17px;
  color: #565A5C;
  margin: 0 0 20px;
}
.contact-phone {
  flex: 1 1 auto;
  width: 50%;
  padding: 10px;
}
.phone-wrapper {
  display: inline-block;
  position: relative;
  margin: auto;
}
.phone-wrapper .phone {
  max-width: 100%;
  margin: auto;
}
.phone-content {
  position: absolute;
  top: 40%;
  left: 2%;
  right: 0;
  display: flex;
  justify-content: center;
}
.phone-content .btn {
  padding: 12px 24px;
  font-size: 14px;
}

@media (max-width: 1023px) {
  .contact {
    flex-flow: column;
  }
  .contact-text,
  .contact-phone {
    width: auto;
  }
}

@media (max-width: 767px) {
  .phone-wrapper {
    width: auto;
  }
  .phone-wrapper .phone {
    opacity: 0;
    display: none;
  }
  .phone-wrapper .phone-content {
    position: relative;
  }
}