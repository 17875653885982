.twenty7tec-banner {
  background-color: #7DB49C;
  overflow: auto;

  .twenty7tec-banner-contents {
    max-width: 1024px;
    margin: 50px auto;
    display: flex;
    align-items: center;

    .twenty7tec-banner-content {
      flex: 1 1 auto;
      padding: 20px;

      img {
        max-width: 100%;
      }

      h1 {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 48px;
        line-height: 1;
        color: #FFFFFF;
        margin: 0 0 25px 0;
      }

      p {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 1.2;
        color: #FFFFFF;
        margin: 0 0 30px 0;
      }
      
      .btn {
        outline: none;
        background: none;
        border: none;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 12px;
        line-height: 1;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        display: block;
        width: max-content;
        margin: 0 0 20px 0;
        padding: 17px 30px;
        cursor: pointer;

        &.btn-primary {
          background: #0C4584;
          color: #FFFFFF;
          border-radius: 50px;
        }

        &.btn-secondary {
          border: 2px solid #FFFFFF;
          color: #FFFFFF;
          border-radius: 50px;
        }
      }

      &.element {
        width: 60%;
      }

      &.details {
        width: 40%;
      }

      @media (max-width: 1023px) {
        h1 {
          font-size: 36px;
        }

        p {
          font-size: 20px;
        }

        .btn {
          font-size: 20px;
          padding: 12px 24px;
        }
      }
    }

    @media (max-width: 767px) {
      margin: 25px auto;
      display: block;

      .twenty7tec-banner-content {
        &.element,
        &.details {
          width: 100%;
        }
      }
    }
  }

  .twenty7tec-banner-bottom {
    background-color: transparent;
    margin-bottom: 0;
    position: relative;
    
    &::before {
      content: " ";
      background-color: #70212F;
      height: 1px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }
    
    &> div {
      clip-path: polygon(0 0, 0 100%, 100% 100%);
      background: #70212F;
      height: 160px;

      @media (max-width: 1023px) { height: 80px; }

      @media (max-width: 767px) { height: 40px; }
    }
  }
}