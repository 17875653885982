.contents-wrapper {
  padding: 50px 30px;
  background-color: #FBFBFB;
}
.contents {
  max-width: 1160px;
  margin: auto;
}
.contents-title {
  padding: 50px 0;
}
.contents-title h1 {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 40px;
  color: #565A5C;
  margin: 0 0 5px;
  text-align: center;
}
.contents h2 {
  font-weight: 600;
  font-size: 28px;
  color: #FF385C;
}
.contents p {
  color: #565A5C;
}
.contents a {
  color: #FF385C;
  text-decoration: none;
}