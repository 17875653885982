.banner-hpac-plugin-wrapper {
  padding: 0;
  max-width: 100vw;
  background-color: #f3f3f3;
  overflow: hidden;
  z-index: -2;
}
.banner-hpac-plugin-wrapper .banner-hpac-plugin {
  max-width: 100vw;
  margin: auto;
  padding: 50px;
}
.banner-hpac-plugin.red {
  background-color: #DD3251;
}
.banner-hpac-plugin-wrapper .banner-hpac-plugin .cys {
  font-weight: 600;
  font-size: 64px;
  color: #FF385C;
  margin: 0 0 50px;
  text-align: center;
}
.banner-hpac-plugin.red {
  background-color: #DD3251;
}
.banner-hpac-plugin-wrapper .banner-hpac-plugin .pk-rec {
  font-weight: 600;
  font-size: 32px;
  color: #fff;
  margin: 0 0 10px;
  text-align: center;
}
.banner-hpac-plugin-wrapper .banner-hpac-plugin .new-way-to-sell {
  font-weight: 700;
  font-size: 64px;
  text-transform: uppercase;
  margin: 0 0 50px;
  text-align: center;
  background: linear-gradient(180deg, #FFFFFF 83.85%, #E894A3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.top-banner {
  height: calc(100vh - 100px);
  position: relative;
}
.conversation-body.bottom-part {
  padding: 28px 10px;
}
.conversation-body.bottom-part.center {
  text-align: center;
}
.conversation-body.bottom-part img {
  max-width: 100%;
}

.bottom-banner {
  padding: 50px 30px;
  background-color: #FFFFFF;
}
.bottom-banner-inner {
  max-width: 1160px;
  margin: auto;
}
.bottom-banner-text h2 {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 26px;
  color: #565A5C;
  margin: 0 0 50px;
  text-align: center;
}
.mwc {
  padding: 30px;
  padding-bottom: 70px;
}
.mwc-inner {
  background-color: #FFFFFF;
  background-image: url('https://d365pq86x330zn.cloudfront.net/4161111c-2e1e-4133-3261-2131e16es2e2.png');
  background-size: cover;
  background-position: bottom;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 50px;
  text-align: center;
}
.mwc-inner h2 {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 40px;
  color: #424242;
  margin: 0 10px 20px;
}
.mwc-inner h3 {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 26px;
  color: #424242;
  margin: 0 10px 20px;
}
.mwc-inner h3 span {
  color: #FF385C;
}
.mwc-inner p {
  /* text-transform: capitalize; */
  font-weight: 400;
  font-size: 18px;
  color: #565A5C;
  margin: 0 10px 50px;
}
.mwc-laptop {
  display: inline-block;
  position: relative;
}
.mwc-laptop img {
  max-width: 100%;
  z-index: 1;
}
.mwc-laptop iframe {
  border: none;
  outline: none;
  position: absolute;
  top: 3.3%;
  left: 11.1%;
  width: 103.9%;
  height: 113%;
  background: white;
  border-top-left-radius: 3.2%;
  border-top-right-radius: 3.2%;
  -ms-zoom: 0.75;
  -moz-transform: scale(0.75);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.75);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.75);
  -webkit-transform-origin: 0 0;
}
.mwc-inner.pro {
  display: grid;
  grid-template-columns: 1fr 270px;
  grid-gap: 30px;
  text-align: left;
  background-image: url('https://d365pq86x330zn.cloudfront.net/5a261965-1999-4069-7569-asM878003551.png');
}
.mwc-ipad {
  display: inline-block;
  position: relative;
}
.mwc-ipad img {
  max-width: 100%;
  z-index: 1;
}
.mwc-ipad iframe {
  border: none;
  outline: none;
  position: absolute;
  top: 8%;
  left: 6%;
  width: 164%;
  height: 159%;
  background: white;
  border-radius: 3.2%;
  -ms-zoom: 0.5;
  -moz-transform: scale(0.5);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.5);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.5);
  -webkit-transform-origin: 0 0;
}
.mwc-inner.sma {
  display: grid;
  grid-template-columns: 325px 1fr;
  grid-gap: 30px;
  text-align: left;
  background-image: url('https://d365pq86x330zn.cloudfront.net/816s091M-M868-410a-1sa1-06s61168aMs6.png');
}
.mwc-inner.sma .banner-animation {
  width: 100%;
}
.mwc-inner.sma .banner-animation-proxy {
  display: none;
}

.banner-hpac-plugin-wrapper .plugin-splitter {
  display: flex;
  align-items: center;
  margin: 20px 0 50px;
}
.banner-hpac-plugin-wrapper .plugin-splitter .center {
  flex: 0 1 auto;
  width: max-content;
  display: flex;
  align-items: center;
}
.banner-hpac-plugin-wrapper .plugin-splitter .side {
  flex: 1 1 auto;
  height: 5px;
  background-color: #dcdcdc;
  width: 100%;
  margin: 20px;
}
.banner-hpac-plugin-wrapper .plugin-splitter .btn.btn-fom {
  background: linear-gradient(180deg, #E66F6F 0%, #FF385C 100%);
  box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.25);
  border-radius: 28px;
  font-weight: 700;
  font-size: 28px;
  color: #fff;
  text-align: center;
  display: block;
  width: max-content;
  margin-left: 20px;
}
.banner-hpac-plugin-wrapper .plugin-splitter .btn.btn-fom.disabled {
  background: linear-gradient(#cecece, #a7a7a7);
  pointer-events: none;
}
.banner-hpac-plugin-wrapper .iframed-hpac-plugin {
  width: 100%;
  margin: auto;
  background-color: #ffffff;
  height: calc(100vh - 100px);
}
.banner-hpac-plugin-wrapper .iframed-hpac-plugin iframe {
  border: none;
  outline: none;
  display: block;
  width: 100%;
  height: calc(100vh - 100px);
}
.banner-hpac-plugin-wrapper .facetime-fact-find-wrapper {
  display: grid;
  grid-template-columns: 1fr 1.4fr;
}
.banner-hpac-plugin-wrapper .facetime-fact-find {
  position: relative;
}
.banner-hpac-plugin-wrapper .facetime-fact-find video {
  position: absolute;
  inset: 0;
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.banner-hpac-plugin-wrapper .cs-img {
  max-width: 100%;
}

@media (max-width: 1100px) {
  .mwc-inner.sma{
    grid-template-columns: 1fr;
  }
}
@media (max-width: 900px) {
  .mwc-inner.pro {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 767px) {
  .mwc-inner.sma .banner-animation-proxy {
    display: block;
    max-width: 100%;
  }
  .mwc-inner h2 {
    font-size: 28px;
  }
  .mwc-inner h3 {
    font-size: 20px;
  }
  .banner-hpac-plugin-wrapper .banner-hpac-plugin {
    padding: 20px;
  }
  .banner-hpac-plugin-wrapper .banner-hpac-plugin .cys {
    font-size: 32px;
    margin: 0 0 20px;
  }
  .banner-hpac-plugin-wrapper .banner-hpac-plugin .new-way-to-sell {
    font-size: 32px;
  }
  .banner-hpac-plugin-wrapper .banner-hpac-plugin .pk-rec {
    font-size: 22px;
  }
  .banner-hpac-plugin-wrapper .plugin-splitter .side {
    display: none;
  }
  .banner-hpac-plugin-wrapper .plugin-splitter .center {
    display: block;
    flex: 1 1 auto;
  }
  .banner-hpac-plugin-wrapper .plugin-splitter .center .btn {
    width: 100%;
    margin: 10px 0;
  }
}

@media (max-width: 424px) {
  .bottom-banner {
    padding: 30px 10px;
  }
  .bottom-banner-text h2 {
    margin-bottom: 30px;
  }
  .mwc {
    padding: 20px;
    padding-bottom: 50px;
  }
  .mwc-inner {
    padding: 20px;
  }
  .mwc-laptop iframe {
    position: absolute;
    top: 3.3%;
    left: 11.1%;
    width: 259.3%;
    height: 280%;
    background: white;
    border-top-left-radius: 3.2%;
    border-top-right-radius: 3.2%;
    -ms-zoom: 0.3;
    -moz-transform: scale(0.3);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.3);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.3);
    -webkit-transform-origin: 0 0;
  }
}

.page-background {
  background-color: #fff;
  background-image: url('https://d365pq86x330zn.cloudfront.net/4161111c-2e1e-4133-3261-2131e16es2e2.png');
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background-size: cover;
  background-position: top left;
  overflow-x: hidden;
  z-index: 0;
}
.page-background .page-airplane {
  /* @include moving-object(80px, 400px, 20s); */
  max-width: 400px;
  max-height: 80px;
  width: 100%;
  left: -120vw;
  display: block;
  position: absolute;

  animation-name: moving-animation;
  animation-duration: 20s;
  animation-iteration-count: infinite;

  top: 180px;
  width: 400px;
  height: 20px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top left;

  background-image: url('https://d365pq86x330zn.cloudfront.net/34436w64-6664-4664-9w14-1616496w1413.png');
}
.page-background .page-cloud-1 {
  /* @include moving-object(200px, 200px, 50s); */
  max-width: 200px;
  max-height: 200px;
  width: 100%;
  left: -120vw;
  display: block;
  position: absolute;

  animation-name: moving-animation;
  animation-duration: 50s;
  animation-iteration-count: infinite;

  top: 130px;
  height: 200px;
  width: 200px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top left;

  background-image: url('https://d365pq86x330zn.cloudfront.net/329dd457-6569-41d6-2999-655163917d35.png');
}
.page-background .page-cloud-2 {
  /* @include moving-object(200px, 200px, 55s); */
  max-width: 200px;
  max-height: 200px;
  width: 100%;
  left: -120vw;
  display: block;
  position: absolute;

  animation-name: moving-animation;
  animation-duration: 55s;
  animation-iteration-count: infinite;

  top: 150px;
  height: 200px;
  width: 200px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top left;

  background-image: url('https://d365pq86x330zn.cloudfront.net/329dd457-6569-41d6-2999-655163917d35.png');
}
.animation-wrapper {
  position: absolute;
  top: 0;
}

@media (max-width: 424px) {
  .conversation-body {
    width: 90%;

  }

  .conversation-wrapper .conversation-body,
  .conversation-wrapper .conversation-message-bubble {
  max-width: 100%;
  }

}

@media (min-width: 425px) {
  .conversation-body {
    width: 80%;
    max-width: 700px;
  }

  .conversation-wrapper .conversation-body,
  .conversation-wrapper .conversation-message-bubble {
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .conversation-body {
    width: 80%;
  }

  .conversation-wrapper .conversation-body,
  .conversation-wrapper .conversation-message-bubble {
    max-width: 100%;
  }
}

@media (min-width: 1024px) {
  .conversation-body {
    width: 60%;
  }

  .conversation-wrapper .conversation-body,
  .conversation-wrapper .conversation-message-bubble {
    max-width: 60%;
  }
}

.typing-spinner .ball {
  display: inline-block;
  width: 9px;
  height: 9px;
  margin-right: 5px;
  border-radius: 50%;
  background-color: #ff385c;
}

.typing-spinner .ball:nth-child(1) {
  opacity: 0.9;
  animation: mp-typing-ball 0.9s linear infinite;
}

.typing-spinner .ball:nth-child(2) {
  opacity: 0.9;
  animation: mp-typing-ball 0.9s 0.225s linear infinite;
}

.typing-spinner .ball:nth-child(3) {
  opacity: 0.6;
  animation: mp-typing-ball 0.9s 0.45s linear infinite;
}

@media (max-width: 767px) {
  .banner-hpac-plugin-wrapper {
    padding: 0;
  }
}