.pia-case-studies-wrapper {
  padding: 70px 20px;
  background-color: #F5F5F5;
  max-width: 100vw;
  overflow: hidden;
}
.pia-case-studies-wrapper .pia-case-studies {
  max-width: 1160px;
  margin: auto;
}
.pia-case-studies-wrapper .pia-case-studies h2 {
  font-weight: 700;
  font-size: 96px;
  color: #333;
  margin: 0 auto 20px;
  text-align: center;
}
.pia-case-studies-wrapper .pia-case-studies .pia-case-studies-inner {
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 50px;
  display: grid;
}
.pia-case-studies-wrapper .pia-case-studies .pia-case-study {
  padding: 20px;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.pia-case-studies-wrapper .pia-case-studies .pia-case-study .pia-case-study-img {
  width: 100%;
  padding-top: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
}
.pia-case-studies-wrapper .pia-case-studies .pia-case-study h5 {
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  color: #000000;
  margin: 20px 0 10px 0;
}
.pia-case-studies-wrapper .pia-case-studies .pia-case-study h3 {
  display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 1.2;
  color: #000000;
  margin: 10px 0 10px 0;
  min-height: 77px;
}
.pia-case-studies-wrapper .pia-case-studies .pia-case-study p {
  display: block;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: #626262;
  min-height: 34px;
}
.pia-case-studies-wrapper .pia-case-studies .pia-case-study a {
  display: block;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.2;
  align-items: center;
  color: #282828;
  margin: 30px 0 10px 0;
}

@media (max-width: 767px) {
  .pia-case-studies-wrapper .pia-case-studies h2 {
    font-weight: 700;
    font-size: 32px;
  }
  .pia-case-studies-wrapper {
    padding: 20px;
  }
  .pia-case-studies-wrapper .pia-case-studies .pia-case-studies-inner {
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }
}