.want-know-more-wrapper {
  padding: 42px 15px;
  background-color: #70212F;
  margin-bottom: -1px;
  max-width: 100vw;
  overflow: hidden;
}
.want-know-more {
  max-width: 1036px;
  margin: auto;
  display: grid;
  grid-template-columns: 430fr 480fr;
  grid-gap: 40px;
}
.want-know-more h2 {
  font-weight: 600;
  font-size: 60px;
  letter-spacing: -1px;
  color: #fff;
  margin: 0 0 30px;
}
.want-know-more h4 {
  font-weight: 600;
  font-size: 24px;
  color: #fff;
  margin: 0 0 20px;
}
.want-know-more p {
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  margin: 0 0 30px;
}
.want-know-more .want-know-more-input {
  padding-right: 40px;
  border-right: 1px solid #C75469;
}
.want-know-more .want-know-more-input p {
  max-width: 285px;
}
.want-know-more .want-know-more-input input::placeholder {
  color: #FFFFFF;
}
.want-know-more .want-know-more-input input {
  background: #AD4054;
  border-radius: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #FFFFFF;
  outline: none;
  border: none;
  padding: 10px 15px;
  display: block;
  width: 100%;
  margin-bottom: 15px;
  max-width: 320px;
}
.want-know-more .want-know-more-input a {
  outline: none;
  border: none;
  background: #FF385C;
  color: #fff;
  border-radius: 30.5px;
  font-weight: 700;
  font-size: 16px;
  display: block;
  padding: 12px 20px;
  text-align: center;
  margin: 15px 0;
  width: 100%;
  max-width: 230px;
}
.want-know-more-details-single {
  display: flex;
}
.want-know-more-details-single .content {
  flex: 1 1 auto;
}
.want-know-more-details-single .img {
  flex: 0 1 auto;
}
.want-know-more-details {
  display: flex;
  align-items: center;
}

@media (max-width: 1023px) {
  
}

@media (max-width: 767px) {
  .want-know-more {
    display: block;
  }
  .want-know-more h2 {
    font-size: 32px;
    margin: 0 0 20px;
  }
  .want-know-more .want-know-more-input {
    padding-right: 0;
    border-right: none;
    padding-bottom: 40px;
    border-bottom: 1px solid #C75469;
    margin-bottom: 40px;
  }
}