.timer-mp {
  background: #FFFFFF;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  border: none;
  padding: 18px 20px;
  margin: 0 auto 35px;
  max-width: 380px;
}
.timer-mp .timer-panel-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 0 5px 15px;
}
.timer-mp .timer-panel-wrapper .timer-panel {
  margin: 0 5px;
}
.timer-mp .timer-panel-wrapper .timer-panel .timer-panel-number {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.timer-mp .timer-panel-wrapper .timer-panel .timer-panel-label {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  display: block;
}
.timer-mp .timer-panel-wrapper .timer-panel .timer-panel-number .timer-panel-number-single {
  background: #666666;
  border-radius: 5px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.04em;
  color: #fff;
  height: 42px;
  width: 37px;
  margin: 2px;
}
.timer-mp .timer-button {
  background: linear-gradient(180deg, #EB4E4E 0%, #E41E1E 100%);
  border-radius: 10px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #fff;
  padding: 7px 50px;
  width: fit-content;
  margin: auto;
}

@media (max-width: 1023px) {
}

@media (max-width: 767px) {
  .timer-mp {
    padding: 12px 18px;
    width: 100%;
    max-width: 320px;
    box-sizing: border-box;
  }
  .timer-mp .timer-panel-wrapper {
    width: fit-content;
    margin: 0 auto 10px;
  }
  .timer-mp .timer-panel-wrapper .timer-panel {
    margin: 0 3px;
    width: fit-content;
  }
  .timer-mp .timer-panel-wrapper .timer-panel .timer-panel-label {
    font-size: 12px;
    line-height: 1.3;
  }
  .timer-mp .timer-panel-wrapper .timer-panel .timer-panel-number .timer-panel-number-single {
    font-size: 16px;
    line-height: 1.3;
    height: 36px;
    width: 28px;
    margin: 2px;
  }
  .timer-mp .timer-button {
    font-size: 12px;
    line-height: 1.3;
  }
}