.iframe-modal-wrapper {
  position: fixed;
  inset: 0;
  z-index: 99999999;
  background-color: rgba(255, 255, 255, .7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.iframe-modal {
  width: 90vw;
  max-width: 100%;
  height: 90vh;
  max-height: 100%;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.iframe-modal button.close {
  width: 30px;
  font-size: 20px;
  color: #c0c5cb;
  align-self: flex-end;
  background-color: transparent;
  border: none;
  margin-bottom: 10px;
  cursor: pointer;
}

.iframe-modal iframe {
  border: none;
  width: 100%;
  height: 100%;
}


.bolt-on-wrapper,
.single-product-wrapper {
  padding: 50px 30px;
}

.bolt-on-wrapper hr {
  opacity: 0.6;
  border-bottom: 1px solid #C1C8D4;
  max-width: 1298px;
  margin: auto;
}

.bolt-on,
.single-product {
  max-width: 1036px;
  margin: auto;
}

.bolt-on-controls,
.single-product-header {
  display: flex;
}

.bolt-on-controls {
  justify-content: space-between;
  align-items: center;
}

.bolt-on-controls .green-button {
  border: none;
  outline: none;
  background: linear-gradient(180deg, #9BC853 0%, #6A991F 100%);
  box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.25);
  border-radius: 28px;
  padding: 7px 20px;
  cursor: pointer;
}

.bolt-on-controls .green-button label {
  font-weight: 700;
  font-size: 20px;
  color: #FFFFFF;
  padding: 10px 0;
  display: block;
  text-align: center;
  user-select: none;
  cursor: pointer;
}

.bolt-on-controls-title {
  font-weight: 600;
  font-size: 32px;
  color: #565A5C;
  margin: 0 20px 5px;
  flex: 0 1 auto;
}

.single-product-header-title,
.single-product-header-cart,
.single-product-info-wrapper {
  margin: 15px 0;
}

.single-product-header-title {
  font-weight: 600;
  font-size: 32px;
  color: #565A5C;
  flex: 1 1 auto;
}

.bolt-on-addons {
  margin: 50px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}

.bolt-on-addon {
  background: #FFFFFF;
  box-shadow: 0px 5px 10px rgba(33, 54, 88, 0.12);
  border-radius: 20px;
  padding: 18px 10px 10px;
  margin-bottom: 20px;
}

.bolt-on-addon-control {
  display: flex;
  align-items: flex-start;
  margin: 0 10px;
}

.bolt-on-addon-control-text {
  flex: 1 1 auto;
}

.bolt-on-addon-control-text .name {
  display: block;
  font-weight: 600;
  font-size: 16px;
  color: #393939;
  margin: 3px 0 10px;
  /* min-height: 39px; */
}

.bolt-on-addon-control-text .price {
  display: block;
  font-weight: 600;
  font-size: 15px;
  color: #565A5C;
  margin: 0 0 15px;
}

.bolt-on-addon-control-button {
  cursor: pointer;
  flex: 0 1 auto;
  width: fit-content;
  padding: 6px 14px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 12px;
  color: #FF385C;
  background: rgba(255, 56, 92, 0.08);
  border-radius: 30px;
}

.bolt-on-addon-image {
  cursor: pointer;
  box-sizing: border-box;
  padding-top: 100%;
  border-radius: 10px;
  border: 1px solid #ececec;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.bolt-on-addon-details {
  display: block;
  font-weight: 400;
  font-size: 14px;
  color: #565A5C;
  margin: 0 0 15px;
  padding: 0 10px;
  text-align: center;
  margin-bottom: 20px;
  min-height: 37px;
}

.bolt-on-addon-button {
  cursor: pointer;
  display: block;
  width: auto;
  min-width: 240px;
  padding: 20px 10px;
  margin: auto;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 16px;
  border-radius: 30px;
  border: none;
}

.bolt-on-addon-button.add {
  color: #fff;
  background: #6AD36E;
}

.bolt-on-addon-button.remove {
  color: #fff;
  background: #FF385C;
  display: none;
}

.bolt-on-addon-button.disabled {
  opacity: .7;
  pointer-events: none;
}

.bolt-on-addon-wrapper.added .bolt-on-addon {
  background-color: #FF385C;
}

.bolt-on-addon-wrapper.added .bolt-on-addon-image {
  border: 2px solid #FF385C;
}

.bolt-on-addon-wrapper.added .bolt-on-addon-control-text .name,
.bolt-on-addon-wrapper.added .bolt-on-addon-control-text .price {
  color: #fff;
}

.bolt-on-addon-wrapper.added .bolt-on-addon-control-button {
  background: #f7748d;
  color: #f53c5f;
}

.bolt-on-addon-wrapper.added .bolt-on-addon-button.add {
  display: none;
}

.bolt-on-addon-wrapper.added .bolt-on-addon-button.remove {
  display: block;
}

.bolt-on-addon-wrapper.disabled {
  pointer-events: none;
}

.bolt-on-addon-wrapper.disabled .bolt-on-addon-control-text .name {
  color: #959595;
}

.bolt-on-addon-wrapper.disabled .bolt-on-addon-control-button {
  background: rgba(205, 205, 205, 0.3);
  color: #565A5C;
}

.bolt-on-addon-wrapper.disabled .bolt-on-addon-button {
  color: #fff;
  background: #C5C5C5;
}

.single-product-info-wrapper {
  background: #FFFFFF;
  border: 1px solid #D1D1D1;
  border-radius: 20px;

  display: grid;
  grid-template-columns: 1.2fr 1fr;
  grid-gap: 40px;
  padding: 40px 35px;
}

.single-product-info-card-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

.single-product-info-card {
  background: #FFFFFF;
  box-shadow: 0px 0px 22px rgba(33, 54, 88, 0.12);
  border-radius: 20px;
  padding: 30px 20px;
}

.single-product-info-card-header {
  display: flex;
}

.single-product-info-card-header-text {
  flex: 1 1 auto;
}

.single-product-info-card-header-text .name {
  display: block;
  font-weight: 600;
  font-size: 24px;
  color: #393939;
  margin: 3px 0;
}

.single-product-info-card-header-text .price {
  display: block;
  font-weight: 600;
  font-size: 24px;
  color: #565A5C;
  margin: 0 0 24px;
}

.single-product-info-card-header-control {
  flex: 0 1 auto;
}

.single-product-info-card-header-control .title {
  display: block;
  font-weight: 400;
  font-size: 16px;
  color: #565A5C;
  margin: 0 0 7px;
}

.single-product-info-card-header-control .color {
  display: block;
  font-weight: 600;
  font-size: 16px;
  color: #565A5C;
  margin: 0 0 7px;

  background: #ecedef;
  border-radius: 20px;
  border: 10px solid #ecedef;
  padding: 3px 10px;
  width: 100%;
}

.single-product-info-card-image {
  margin-top: 30px;
  box-sizing: border-box;
  padding-top: 100%;
  border-radius: 10px;
  border: 1px solid #ececec;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.single-product-info-card-iframe {
  margin-top: 30px;
  box-sizing: border-box;
  padding-top: 100%;
  border-radius: 10px;
  border: 1px solid #ececec;
  position: relative;
}

.single-product-info-card-iframe iframe {
  position: absolute;
  inset: 0;
  outline: 0;
  border: 0;
  width: 133%;
  height: 133%;

  -ms-zoom: 0.75;
  -moz-transform: scale(0.75);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.75);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.75);
  -webkit-transform-origin: 0 0;
}

.single-product-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0;
}

.single-product-info .type {
  display: block;
  font-weight: 700;
  font-size: 20px;
  color: #FF385C;
  margin: 0 0 16px;
  text-transform: capitalize;
}

.single-product-info .detail {
  display: block;
  font-weight: 600;
  font-size: 24px;
  color: #565A5C;
  line-height: 1.7;
  margin: 0 0 24px;
  text-transform: capitalize;
}

.single-product-info .list {
  padding: 0;
  list-style-type: none;
  margin-bottom: 50px;
}

.single-product-info .list>li {
  display: flex;
  margin-bottom: 25px;
}

.single-product-info .list>li>svg {
  min-width: 35px;
  margin-right: 15px;
}

.single-product-info .list>li>label {
  flex: 1 1 auto;
  display: block;
  font-weight: 600;
  font-size: 20px;
  color: #565A5C;
  line-height: 1.7;
  text-transform: capitalize;
}

.single-product-info .add-to-basket {
  cursor: pointer;
  display: inline-block;
  padding: 20px 30px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 16px;
  border-radius: 30px;
  border: none;
  color: #fff;
  text-transform: uppercase;
  background-color: #FF385C;
  width: max-content;
  margin: 7px;
  user-select: none;
}

.single-product-info .add-to-basket.disabled {
  opacity: .7;
  pointer-events: none;
}

.single-product-info .add-to-basket.grey {
  background-color: #C5C5C5;
}

.single-product-info .pricing {
  cursor: pointer;
  display: inline-block;
  padding: 20px 30px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 16px;
  border-radius: 30px;
  border: none;
  color: #fff;
  text-transform: uppercase;
  background-color: #FF385C;
  width: max-content;
  margin: 7px;
}

@media (max-width: 1128px) {
  .bolt-on-addons {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 1023px) {
  .bolt-on-wrapper {
    padding: 20px 20px 50px;
  }

  .bolt-on-controls,
  .bolt-on-controls .green-button {
    display: block;
  }

  .bolt-on-controls-title {
    margin: 0 0 20px;
  }

  .bolt-on-addons {
    grid-template-columns: 1fr 1fr;
  }

  .single-product-info-wrapper {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 767px) {
  .bolt-on-controls-dropdowns {
    display: block;
  }

  .bolt-on-controls-sort,
  .bolt-on-controls-looking {
    width: 100%;
    min-width: 0;
    max-width: 100%;
  }

  .bolt-on-controls-sort-wrapper {
    margin-top: 20px;
  }

  .bolt-on-addons {
    grid-template-columns: 1fr;
  }

  .single-product-info {
    padding: 20px 5px;
  }

  .single-product-info-card-header {
    display: block;
  }

  .bolt-on-wrapper,
  .single-product-wrapper,
  .basket-wrapper {
    padding: 20px 15px;
  }

  .single-product-info-wrapper {
    padding: 28px 24px;
  }

  .green-button {
    display: block;
    width: 100%;
  }
}

.checkout-pricing-options {
  background-color: #70212f;
  padding: 50px 20px;
  overflow: auto;
  
  .checkout-pricing-options-inner {
    max-width: 1024px;
    margin: 20px auto;

    h2 {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 48px;
      line-height: 1.2;
      color: #FFFFFF;
      margin: 0 0 10px 0;
      text-align: center;
    }

    p {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 1.2;
      color: #FFFFFF;
      margin: 0 0 20px 0;
      text-align: center;
    }

    .options {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      padding: 50px 0;
      max-width: 550px;
      margin: auto;

      .option {
        min-height: 148px;
        padding: 50px;
        background: #FFFFFF;
        box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.2);
        border-radius: 20px;
        padding: 20px 15px 15px 15px;

        h3 {
          font-family: 'Montserrat', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 1.2;
          color: #565A5C;
          margin: 0 0 10px 0;
        }

        p {
          font-family: 'Open Sans', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 1.2;
          color: #565A5C;
          text-align: left;
          margin: 0 0 10px 0;
          min-height: 60px;
        }

        button,
        a {
          cursor: pointer;
          border: none;
          outline: none;
          display: block;
          font-family: 'Open Sans', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 1;
          color: #fff;
          background-color: #565A5C;
          padding: 12px 34px;
          margin: 0 auto 20px auto;
          width: fit-content;
          text-align: center;
          border-radius: 30px;
          // text-transform: uppercase;
        }

        span {
          display: block;
          font-family: 'Open Sans', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 1;
          color: #000;
          margin: auto;
          text-align: center;
        }

        .per-month {
          padding: 15px 0 24px;

          h4 {
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 64px;
            line-height: 1;
            color: #565A5C;
            margin: 0;
            text-align: center;
          }
          h5 {
            font-family: 'Open Sans', sans-serif;
            font-style: italic;
            font-weight: 600;
            font-size: 24px;
            line-height: 1;
            color: #565A5C;
            margin: 0;
            text-align: center;
          }
          h6 {
            font-family: 'Open Sans', sans-serif;
            font-style: italic;
            font-weight: 600;
            font-size: 16px;
            line-height: 1;
            color: #565A5C;
            margin: 0;
            text-align: center;
          }
        }

        .splitter {
          background-color: #D1D1D1;
          width: 100%;
          height: 1px;
          margin: 10px 0;
        }

        &.active {
          border-width: 5px;
          border-style: solid;
        }
      }
    }

    @media (max-width: 1023px) {
      h2 {
        font-size: 32px;
      }

      p {
        font-size: 17px;
      }

      @media (max-width: 767px) {
        h2 {
          font-size: 28px;
        }

        p {
          font-size: 16px;
        }

        .options {
          grid-template-columns: 1fr;
        }
      }
    }
  }
}
