.twenty7tec-header {
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px;
  position: fixed;
  width: 100%;
  z-index: 99999999;
  box-sizing: border-box;

  .nav {
    max-width: 1140px;
    margin: auto;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo-wrapper {
      .toggle-open,
      .toggle-close,
      .space-filler {
        display: none;
      }

      .logo {
        max-height: 72px;
      }
    }

    .nav-link {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      gap: 22px;

      li {
        position: relative;
        padding: 10px 0;

        .sub-menu {
          display: none;
          position: absolute;
          list-style-type: none;
          top: 100%;
          padding: 0;
          margin: 0;
          border: 1px solid rgba(0,0,0,.15);
          border-radius: 6px;
          background-color: #fff;
          overflow: hidden;

          li {
            padding: 0;
            margin: 0;

            a {
              display: block;
              color: #000000;
              padding: 12px 10px;
              margin: 0;
              min-width: 100px;
    
              &:hover {
                color: #000000;
              }
            }

            &:hover {
              background-color: #009DD9;
            }
          }
        }

        &:hover {
          .sub-menu {
            display: block;
          }
        }

        a {
          font-family: 'Montserrat', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 1;
          color: #000000;

          &:hover {
            color: #009DD9;
          }

          &.free-trial {
            letter-spacing: 2px;
            color:#ffffff;
            border: 2px solid #82BBA2;
            background-color: #82BBA2;
            border-radius: 20px;
            padding: 10px;
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-weight: 800;
            font-size: 13px;
            line-height: 1;
            color: #FFFFFF;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  @media (max-width: 929px) {
    .nav {
      display: block;
  
      .logo-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .toggle-open,
        .space-filler {
          display: block;
        }
  
        .logo {
          max-height: 50px;
        }
      }
  
      .nav-link {
        display: none;
        grid-template-columns: 1fr;
        gap: 0;
  
        li {
          .sub-menu {
            display: block;
            position: relative;
            list-style-type: none;
            border: none;
            padding-top: 10px;
            padding-left: 20px;
  
            li {
              padding: 0;
              margin: 0;
  
              a {
                display: block;
                color: #000000;
                padding: 12px 10px;
                margin: 0;
                min-width: 100px;
      
                &:hover {
                  color: #000000;
                }
              }
  
              &:hover {
                background-color: #009DD9;
              }
            }
          }
  
          a {
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 1;
            color: #000000;
  
            &:hover {
              color: #009DD9;
            }
  
            &.free-trial {
              letter-spacing: 2px;
              color:#ffffff;
              border: 2px solid #82BBA2;
              background-color: #82BBA2;
              border-radius: 20px;
              padding: 10px;
              font-family: 'Montserrat', sans-serif;
              font-style: normal;
              font-weight: 800;
              font-size: 13px;
              line-height: 1;
              color: #FFFFFF;
              text-transform: uppercase;
            }
          }
        }
      }
    }

    &.open {
      .nav {
        padding-bottom: 20px;

        .logo-wrapper {
          .toggle-open {
            display: none;
          }

          .toggle-close {
            display: block;
          }
        }
    
        .nav-link {
          display: grid;
        }
      }
    }
  }
}