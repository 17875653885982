.ldetails-graphics-inner .laptop-bg {
  position: absolute;
  top: 10.3%;
  left: 16%;
  right: 13.5%;
  bottom: 12%;
  background-color: #fff;
  border-top-left-radius: 2%;
  border-top-right-radius: 2.4%;
  z-index: -1;
}