.prices-wrapper {
  padding: 50px 30px;
}
.prices {
  max-width: 1298px;
  margin: auto;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.price-wrapper {
  background: #FFFFFF;
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  max-width: 100%;
  overflow: hidden;
}
.price-wrapper .price {
  padding: 20px;
}
.price-detail {
  display: flex;
  margin-bottom: 20px;
}
.price-detail-intro {
  flex: 1 1 auto;
}
.price-detail-intro h4 {
  font-weight: 400;
  font-size: 32px;
  color: #565A5C;
  margin: 0 0 5px;
}
.price-detail-intro h5 {
  font-weight: 400;
  font-size: 16px;
  color: #565A5C;
  margin: 0 0 5px;
}
.price-detail-main {
  flex: 0 1 auto;
}
.price-detail-main h3 {
  font-weight: 400;
  font-size: 40px;
  color: #565A5C;
  margin: 0 0 5px;
}
.green-price .price-detail-main h3 { color: #3DB233; }
.gold-price .price-detail-main h3 { color: #ECB22E; }
.purple-price .price-detail-main h3 { color: #8C4BE4; }
.red-price .price-detail-main h3 { color: #FF385C; }
.price-detail-main h3 em {
  font-size: 16px;
}
.price-content {
  position: relative;
}
.price-content-inner {
  margin: 0 0 50px;
}
.price-content-iframe-wrapper {
  box-sizing: border-box;
  padding-top: 100%;
  border-radius: 10px;
  border: 1px solid #ececec;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.price-content-iframe-wrapper iframe {
  position: absolute;
  inset: 0;
  border: none;
  outline: none;
  width: 152%;
  height: 152%;

  -ms-zoom: 0.66;
  -moz-transform: scale(0.66);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.66);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.66);
  -webkit-transform-origin: 0 0;
}
.price-content h5 {
  display: block;
  font-weight: 700;
  font-size: 24px;
  color: #565A5C;
  margin: 12px 0;
}
.price-content label {
  display: block;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  color: #565A5C;
  margin: 12px 0;
}

@media (max-width: 1159px) {
  .prices {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 767px) {
  .price-content-inner {
    margin: 0 0 30px;
  }
  .price-detail {
    display: block;
  }
  .price-detail-intro h4 {
    font-size: 27px;
  }
  .price-detail-main h3 {
    font-size: 36px;
  }
}
  