.payads-wrapper {
  padding: 0;    
  background-color: #70212F;
  margin-bottom: -1px;
  max-width: 100vw;
  overflow: hidden;
}
.payads {
  padding: 50px 15px;   
  max-width: 1036px;
  margin: auto;
}

.payads-single {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
}
.payads-single-text {
  flex: 1 1 auto;
  width: 55%;
}
.payads-single-img {
  flex: 1 1 auto;
  width: 45%;
}
.payads-single-img img {
  max-width: 100%;
}
.payads-single-text {
  padding: 50px 15px;
}
.payads-single-text h2 {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 64px;
  color: #fff;
  margin: 0 0 35px;
}
.payads-single-text p {
  font-weight: 400;
  font-size: 20px;
  color: #fff;
  line-height: 1.7;
  margin: 0 0 40px;
}
.payads-single.reverse .payads-single-img {
  order: 1;
}
.payads-single.reverse .payads-single-text {
  order: 2;
}
.payads-single.reverse .payads-single-text .btn {
  margin-left: auto;
  display: block;
  width: fit-content;
}

@media (max-width: 1023px) {
  .payads-single {
    flex-direction: column;
  }
  .payads-single .payads-single-img,
  .payads-single.reverse .payads-single-img {
    order: 1;
    width: auto;
  }
  .payads-single .payads-single-text,
  .payads-single.reverse .payads-single-text {
    order: 2;
    width: auto;
  }
  .payads-single.reverse .payads-single-text .btn {
    margin-left: 0;
    display: block;
  }
}

@media (max-width: 767px) {
  .payads-single-text h2 {
    font-size: 32px;
  }
}