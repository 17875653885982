.time-to-parker-banner-wrapper {
  padding: 0 30px 90px;
  background-image: url('https://d365pq86x330zn.cloudfront.net/164258a0-5121-4H64-6850-4a6551151422.png');
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  box-sizing: border-box;
}
.time-to-parker-banner-wrapper .btn {
  display: block;
  width: max-content;
  margin: 20px auto 0;
}
.time-to-parker-banner {
  max-width: 1160px;
  margin: auto;
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}
.time-to-parker-banner-text {
  flex: 1 1 auto;
  width: 45%;
  padding: 10px;
}
.time-to-parker-banner-image {
  flex: 1 1 auto;
  width: 55%;
  padding: 10px;
}
.time-to-parker-banner-image img {
  max-width: 100%;
}
.time-to-parker-banner-text h1 {
  text-transform: capitalize;
  color: #565A5C;
  font-weight: 600;
  font-size: 64px;
  margin: 0 0 15px;
}
.time-to-parker-banner-text h1 span {
  color: #FF385C;
  font-weight: 700;
}
.time-to-parker-banner-text p {
  font-weight: 600;
  font-size: 24px;
  color: #565A5C;
  margin: 0 0 20px;
}
.time-to-parker-video label {
  display: block;
  text-align: center;
  font-weight: 700;
  font-size: 32px;
  color: #FF385C;
  margin: 0 0 20px;
}
.time-to-parker-video h4 {
  text-align: center;
  font-weight: 600;
  font-size: 34px;
  color: #565A5C;
  margin: 0 auto 15px;
}
.time-to-parker-banner-text button {
  margin: 40px 40px 0;
}
.awards-wrapper .awards {
  width: 100%;
  height: auto;
  max-width: 487px;
  margin: 20px 40px;
}
.awards-wrapper {
  display: inline-block;
  position: relative;
  padding-top: 80px;
}
.time-to-parker-video-wrapper {
  margin: 20px;
}
.time-to-parker-video {
  max-width: 1160px;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-gap: 30px;
}
.time-to-parker-video-vid {
  border: none;
  outline: none;
  width: 100%;
  height: 317px;
}
.time-to-parker-video.full {
  display: block;
  padding: 30px 0;
}
.time-to-parker-video.full .time-to-parker-video-vid {
  border: none;
  outline: none;
  width: 100%;
  height: 500px;
}

@media (max-width: 1023px) {
  .time-to-parker-banner {
    flex-flow: column;
    padding-top: 80px;
  }
  .time-to-parker-banner-image,
  .time-to-parker-banner-text {
    width: auto;
  }
  .time-to-parker-banner-text h1,
  .time-to-parker-banner-text p {
    padding: 0;
  }
  .time-to-parker-banner-text button {
    margin: 10px 0 40px 0;
  }
  .time-to-parker-video {
    display: block;
  }
  .time-to-parker-video-wrapper {
    margin: 30px;
  }
  .time-to-parker-video-vid {
    margin: 15px 0;
  }
  .time-to-parker-video label {
    min-height: 0;
  }
}

@media (max-width: 767px) {
  .time-to-parker-banner {
    flex-flow: column;
    padding-top: 80px;
  }
  .time-to-parker-banner-animation {
    display: none;
  }
  .time-to-parker-video.full .time-to-parker-video-vid {
    height: 317px;
  }
}