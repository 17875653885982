.banner-hpac-wrapper {
  padding: 50px 30px;
  background-color: #fff;
  max-width: 100vw;
  overflow: hidden;
}
.banner-hpac-wrapper .banner-hpac {
  max-width: 1160px;
  margin: auto;
}
.banner-hpac-wrapper .banner-hpac h1 {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 50px;
  color: #000;
  margin: 0 auto 15px;
  text-align: center;
  max-width: 900px;
}
.banner-hpac-wrapper .banner-hpac p {
  font-weight: 500;
  font-size: 20px;
  color: #000;
  max-width: 900px;
  margin: auto;
  text-align: center;
}
.banner-hpac-wrapper .banner-hpac .single-content {
  padding-top: 10vw;
  text-align: center;
}
.banner-hpac-wrapper .banner-hpac .pac-btn {
  background: #FF385C;
  box-shadow: 0px 4px 19px rgba(242, 77, 107, 0.6);
  border-radius: 30.5px;
  display: inline-block;
  font-weight: 600;
  font-size: 20px;
  color: #fff;
  margin: 0 10px 20px;
  padding: 20px 70px;
}
.banner-hpac-wrapper .banner-hpac .pac-btn.black {
  background: #262626;
  box-shadow: none;
}

@media (max-width: 767px) {
  .banner-hpac-wrapper {
    padding: 20px;
  }
  .banner-hpac-wrapper .banner-hpac h1 {
    font-size: 36px;
    margin: 0 auto 10px;
  }
  .banner-hpac-wrapper .banner-hpac p {
    font-size: 16px;
  }
}

.banner-hpac-comm-wrapper {
  padding: 0;
  background-color: #ffffff;
  max-width: 100vw;
  overflow: hidden;
}
.banner-hpac-comm-wrapper .banner-hpac-comm {
  max-width: 100vw;
  margin: auto;
  background-color: #fff;
  height: calc(100vh - 100px);
  display: grid;
  grid-template-columns: .7fr 1fr;
}
.banner-hpac-comm-wrapper .banner-hpac-comm .banner-hpac-comm-image {
  background-image: url('https://d365pq86x330zn.cloudfront.net/68830564-W1W4-4E01-4436-146663481W41.png');
  background-position: center;
  background-size: cover;
  min-height: 120px;
}
.banner-hpac-comm-wrapper .banner-hpac-comm-sign-in {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}
.banner-hpac-comm-wrapper .banner-hpac-comm-sign-in-inner {
  max-width: 480px;
  text-align: center;
}
.banner-hpac-comm-wrapper .banner-hpac-comm h2 {
  font-weight: 700;
  font-size: 32px;
  color: #333;
  margin: 0 0 10px;
}
.banner-hpac-com p {
  font-weight: 500;
  font-size: 12px;
  color: #333;
  margin: 0 auto 10px;
  text-align: center;
}
.banner-hpac-com iframe {
  border: none;
  outline: none;
  width: 100%;
  height: calc(100vh - 100px);
}
.banner-hpac-comm-wrapper p {
  font-weight: 500;
  font-size: 12px;
  color: #333;
  margin: 0 0 10px;
}
.banner-hpac-comm-wrapper label {
  font-weight: 600;
  font-size: 14px;
  color: #333;
  margin: 0 0 10px;
  display: block;
  text-align: left;
}
.banner-hpac-comm-wrapper input {
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 16px;
  color: #000;
  border: 1px solid #ececec;
  padding: 10px 15px;
  border-radius: 5px;
  display: block;
  width: 100%;
  cursor: pointer;
}
.banner-hpac-comm-wrapper img {
  max-width: 100%;
}
.banner-hpac-comm-wrapper a {
  color: #ff385c;
  font-weight: 700;
  text-decoration: underline;
}
.banner-hpac-comm-wrapper button {
  border: none;
  outline: none;
  background-color: #ff385c;
  color: #fff;
  font-weight: 700;
  font-size: 12px;
  padding: 10px 35px;
  border-radius: 10px;
  display: block;
  margin: auto;
  width: fit-content;
  cursor: pointer;
}

@media (max-width: 767px) {
  .banner-hpac-comm-wrapper {
    padding: 0;
  }
  .banner-hpac-comm-wrapper .banner-hpac-comm {
    grid-template-columns: 1fr;
  }
}