.join-us-wrapper {
  padding: 70px 15px;
  background-color: #262626;
  margin-bottom: -1px;
  max-width: 100vw;
  overflow: hidden;
}
.join-us {
  max-width: 1036px;
  margin: auto;
  display: grid;
  grid-template-columns: 430fr 480fr;
  grid-gap: 40px;
}
.join-us h2 {
  font-weight: 600;
  font-size: 48px;
  letter-spacing: -1px;
  color: #fff;
  margin: 0 0 30px;
}
.join-us h4 {
  font-weight: 600;
  font-size: 24px;
  color: #fff;
  margin: 0 0 20px;
}
.join-us p {
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  margin: 0 0 30px;
}
.join-us .join-us-input {
  padding-right: 40px;
  border-right: 1px solid #5E5E5E;
}
.join-us .join-us-input p {
  max-width: 285px;
}
.join-us .join-us-input input::placeholder {
  color: #262626;
}
.join-us .join-us-input input {
  background: #fff;
  border-radius: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #262626;
  outline: none;
  border: none;
  padding: 10px 15px;
  display: block;
  width: 100%;
  margin-bottom: 15px;
  max-width: 320px;
}
.join-us .join-us-input .fbtn,
.join-us .join-us-input button {
  outline: none;
  border: none;
  background: #FF385C;
  color: #fff;
  border-radius: 30.5px;
  font-weight: 700;
  font-size: 16px;
  display: block;
  padding: 12px 20px;
  text-align: center;
  margin: 15px 0;
  width: 100%;
  max-width: 230px;
}
.join-us-details-single {
  display: flex;
}
.join-us-details-single .content {
  flex: 1 1 auto;
  padding: 0 10px 20px;
}
.join-us-details-single .img {
  flex: 0 1 auto;
}
.join-us-details {
  display: flex;
  align-items: center;
}

@media (max-width: 1023px) {
  
}

@media (max-width: 767px) {
  .join-us {
    display: block;
  }
  .join-us h2 {
    font-size: 32px;
    margin: 0 0 20px;
  }
  .join-us .join-us-input {
    padding-right: 0;
    border-right: none;
    padding-bottom: 40px;
    border-bottom: 1px solid #5E5E5E;
    margin-bottom: 40px;
  }
}