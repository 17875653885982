.contents-wrapper.hpac {
  padding: 50px 30px;
  background-color: #FBFBFB;
  max-width: 100vw;
  overflow: hidden;
}
.contents-wrapper.hpac .contents {
  max-width: 1160px;
  margin: auto;
}
.contents-wrapper.hpac .contents .single-content {
  padding: 120px 0;
  text-align: center;
}
.contents-wrapper.hpac .contents .pac-logo {
  max-width: 205px;
  margin: auto;
}
.contents-wrapper.hpac .contents .pac-image {
  max-width: 100%;
  margin: auto;
}
.contents-wrapper.hpac .contents h2 {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 96px;
  color: #000;
  margin: 0 0 15px;
  text-align: center;
}
.contents-wrapper.hpac .contents p {
  font-weight: 400;
  font-size: 20px;
  color: #565A5C;
  max-width: 700px;
  margin: auto;
}
.contents-wrapper.hpac .contents .pac-btn {
  background: #303030;
  border-radius: 15px;
  display: block;
  font-weight: 600;
  font-size: 20px;
  color: #fff;
  max-width: 323px;
  margin: auto;
  padding: 20px;
}

@media (max-width: 767px) {
  .contents-wrapper.hpac {
    padding: 20px;
  }
  .contents-wrapper.hpac .contents h2 {
    font-size: 36px;
    margin: 0 0 10px;
  }
  .contents-wrapper.hpac .contents p {
    font-size: 16px;
  }
  .contents-wrapper.hpac .contents .pac-logo {
    max-width: 115px;
  }
  .contents-wrapper.hpac .contents .single-content {
    padding: 50px 0;
  }
}