.not-found {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  background-image: url('https://d365pq86x330zn.cloudfront.net/164258a0-5121-4H64-6850-4a6551151422.png');
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  box-sizing: border-box;
}
.not-found h1 {
  font-size: 120px;
  color: #FF385C;
}