.time-to-meetparker-content-wrapper {
  padding: 120px 30px;
  background-color: #FF385C;
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  box-sizing: border-box;
}
.time-to-meetparker-content {
  max-width: 1160px;
  margin: auto;
}
.time-to-meetparker-content-question-form {
  padding: 30px 0;
}
.time-to-meetparker-content h2 {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 40px;
  color: #fff;
  margin: 0 0 15px;
}
.time-to-meetparker-content p {
  font-weight: 600;
  font-size: 24px;
  color: #fff;
  margin: 0 0 15px;
}
.time-to-meetparker-content label {
  font-weight: 500;
  font-size: 24px;
  color: #fff;
  margin: 0 0 25px;
}
.time-to-meetparker-content .form-group {
  display: flex;
  position: relative;
  margin-bottom: 25px;
}
.time-to-meetparker-content .form-group label {
  min-width: 270px;
  text-align: right;
  margin: 7px 10px;
}
.time-to-meetparker-content .form-group textarea,
.time-to-meetparker-content .form-group input {
  flex: 1 1 auto;
  margin: 0 10px;
  font-size: 24px;
  background: #FFFFFF;
  border: 1px solid #C9C9C9;
  border-radius: 5px;
  padding: 10px;
  outline-color: #FFA4B4;
  max-width: 100%;
  box-sizing: border-box;
}
.time-to-meetparker-content-question {
  opacity: .6;
  cursor: pointer;
  user-select: none;
}
.time-to-meetparker-content-question * {
  cursor: pointer;
}
.time-to-meetparker-content-question .splitter-line {
  height: 1px;
  background-color: #fff;
  margin: 20px 0;
}
.time-to-meetparker-content-question label {
  display: block;
  margin: 0;
}
.time-to-meetparker-content-question label a {
  color: #fff;
  text-decoration: underline;
}
.time-to-meetparker-content-question label.answer {
  display: none;
  padding-left: 70px;
  margin: 30px 0;
}
.time-to-meetparker-content-question.active {
  opacity: 1;
}
.time-to-meetparker-content-question.active label.answer {
  display: block;
}


@media (max-width: 1023px) {
  .time-to-meetparker-content-wrapper {
    padding: 30px;
  }
}

@media (max-width: 767px) {
  .time-to-meetparker-content .form-group {
    display: block;
    box-sizing: border-box;
  }
  .time-to-meetparker-content .form-group label {
    min-width: 0;
    text-align: left;
    width: 100%;
    margin: 0 0 10px;
    display: block;
  }
  .time-to-meetparker-content .form-group textarea,
  .time-to-meetparker-content .form-group input {
    display: block;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
  }
}