@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');

::-webkit-scrollbar {
  display: block;
  width: 10px;
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-track-piece {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, .2);
  border: 1px solid hsla(0, 0%, 100%, .2);
  border-radius: 24px
}

html { scroll-behavior: smooth; }

a {
  text-decoration: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  line-height: 1.3;
  text-transform: none;
}

label,
span,
div,
p {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  line-height: 1.3;
  text-transform: none;
}

.bg-f8f8f8 {
  background-color: #f8f8f8;
}

.bg-efefef {
  background-color: #efefef;
}

.bg-70212F {
  background-color: #70212F;
}

.font-ffffff {
  color: #ffffff !important;
}

.btn {
  border-radius: 30px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
  border: 2px solid transparent;
  outline: none;
  padding: 17px 30px;
  cursor: pointer;
}

.btn-block {
  display: block;
}

.btn-fit {
  display: inline-block;
  width: fit-content;
}

.btn-primary {
  background: #FF385C;
  color: #fff;
}

.btn-primary:hover {
  background: #fff;
  color: #FF385C;
  border-color: #FF385C;
}

.btn-primary-invert {
  background: #fff;
  color: #FF385C;
}

.btn-primary-invert:hover {
  background: #FF385C;
  color: #fff;
  border-color: #fff;
}

.btn-primary-rev {
  background: #fff;
  color: #FF385C;
  border: 2px solid #FF385C;
  border-radius: 15px;
}

.btn-primary-rev:hover {
  background: #FF385C;
  color: #fff;
  border-color: #FF385C;
}

.btn-floating {
  background: #fff;
  border-color: #fff;
  color: #565A5C;
}

.btn-floating>.icon {
  color: #FF385C;
}

.btn-success {
  background: linear-gradient(180deg, #81AE37 0%, #709D27 100%);
  ;
  color: #fff;
}

.btn-success.disabled {
  background: linear-gradient(#cecece, #a7a7a7);
  pointer-events: none;
}

.splitbar {
  background: #FF385C;
  width: 35px;
  height: 5px;
  margin: 10px auto;
  border-radius: 5px;
}

.splitbar-invert {
  background: #fff;
  width: 35px;
  height: 5px;
  margin: 10px 0;
  border-radius: 5px;
}

.splitbar-grey {
  background: #cecece;
  width: 35px;
  height: 5px;
  margin: 10px 0;
  border-radius: 5px;
}

.basic-banner-wrapper {
  padding: 50px 30px;
}

.basic-banner {
  max-width: 1298px;
  margin: auto;
}

.basic-banner-card {
  display: grid;
  grid-template-columns: 7fr 9fr;
  background: #FFFFFF;
  overflow: hidden;
  border-radius: 20px;
}

.basic-banner-card-text {
  border-radius: 20px;
  padding: 55px 50px;
}

.basic-banner-card-text h2 {
  font-weight: 700;
  font-size: 24px;
  color: #FF385C;
  margin: 0 0 15px;
}

.basic-banner-card-text h1 {
  font-weight: 700;
  font-size: 48px;
  line-height: 1.2;
  color: #393939;
  margin: 0 0 15px;
}

.basic-banner-card-text p {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.3;
  color: #565A5C;
  margin: 0 0 15px;
}

.basic-banner-card-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 55px 50px;
}

.basic-banner-card-image {
  width: 100%;
  height: auto;
}

.basic-store-wrapper {
  padding: 50px 30px;
}

.basic-store-wrapper hr {
  opacity: 0.6;
  border-bottom: 1px solid #C1C8D4;
  max-width: 1298px;
  margin: auto;
}

.basic-store {
  max-width: 1298px;
  margin: auto;
}

.basic-store-head-title {
  font-weight: 700;
  font-size: 36px;
  line-height: 1.2;
  text-align: center;
  color: #171717;
}

.basic-store-search {
  margin: 30px 20px 70px;
  text-align: center;
}

.basic-store-search-inner {
  background: #ffffff;
  border: 1px solid #FF385C;
  border-radius: 40px;
  max-width: 695px;
  margin: auto;
  display: flex;
  align-items: center;
  border-radius: 40px;
  position: relative;
}

.autoType {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  box-shadow: 0px 4px 22px rgb(0 0 0 / 25%);
  list-style-type: none;
  padding: 0;
  margin: 20px 0 0 0;
  border-radius: 20px;
  display: none;
  cursor: pointer;
  max-height: 186px;
  overflow-y: auto;
  z-index: 1;
}

.basic-store-search-inner .autoType:hover,
.basic-store-search-inner input:focus+.autoType {
  display: block;
}

.autoType li {
  padding: 20px;
  border-bottom: 2px solid #A9A9A9;
  color: #757575;
}

.autoType li:last-child {
  border-bottom: 0px solid #A9A9A9;
}

.basic-store-search-inner input {
  flex: 1 1 auto;
  background: none;
  outline: none;
  border: none;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #A9A9A9;
  padding: 0 20px;
}

.basic-store-search-inner button {
  flex: 0 1 auto;
  border: none;
  outline: none;
  background-color: #FF385C;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
}

.basic-store-search-inner.reverse {
  background-color: #ffffff;
  border: 2px solid #878787;
}

.basic-store-search-inner.reverse button {
  background-color: #ffffff;
}

.basic-store-controls {
  display: flex;
}

.basic-store-controls.w-buttons {
  display: block;
}

.basic-store-controls-title {
  font-weight: 700;
  font-size: 36px;
  color: #FF385C;
  margin: 0 20px 5px;
  flex: 0 1 auto;
}

.basic-store-controls-buttons {
  margin: 0 20px 40px;
  flex: 0 1 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 30px;
}

.basic-store-controls-button {
  border: none;
  outline: none;
  background: #FFFFFF;
  color: #FF385C;
  box-shadow: 0px 4px 10px rgba(255, 177, 191, 0.43);
  border-radius: 10px;
  text-align: center;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  padding: 10px;
}

.basic-store-controls-button.active {
  background: #FF385C;
  color: #ffffff;
}

.basic-store-controls-dropdowns {
  flex: 1 1 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.basic-store-controls-sort,
.basic-store-controls-looking {
  background: #FFFFFF;
  border: 1px solid #CFCFCF;
  border-radius: 20px;
  width: 100%;
  max-width: 400px;
  position: relative;
}

.basic-store-controls-looking {
  opacity: 0;
  pointer-events: none;
}

.basic-store-controls-sort-title,
.basic-store-controls-looking-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.basic-store-controls-sort-title label,
.basic-store-controls-looking-title label {
  font-weight: 400;
  font-size: 15px;
  color: #565A5C;
}

.basic-store-controls-sort-selection,
.basic-store-controls-looking-selection {
  display: none;
}

.basic-store-controls-sort.active,
.basic-store-controls-looking.active {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.basic-store-controls-sort.active .basic-store-controls-sort-selection,
.basic-store-controls-looking.active .basic-store-controls-looking-selection {
  display: block;
  position: absolute;
  top: 100%;
  left: -1px;
  right: -1px;
  background: #FFFFFF;
  border: 1px solid #CFCFCF;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  overflow-y: auto;
  max-height: 120px;
  z-index: 9;
}

.basic-store-controls-sort .basic-store-controls-sort-selection li,
.basic-store-controls-looking .basic-store-controls-looking-selection li {
  border-bottom: 1px solid #CFCFCF;
  font-weight: 400;
  font-size: 15px;
  color: #565A5C;
  padding: 10px 20px;
}

.basic-store-controls-sort .basic-store-controls-sort-selection li:last-child,
.basic-store-controls-looking .basic-store-controls-looking-selection li:last-child {
  border-bottom: none;
}

.basic-store-controls-sort-wrapper {
  display: flex;
  align-items: center;
}

.basic-store-controls-sort-wrapper>span {
  min-width: 80px;
  display: block;
  padding: 0 10px;
  font-weight: 400;
  font-size: 15px;
  color: #565A5C;
}

.basic-store-controls-sort {
  background: #e6e7e9;
  border: 1px solid #f8f8f8;
  min-width: 150px;
}

.basic-store-controls-sort-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 18px;
}

.basic-store-controls-sort-title label {
  font-weight: 600;
  font-size: 15px;
  color: #565A5C;
}

.basic-store-controls-sort.active .basic-store-controls-sort-selection {
  background: #e6e7e9;
  border: 1px solid #f8f8f8;
}

.basic-store-controls-sort .basic-store-controls-sort-selection li {
  border-bottom: 1px solid #fff;
  font-weight: 600;
  font-size: 15px;
  color: #565A5C;
  padding: 7px 18px;
}

.basic-store-controls-sort .basic-store-controls-sort-selection li:last-child {
  border-bottom: none;
}

.basic-store-products {
  margin: 50px 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}

.basic-store-products.large {
  grid-template-columns: 1fr;
}

.basic-store-product {
  background: #FFFFFF;
  box-shadow: 0px 5px 10px rgba(33, 54, 88, 0.12);
  border-radius: 20px;
  padding: 18px 10px 10px;
  margin-bottom: 20px;
}

.basic-store-product-control {
  display: flex;
  align-items: flex-start;
  margin: 0 10px;
}

.basic-store-product-control-text {
  flex: 1 1 auto;
}

.basic-store-product-control-text .name {
  display: block;
  font-weight: 600;
  font-size: 16px;
  color: #393939;
  margin: 3px 0 10px;
  min-height: 39px;
}

.basic-store-product-control-text .price {
  display: block;
  font-weight: 600;
  font-size: 15px;
  color: #565A5C;
  margin: 0 0 15px;
}

.basic-store-product-control-button {
  /* pointer-events: none; */
  flex: 0 1 auto;
  width: fit-content;
  padding: 6px 14px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 12px;
  color: #FF385C;
  background: rgba(255, 56, 92, 0.08);
  border-radius: 30px;
}

.basic-store-product-image {
  box-sizing: border-box;
  padding-top: 100%;
  border-radius: 10px;
  border: 1px solid #ececec;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.basic-store-product-details {
  display: block;
  font-weight: 400;
  font-size: 14px;
  color: #565A5C;
  margin: 0 0 15px;
  padding: 0 10px;
}

.basket-wrapper {
  padding: 50px 30px;
}

.basket {
  max-width: 1036px;
  margin: auto;
}

.basket-card {
  margin: 0 20px;
  background: #FFFFFF;
  border-radius: 20px;
}

.basket-card-header {
  padding: 35px 0;
  margin: 0 40px;
  display: flex;
  align-items: center;
}

.basket-card-header .logo {
  flex: 0 1 auto;
  margin-right: 35px;
}

.basket-card-header .title {
  flex: 1 1 auto;
  display: block;
  font-weight: 700;
  font-size: 36px;
  color: #434343;
  margin: 0;
}

.basket-card-header .cs {
  flex: 0 1 auto;
  background: #FFFFFF;
  border: 2px solid #919191;
  border-radius: 30px;
  display: flex;
  padding: 10px 18px;
  align-items: center;
  width: max-content;
  cursor: pointer;
}

.basket-card-header .cs label {
  display: block;
  font-weight: 400;
  font-size: 15px;
  color: #909090;
  margin: 0;
  margin-left: 7px;
}

.basket-card-body {
  margin: 35px 40px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 40px;
  padding: 40px 0;
}

.basket-card-body-payment-info-wrapper {
  background: linear-gradient(180deg, #FF5473 0%, #FF385C 100%);
  border-radius: 20px;
  padding: 20px;
}

.basket-card-body-payment-info-title {
  font-weight: 600;
  font-size: 32px;
  color: #FFFFFF;
  padding-bottom: 7px;
  border-bottom: 2px solid #C53B54;
  margin: 0 0 7px;
}

.basket-card-body-payment-info-table {
  width: 100%;
  margin: 10px 0 20px;
}

.basket-card-body-payment-info-table td {
  padding: 5px 10px;
}

.basket-card-body-payment-info-table label {
  font-weight: 400;
  font-size: 12px;
  color: #FFFFFF;
  margin: 0;
}

.basket-card-body-payment-info-table select,
.basket-card-body-payment-info-table input {
  font-weight: 400;
  font-size: 12px;
  color: #FFFFFF;
  margin: 0;
  width: 100%;

  border: none;
  background: none;
  border-bottom: 1px solid #F48D9F;
}

.basket-card-body-payment-info-table input::placeholder {
  color: #fff;
}

.basket-card-body-payment-info-table .one-third {
  width: 33.33%;
}

.basket-card-body-payment-info-table .payment-methods {
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 100%;
}

.basket-card-body-payment-info-table .credit-card {
  display: inline-block;
  border: none;

  background: #FFFFFF;
  border-radius: 30px;
  padding: 12px 22px;
  margin: 0 3px;
  font-size: 13px;
  font-weight: 500;
}

.basket-card-body-payment-info-table .paypal {
  display: inline-block;
  border: none;

  background: #003087;
  padding: 12px 22px;
  margin: 0 3px;
  border-radius: 30px;
  color: #fff;
  font-style: italic;
  font-size: 13px;
  font-weight: 700;
}

.basket-card-body-payment-info-table .credit-card svg {
  vertical-align: bottom;
}

.basket-card-body-payment-info-button {
  display: block;
  border: none;
  background: #fff;
  padding: 15px 50px;
  margin: 40px auto 10px;
  border-radius: 30px;
  color: #FF3D60;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

.basket-card-body-calculation-title {
  display: grid;
  grid-template-columns: 2.5fr 1fr;
}

.basket-card-body-calculation-title h3 {
  font-size: 20px;
  font-weight: 400;
  color: #565A5C;
}

.basket-card-body-calculation-single-product {
  display: grid;
  grid-template-columns: .5fr 2fr 1fr;
  border-top: 2px solid #CCCCCC;
  padding-top: 25px;
  border-bottom: 2px solid #CCCCCC;
  padding-bottom: 25px;
}

.basket-card-body-calculation-single-product .detail {
  display: flex;
}

.basket-card-body-calculation-single-product .detail .image {
  flex: 0 1 auto;
  position: relative;
  width: 185px;
  background: #565A5C;
  box-shadow: 0px 5px 10px rgba(33, 54, 88, 0.12);
  border-radius: 10px;
  align-self: flex-start;
}

.basket-card-body-calculation-single-product .detail .image .image-inner {
  box-sizing: border-box;
  padding-top: 100%;
  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.basket-card-body-calculation-single-product .detail .text {
  flex: 0 1 auto;
  padding: 0 25px;
}

.basket-card-body-calculation-single-product .detail .text label {
  display: block;
  font-weight: 600;
  font-size: 24px;
  color: #000000;
  margin: 0 0 10px;
}

.basket-card-body-calculation-single-product .detail .text p {
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  margin: 0 0 15px;
}

.basket-card-body-calculation-single-product .price {
  display: flex;
  justify-content: space-between;
}

.basket-card-body-calculation-single-product .price label {
  display: block;
  font-weight: 600;
  font-size: 20px;
  color: #000000;
  margin: 0 0 10px;
}

.basket-card-body-calculation-single-product .price h3.remove-btn {
  cursor: pointer;
  display: block;
  font-weight: 600;
  font-size: 20px;
  color: #979797;
  margin: 0 0 10px;
  padding: 0 5px;
}

.basket-card-body-calculation-single-addons {
  padding: 10px 0;
  display: none!important;
}

.basket-card-body-calculation-single-addon {
  padding: 10px 0;
  display: grid;
  grid-template-columns: .5fr 2fr 1fr;
}

.basket-card-body-calculation-single-addon .detail {
  padding-left: 38px;
}

.basket-card-body-calculation-single-addon .detail .bolt-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #F4F4F4;
  border-radius: 30px;
  padding: 10px 30px;
  max-width: 420px;
  width: 100%;
}

.basket-card-body-calculation-single-addon .detail .bolt-card-image {
  flex: 0 1 auto;
  height: 42px;
  width: 42px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 10px;
  margin-right: 10px;
}

.basket-card-body-calculation-single-addon .detail .bolt-card-text {
  flex: 1 1 auto;
}

.basket-card-body-calculation-single-addon .detail .bolt-card-text label {
  display: block;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  margin: 0 0 5px;
}

.basket-card-body-calculation-single-addon .detail .bolt-card-text p {
  display: block;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  margin: 0;
  padding-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.basket-card-body-calculation-single-addon .detail .bolt-card-remove-btn {
  cursor: pointer;
  flex: 0 1 auto;
  display: block;
  font-weight: 600;
  font-size: 20px;
  color: #979797;
  margin: 0;
  padding: 0 15px;
}

.basket-card-body-calculation-single-addon .price {
  display: flex;
  align-items: center;
}

.basket-card-body-calculation-single-addon .price label {
  display: block;
  font-weight: 400;
  font-size: 20px;
  color: #000000;
  padding-left: 10px;
  margin: 0;
}

.basket-card-body-calculation-single-total {
  display: grid;
  grid-template-columns: 2.5fr 1fr;
  border-top: 2px solid #CCCCCC;
  padding-top: 25px;
}

.basket-card-body-calculation-single-total .terms {
  display: flex;
  align-items: center;
}

.basket-card-body-calculation-single-total .terms label {
  font-weight: 500;
  font-size: 16px;
  color: #808080;
  margin: 0;
}

.basket-card-body-calculation-single-total .terms label span {
  color: #E21E1E;
}

.basket-card-body-calculation-single-total .terms input[type='checkbox'] {
  /* -webkit-appearance:none; */
  width: 28px;
  height: 28px;
  border-radius: 5px;
  background: #EDEDED;
  border: 1px solid #B5B5B5;
  margin-right: 10px;
}

.basket-card-body-calculation-single-total .terms input[type='checkbox']:checked {
  background: #FF385C;
}

.basket-card-body-calculation-single-total .terms input[type='checkbox']:checked:after {
  content: "✔";
  color: #fff;
  position: absolute;
  font-size: 90%;
  left: 0.0625em;
  top: -0.25em;
}

.basket-card-body-calculation-single-total .price label {
  display: none;
  font-weight: 700;
  font-size: 24px;
  color: #000000;
  margin: 0;
}

.basket-card-body-calculation-single-total .detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.basket-card-body-calculation-single-total .detail .cs {
  background: #FFFFFF;
  border: 2px solid #919191;
  border-radius: 30px;
  display: flex;
  padding: 10px 18px;
  align-items: center;
  width: max-content;
  cursor: pointer;
}

.basket-card-body-calculation-single-total .detail .cs label {
  display: block;
  font-weight: 400;
  font-size: 15px;
  color: #909090;
  margin: 0;
  margin-left: 7px;
}

.basket-card-body-calculation-single-total .detail .text h3 {
  display: none;
  font-weight: 600;
  font-size: 24px;
  color: #FF385C;
  margin: 0;
}

.basket-card-body-calculation-single-total .detail .text {
  padding: 0 30px;
}

.basket-card-body-calculation-single-total .detail .text p {
  display: block;
  font-weight: 400;
  font-size: 16px;
  color: #565A5C;
  margin: 0;
}

.basket-card-body-feilds {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
}

.basket-card-body-feilds label {
  display: block;
  font-weight: 500;
  font-size: 18px;
  color: #000000;
  margin: 0;
  margin-bottom: 7px;
}

.basket-card-body-feilds input {
  background: #FFFFFF;
  border: 1px solid #AFAFAF;
  border-radius: 5px;
  padding: 12px;
  width: 100%;
}

.basket-card-footer {
  background: #FF385C;
  border: 1px solid #D1D1D1;
  border-radius: 0px 0px 20px 20px;
  padding: 25px 45px;
}

.basket-card-footer .gocardless {
  display: flex;
  align-items: center;
}

.basket-card-footer .gocardless .gocardless-text {
  flex: 0 1 auto;
}

.basket-card-footer .gocardless .gocardless-text label {
  display: block;
  font-weight: 500;
  font-size: 24px;
  color: #FFFFFF;
  margin: 0;
  margin-right: 20px;
}

.basket-card-footer .gocardless .gocardless-image {
  flex: 1 1 auto;
}

.basket-card-footer .gocardless .gocardless-image img {
  max-width: 100%;
}

.basket-card-footer .gocardless .basket-card-body-payment-info-button {
  flex: 0 1 auto;
  padding: 15px 70px;
  margin: 10px auto 10px;
  border-radius: 10px;
}

@media (max-width: 1128px) {
  .basic-store-products {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 1023px) {

  .basic-store-wrapper,
  .basic-banner-wrapper {
    padding: 20px 20px 50px;
  }

  .basic-banner-card-text,
  .basic-banner-card-image-wrapper {
    padding: 30px;
  }

  .basic-banner-card {
    display: flex;
    flex-direction: column;
  }

  .basic-banner-card-image-wrapper {
    flex: 1;
    order: 1;
  }

  .basic-banner-card-text {
    flex: 1;
    order: 2;
  }

  .basic-store-controls {
    display: block;
  }

  .basic-store-controls-title {
    margin-bottom: 20px;
  }

  .basic-store-products {
    grid-template-columns: 1fr 1fr;
  }

  .basket-card-body,
  .basket-card-body-calculation-single-product,
  .basket-card-body-calculation-title {
    grid-template-columns: 1fr;
  }

  .basket-card-body-calculation-single-product .detail {
    display: block;
  }

  .basket-card-body-calculation-single-product .detail .image {
    margin: 10px auto;
    width: 90%;
  }

  .basket-card-body-calculation-title h3:last-child {
    display: none;
  }

  .basket-card-body-calculation-single-addon .detail,
  .basket-card-body-calculation-single-product .detail .text {
    padding: 0;
  }

  .basket-card-body-feilds {
    grid-template-columns: 1fr;
  }

  .basket-card-footer .gocardless {
    display: block;
    text-align: center;
  }

  .basket-card-footer .gocardless .gocardless-text label {
    margin: 0;
  }
}

@media (max-width: 767px) {
  .basic-store-controls-dropdowns {
    display: block;
  }

  .basic-store-controls-sort,
  .basic-store-controls-looking {
    width: 100%;
    min-width: 0;
    max-width: 100%;
  }

  .basic-store-controls-sort-wrapper {
    margin-top: 20px;
  }

  .basic-store-products {
    grid-template-columns: 1fr;
  }

  .basket-card-body-calculation-single-addon,
  .basket-card-body-calculation-single-total {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }

  .basket-card-body-calculation-single-total .detail,
  .basket-card-body-payment-info-table .payment-methods {
    display: block;
  }

  .basket-card-body-payment-info-table .credit-card {
    margin-bottom: 10px;
  }

  .basket-card {
    padding: 0;
    margin: 0;
  }

  .basket-card-header {
    /* padding: 35px 0 0; */
    margin: 20px;
    display: block;
  }

  .basket-card-header>* {
    margin-bottom: 10px !important;
  }

  .basket-card-body {
    padding: 0;
    margin: 20px;
  }

  .basket-card-header .title {
    font-size: 24px;
  }

  .basket-card-body-calculation-single-total .detail .text {
    padding: 20px 0 0;
  }

  .basket-card-body-calculation-single-addon .detail .bolt-card {
    display: block;
    max-width: 100%;
  }

  .basket-card-body-calculation-single-addon .detail .bolt-card-text p {
    max-width: 100%;
    white-space: unset;
    overflow: unset;
    text-overflow: unset;
  }

  .basket-card-body-calculation-single-addon .detail .bolt-card-text {
    display: inline-block;
    width: calc(100% - 30px);
  }

  .basket-card-body-calculation-single-addon .detail .bolt-card-remove-btn {
    text-align: center;
    display: block;
    border-radius: 50%;
    margin: 10px 2px;
    background-color: #979797;
    color: #ffffff;
    padding: 0;
    height: 26px;
    width: 26px;
    float: right;
  }

  .basic-store-controls-buttons {
    grid-template-columns: 1fr;
  }

  .basic-store-search-inner button {
    display: none;
  }

  .basic-store-search-inner input {
    min-height: 55px;
  }
}

.mr-10px {
  margin-right: 10px;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.swiper-pagination-bullet-active {
  background-color: #FF385C !important;
}

#meetparker-widget .message-bubble.user,
#meetparker-widget .message-bubble.agent,
#meetparker-widget .message-bubble.bot,
#meetparker-widget .conversation-header {
  background: #ff385c !important;
}

.clippy-top-red-white {
  background-color: #fff;
  margin-bottom: -1px;
}

.clippy-top-red-white>div {
  clip-path: polygon(0 0, 0 100%, 100% 100%);
  background: #70212F;
  height: 160px;
  margin-bottom: -1px;
}

.clippy-top-white-red {
  background-color: #70212F;
  margin-bottom: -1px;
}

.clippy-top-white-red>div {
  clip-path: polygon(0 0, 0 100%, 100% 100%);
  background: #fff;
  height: 160px;
  margin-bottom: -1px;
}

.lbanner-red-transparent {
  background-color: transparent;
  margin-bottom: -1px;
}

.lbanner-red-transparent>div {
  clip-path: polygon(0 0, 0 100%, 100% 100%);
  background: #70212F;
  height: 160px;
  margin-bottom: -1px;
}

.lbanner-white-transparent {
  background-color: transparent;
  margin-bottom: -1px;
}

.lbanner-white-transparent>div {
  clip-path: polygon(0 0, 0 100%, 100% 100%);
  background: #fff;
  height: 160px;
  margin-bottom: -1px;
}

@media (max-width: 1023px) {

  .lbanner-white-transparent>div,
  .lbanner-red-transparent>div,
  .clippy-top-red-white>div,
  .clippy-top-white-red>div {
    height: 80px;
  }
}

@media (max-width: 767px) {

  .lbanner-white-transparent>div,
  .lbanner-red-transparent>div,
  .clippy-top-red-white>div,
  .clippy-top-white-red>div {
    height: 40px;
  }
}

/** landing **/
.ldetails-wrapper {
  padding: 0;
  background-color: #70212F;
  margin-bottom: -1px;
  max-width: 100vw;
  overflow-x: hidden;
}

.ldetails-points,
.ldetails-title,
.ldetails {
  padding: 50px 15px;
  max-width: 1036px;
  margin: auto;
}

.ldetails-title h2 {
  font-weight: 600;
  font-size: 54px;
  color: #fff;
  margin: 0 0 20px;
  text-align: center;
}

.ldetails-title p {
  font-weight: 400;
  font-size: 24px;
  color: #fff;
  margin: 0 0 50px;
  text-align: center;
}

.ldetails {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
}

.ldetails-single {
  background: #FFFFFF;
  border-radius: 20px;
  padding: 20px;
  min-height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ldetails-single {
  font-weight: 500;
  font-size: 24px;
  color: #8C2A3C;
  margin: 0px;
  text-align: center;
}

.ldetails-graphics {
  padding: 0 15px 50px;
  max-width: 1036px;
  margin: auto;
}

.ldetails-graphics img {
  max-width: 100%;
}

.lbanner-wrapper {
  padding: 0;
  background: linear-gradient(180deg, #70212F 17%, #DD3251 87.66%);
  transform: matrix(-1, 0, 0, 1, 0, 0);
  max-width: 100vw;
  overflow: hidden;
}

.lbanner-wrapper>* {
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.lbanner {
  padding: 50px 15px;
  max-width: 1036px;
  margin: auto;
}

.lbanner h1 {
  font-weight: 700;
  font-size: 70px;
  color: #fff;
  margin: 0 0 50px;
  text-align: center;
}

.lbanner h1 span {
  color: #FF385C;
}

.lbanner h2 {
  font-weight: 700;
  font-size: 48px;
  color: #fff;
  margin: 0 0 40px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.lbanner p {
  font-weight: 500;
  font-size: 24px;
  color: #fff;
  margin: 0 0 40px;
  text-align: center;
}

.lbanner .btn.btn-success {
  border: none;
  display: block;
  width: max-content;
  margin: 0 auto 20px;
  background: linear-gradient(180deg, #9BC853 0%, #6A991F 100%);
  box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.25);
  border-radius: 28px;
}

.ldetails-graphics-inner {
  display: inline-block;
  position: relative;
  max-width: 100%;
}

.swiper-ld {
  position: absolute !important;
  top: 10.5%;
  bottom: 16.5%;
  left: 16.2%;
  right: 14%;
  border-top-left-radius: 2%;
  border-top-right-radius: 2%;
}

.swiper-ld .img-ld {
  padding-top: 65%;
  background-position: center;
  background-size: cover;
}

.iframe-ld {
  background-color: #fff;
  -ms-zoom: .75;
  background: #fff;
  border: none;
  border-top-left-radius: 3.2%;
  border-top-right-radius: 3.2%;
  height: 144%;
  left: 16.3%;
  outline: none;
  position: absolute;
  top: 10.7%;
  -moz-transform: scale(.5);
  -o-transform: scale(.5);
  -webkit-transform: scale(.5);
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  width: 139.7%;
}

.ldetails-points {
  display: flex;
  align-items: center;
}

.ldetails-points .ldetails-points-img {
  flex: 1 1 auto;
  width: 60%;
}

.ldetails-points .ldetails-points-img img {
  max-width: 100%;
}

.ldetails-points .ldetails-points-inner {
  flex: 1 1 auto;
  width: 40%;
  padding: 0 10px;
}

.ldetails-points .ldetails-point {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.ldetails-points .ldetails-point img {
  flex: 0 1 auto;
}

.ldetails-points .ldetails-point h4 {
  flex: 1 1 auto;
  padding-left: 30px;
  font-weight: 400;
  font-size: 20px;
  color: #fff;
  margin: 0;
}

@media (max-width: 1023px) {
  .lbanner h1 {
    min-height: 185px;
  }
}

@media (max-width: 767px) {

  .ldetails-points,
  .ldetails-title,
  .ldetails,
  .ldetails-graphics,
  .lbanner {
    padding: 15px;
  }

  .ldetails {
    grid-template-columns: 1fr;
  }

  .ldetails-points {
    display: block;
  }

  .ldetails-points .ldetails-points-img,
  .ldetails-points .ldetails-points-inner {
    width: 100%;
  }

  .lbanner h2 {
    font-size: 32px;
  }

  .lbanner h1 {
    font-size: 40px;
    min-height: 107px;
  }
}

.cookies {
  padding: 20px;
  background: #FF385C;
  display: flex;
  align-items: center;
  position: fixed;
  margin: 0;
  width: 100vw;
  box-sizing: border-box;
  bottom: 0;
}

.cookies-details {
  flex: 1 1 auto;
}

.cookies-details {
  flex: 1 1 auto;
}

.cookies-details h4 {
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 5px;
  color: #fff;
}

.cookies-details p {
  font-size: 15px;
  font-weight: 400;
  margin: 0;
  color: #fff;
}

.cookies-details a {
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  text-decoration: underline;
}

.cookies button {
  margin: 10px;
  min-width: 175px;
  border: none;
  outline: none;
  font-size: 15px;
  font-weight: 400;
  padding: 16px;
  background: #6D1827;
  color: #fff;
  border-radius: 10px;
}

@media (max-width: 767px) {
  .cookies {
    display: block;
  }

  .cookies button {
    display: block;
    margin: 20px 0 0;
  }
}