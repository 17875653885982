.featured-in-wrapper {
  padding: 50px 30px;
  background-color: #F8F8F8;
}
.featured-in {
  max-width: 1160px;
  margin: auto;
}
.featured-in-text h2 {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 26px;
  color: #565A5C;
  margin: 0 0 5px;
  text-align: center;
}
.featured-in-images {
  padding: 30px 0 10px;
}
.featured-in-images .featured-in-images-inner {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.featured-in-images .featured-in-images-inner .image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 20px;
}
.featured-in-images .featured-in-images-inner .image-wrapper img {
  max-width: 100%;
}

@media (max-width: 1023px) {
  .featured-in-images .featured-in-images-inner {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 767px) {
  .featured-in-images .featured-in-images-inner {
    grid-template-columns: 1fr;
  }
}