.call-to-actions {
  background-color: #70212f;
  overflow: auto;
  
  .call-to-action {
    max-width: 1024px;
    margin: 20px auto;
    display: flex;
    align-items: center;

    .call-to-action-image {
      flex: 0 1 auto;
      width: 60%;
      text-align: center;

      img {
        max-width: 80%;
      }
    }

    .call-to-action-details {
      flex: 0 1 auto;
      width: 40%;
      padding: 20px;

      h2 {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 1.2;
        color: #FFFFFF;
        margin: 0 0 12px 0;
      }

      p {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 1.2;
        color: #FFFFFF;
        margin: 0 0 20px 0;
      }

      a {
        display: block;
        width: max-content;
        padding: 12px 20px;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 12px;
        line-height: 1;
        color: #FFFFFF;
        background: #FF385C;
        border-radius: 50px;
      }
    }

    .call-to-action-image,
    .call-to-action-details {
      &:first-child {
        &:last-child {
          flex: 0 1 auto;
          width: 100%;
        }
      }
    }

    &.odd {
      @media (min-width: 768px) {
        .call-to-action-details { order: 1; }
        .call-to-action-image { order: 2; }
      }
    }

    @media (max-width: 1023px) {
      .call-to-action-details {
        h2 {
          font-size: 32px;
        }
  
        p {
          font-size: 16px;
        }
      }

      @media (max-width: 767px) {
        display: block;
        margin-bottom: 60px;

        .call-to-action-image,
        .call-to-action-details {
          width: 100%;
        }
      }
    }
  }
}