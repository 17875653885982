.banner-hpac-portal-wrapper {
  padding: 0;
  background-color: #ffffff;
  max-width: 100vw;
  overflow: hidden;
}
.banner-hpac-portal-wrapper .banner-hpac-portal {
  max-width: 100vw;
  margin: auto;
  background-color: #05202e;
  height: calc(100vh - 100px);
  display: grid;
  grid-template-columns: 1.2fr 1fr;
}
.banner-hpac-portal-wrapper .banner-hpac-portal .banner-hpac-portal-image {
  background-image: url('https://d365pq86x330zn.cloudfront.net/66101651-6156-40 6-1601-722611165561.jpg');
  background-position: center;
  background-size: cover;
}
.banner-hpac-portal-wrapper .banner-hpac-portal-sign-in {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}
.banner-hpac-portal-wrapper .banner-hpac-portal-sign-in-inner {
  max-width: 280px;
}
.banner-hpac-portal-wrapper h2 {
  font-weight: 600;
  font-size: 32px;
  color: #fff;
  margin: 0 0 10px;
}
.banner-hpac-portal-wrapper p {
  font-weight: 500;
  font-size: 12px;
  color: #fff;
  margin: 0 0 10px;
}
.banner-hpac-portal-wrapper label {
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  margin: 0 0 10px;
  display: block;
}
.banner-hpac-portal-wrapper input {
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 16px;
  color: #000;
  background-color: #fff;
  padding: 10px 15px;
  border-radius: 5px;
  display: block;
  width: 100%;
  cursor: pointer;
}
.banner-hpac-portal-wrapper img {
  max-width: 100%;
}
.banner-hpac-portal-wrapper a {
  color: #35A3C1;
}
.banner-hpac-portal-wrapper button {
  border: none;
  outline: none;
  background-color: #4ECBEE;
  color: #fff;
  font-weight: 700;
  font-size: 12px;
  padding: 15px 15px;
  border-radius: 5px;
  display: block;
  width: 100%;
  cursor: pointer;
}

@media (max-width: 767px) {
  .banner-hpac-portal-wrapper {
    padding: 0;
  }
  .banner-hpac-portal-wrapper .banner-hpac-portal {
    grid-template-columns: 1fr;
  }
}