.how-meetparker-works-wrapper {
  padding: 70px 15px;
  background-color: #262626;
  margin-bottom: -1px;
  max-width: 100vw;
  overflow: hidden;
}
.how-meetparker-works {
  max-width: 1036px;
  margin: auto;
}
.how-meetparker-works h2 {
  font-weight: 700;
  font-size: 40px;
  color: #fff;
  margin: 0 auto 30px;
  max-width: 780px;
  text-align: center;
}
.how-meetparker-works h3 {
  font-weight: 600;
  font-size: 32px;
  color: #fff;
  margin: 0 auto 30px;
  text-align: center;
}
.how-meetparker-works h4 {
  font-weight: 600;
  font-size: 20px;
  color: #fff;
  margin: 0 auto 30px;
  text-align: center;
}
.how-meetparker-works h5 {
  font-weight: 600;
  font-size: 20px;
  color: #262626;
  margin: 10px auto 20px;
  text-align: center;
}
.how-meetparker-works span {
  display: block;
  font-weight: 500;
  font-size: 15px;
  color: #262626;
  margin: 0 auto 10px;
  text-align: center;
}
.how-meetparker-works p {
  font-weight: 300;
  font-size: 16px;
  color: #fff;
  margin: 0 auto 30px;
  max-width: 780px;
  text-align: center;
}
.how-meetparker-works img {
  display: block;
  max-width: 100%;
  margin: 0 auto 20px;
}
.how-meetparker-works .line-small-white-v {
  display: block;
  border-radius: 5px;
  width: 10px;
  height: 50px;
  margin: 10px auto;
  background-color: #fff;
}
.how-meetparker-works .line-medium-white-v {
  display: block;
  border-radius: 5px;
  width: 10px;
  height: 100px;
  margin: 10px auto;
  background-color: #fff;
}
.how-meetparker-works .line-full-white-h {
  display: block;
  border-radius: 5px;
  width: 100%;
  height: 10px;
  margin: auto;
  background-color: #fff;
}
.how-meetparker-works .line-medium-red-v {
  display: block;
  border-radius: 5px;
  width: 10px;
  height: 100px;
  margin: 10px auto;
  background-color: #FF385C;
}
.how-meetparker-works .steps-outer {
  display: grid;
  grid-template-columns: 424fr 265fr 265fr;
  grid-gap: 40px;
}
.how-meetparker-works .steps-inner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.how-meetparker-works .step {
  background: #FFFFFF;
  border-radius: 13px;
  padding: 20px;
  min-height: 145px;
}

@media (max-width: 1023px) {
  
}

@media (max-width: 767px) {
  .how-meetparker-works .steps-inner,
  .how-meetparker-works .steps-outer {
    grid-template-columns: 1fr;
  }
}