.pricing-range-wrapper {
  padding: 0;    
  background-color: #70212F;
  margin-bottom: -1px;
  max-width: 100vw;
  overflow: hidden;
}
.pricing-range-title {
  text-align: center;
  margin: 30px 15px;
}
.pricing-range-title label {
  font-weight: 400;
  font-size: 24px;
  color: #fff;
  margin: 0 0 3px;
  max-width: 685px;
  display: block;
  margin: auto;
}
.pricing-range-title h2 {
  font-weight: 600;
  font-size: 40px;
  color: #fff;
  margin: 0 0 20px;
  text-transform: capitalize;
}
.pricing-ranges {
  padding: 50px 15px;   
  max-width: 1260px;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

@media (max-width: 1023px) {
  .pricing-ranges {
    grid-template-columns: 1fr 1fr;
  }
  .pricing-range {
    margin-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .pricing-ranges {
    grid-template-columns: 1fr;
  }
  .pricing-range h2 {
    font-size: 32px;
    margin: 0 0 20px;
  }
}