.its-time-to-meetparker-form-wrapper {
  padding: 30px 30px 120px;
  background-color: #fff;
  background-image: url('https://d365pq86x330zn.cloudfront.net/164258a0-5121-4H64-6850-4a6551151422.png');
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  box-sizing: border-box;
}
.its-time-to-meetparker-form-wrapper img {
  max-width: 100%;
}
.its-time-to-meetparker-form {
  max-width: 1400px;
  margin: auto;
  display: grid;
  grid-template-columns: 10fr 6fr;
}
.its-time-to-meetparker-form-text,
.its-time-to-meetparker-form-fields {
  padding: 0 28px;
}
.its-time-to-meetparker-form-vid {
  border: none;
  outline: none;
  margin: 50px 3%;
  width: 80%;
  height: 350px;
}
.its-time-to-meetparker-form-text h1 {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 32px;
  color: #565A5C;
  margin: 0 0 25px;
  max-width: 680px;
}
.its-time-to-meetparker-form-text h1 label {
  color: #FF385C;
}
.its-time-to-meetparker-form-text h2 {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 32px;
  color: #565A5C;
  margin: 0 0 25px;
  max-width: 680px;
}
.its-time-to-meetparker-form-text p {
  font-weight: 400;
  font-size: 32px;
  color: #565A5C;
  margin: 0 0 25px;
  max-width: 680px;
}
.its-time-to-meetparker-form-fields-card {
  background: #FFFFFF;
  box-shadow: 7px 14px 42px rgba(0, 0, 0, 0.2);
  border-radius: 30px;
  padding: 40px 18px;
}
.its-time-to-meetparker-form-fields h3 {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 28px;
  color: #565A5C;
  margin: 0 0 25px;
  max-width: 680px;
}
.its-time-to-meetparker-form-fields h3 label {
  color: #FF385C;
  font-size: 32px;
}
.its-time-to-meetparker-form-fields .form-row {
  display: flex;
}
.its-time-to-meetparker-form-fields .form-col {
  flex: 1 1 auto;
  width: 50%;
  padding: 20px 10px;
}
.its-time-to-meetparker-form-fields .form-group {
  display: flex;
  position: relative;
}
.its-time-to-meetparker-form-fields .form-group label {
  min-width: 110px;
  font-weight: 500;
  font-size: 16px;
  color: #565A5C;
  text-align: left;
  padding: 10px;
  display: flex;
  align-items: center;
}
.its-time-to-meetparker-form-fields .form-group input {
  flex: 1 1 auto;
  font-size: 16px;
  background: #FFFFFF;
  border: 1px solid #C9C9C9;
  border-radius: 5px;
  padding: 18px;
  outline-color: #FFA4B4;
  max-width: 100%;
  box-sizing: border-box;
}
.its-time-to-meetparker-form-fields .form-group input[type="submit"] {
  background: linear-gradient(180deg, rgba(255, 56, 92, 0.75) 0%, #FF385C 100%);
  border-radius: 10px;
  border: none;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 24px;
  color: #fff;
  transform: scale(.85);
  transition: transform .5s, background .5s;
}
.its-time-to-meetparker-form-fields .form-group input[type="submit"]:hover {
  background: linear-gradient(180deg, #EB4E4E 0%, #E41E1E 100%);
  transform: scale(1);
}

@media (max-width: 1023px) {
  .its-time-to-meetparker-form-wrapper {
    padding: 30px;
  }
  .its-time-to-meetparker-form-fields .form-row {
    flex-direction: column;
  }
  .its-time-to-meetparker-form-fields .form-col {
    width: auto;
    padding: 10px;
  }
  .its-time-to-meetparker-form {
    display: block;
  }
  .its-time-to-meetparker-form-text,
  .its-time-to-meetparker-form-fields {
    padding: 0;
  }
  .its-time-to-meetparker-form-vid {
    margin: 50px 0;
    width: 100%;
    height: 350px;
  }
  .its-time-to-meetparker-form-text h1,
  .its-time-to-meetparker-form-text h2,
  .its-time-to-meetparker-form-text p,
  .its-time-to-meetparker-form-fields h3 {
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .its-time-to-meetparker-form-fields .form-group {
    display: block;
  }
  .its-time-to-meetparker-form-fields .form-group input {
    width: 100%;
  }
}