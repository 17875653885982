
.post-wrapper {
  background-color: #f8f8f8;
  background-image: url('https://d365pq86x330zn.cloudfront.net/216i7661-6669-4116-6701-15551Bu6ii97.png');
  background-size: 100% auto;
  background-position: bottom;
  background-repeat: no-repeat;
  box-sizing: border-box;
}

.post {
  max-width: 1110px;
  margin: auto;
}

.post .fw-reg {
  font-weight: 400;
}


.post h1 {
  font-weight: 700;
  font-size: 40px;
  color: #565A5C;
  margin: 0 0 5px;
}

.post h2 {
  font-weight: 700;
  font-size: 24px;
  color: #565A5C;
  margin: 0 0 5px;
}

.post h3 {
  font-weight: 700;
  font-size: 20px;
  color: #565A5C;
  margin: 0 0 5px;
}

.post p {
  font-weight: 400;
  font-size: 20px;
  color: #565A5C;
  margin: 0 0 5px;
}

.post label {
  font-weight: 400;
  font-size: 24px;
  color: #565A5C;
  margin: 0 0 5px;
}

.post .post-title {
  padding: 15px 30px;
  color: #FF385C;
  text-align: center;
}

.post .post-info {
  text-align: center;
}
.post .post-info span,
.post .post-info a {
  color: #FF385C;
}

.post .post-subtitle {
  text-align: center;
}

.post .post-subtitle h2 {
  font-size: 32px;
}

.post .post-subtitle h2 {
  font-size: 32px;
  text-align: center;
}

.post .post-image {
  margin: 15px 30px;
  text-align: center;
}

.post .post-image img {
  max-width: 100%;
}

.post .post-content {
  padding: 15px 30px;
  margin: auto;
  max-width: 980px;
}

.post .grid {
  display: grid;
  grid-template-columns: 1fr;
}

.post .grid {
  display: grid;
}

.post .grid.c1fr4fr {
  grid-template-columns: 1fr 4fr;
}

.post .grid.c1fr7fr {
  grid-template-columns: 1fr 7fr;
}

@media (max-width: 1023px) {
}

@media (max-width: 767px) {

  .post .grid.c1fr4fr {
    display: grid;
    grid-template-columns: 1fr;
  }
}