header.header {
  background-color: #70212F;
  padding: 10px 30px;
  box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px;
  position: fixed;
  width: 100%;
  z-index: 99999999;
  box-sizing: border-box;
}
.header-placeholder {
  background-color: #70212F;
  padding-top: 92px;
}
header.header label,
header.header a {
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.2;
  color: #fff;
  letter-spacing: 1px;
}
header.header a.red {
  color: #FF385C;
}
header.header .nav {
  max-width: 1200px;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 220px 1fr;
  align-items: center;
}
header.header .nav .left-navbar,
header.header .nav .logo-wrapper,
header.header .nav .right-navbar {
  flex: 1 1 auto;
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0;
  margin: 0;
}
header.header .nav .left-navbar {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
header.header .nav .logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
}
.space-filler,
header.header .nav .logo-wrapper > .toggle-close,
header.header .nav .logo-wrapper > .toggle-open {
  opacity: 0;
  display: none;
}
header.header .nav .right-navbar {
  grid-template-columns: auto auto;
  text-align: right;
}
header.header .nav .left-navbar > li ,
header.header .nav .right-navbar > li {
  flex: 1 1 auto;
  position: relative;
}
header.header .nav .right-navbar > li {
  display: flex;
  justify-content: end;
  align-items: center;
}

header.header .nav .left-navbar > li > .sub-menu,
header.header .nav .right-navbar > li > .sub-menu {
  position: absolute;
  top: 100%;
  right: 0;
  text-align: left;
  list-style: none;
  min-width: max-content;
  padding: 10px;
  background: #70212F;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-bottom: 3px solid #fff;
  display: none;
}

header.header .nav .left-navbar > li:hover > .sub-menu,
header.header .nav .right-navbar > li:hover > .sub-menu {
  display: block;
}

header.header .nav .left-navbar > li > .sub-menu > li,
header.header .nav .right-navbar > li > .sub-menu > li {
  padding: 5px 20px;
}

header.header .btn {
  text-transform: none;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2;
  padding: 14px 18px;
  margin: 0 0 0 10px;
  color: #fff;
  display: inline-block;
}
header.header .icon {
  margin: auto 12px;
}
header.header .icon:first-child {
  margin-left: 0;
}
header.header .icon:last-child {
  margin-right: 0;
}

header.header .logo {
  max-width: 105px;
  max-height: 90px;
}

@media (max-width: 1023px) {
  header.header label {
    display: block;
    margin-bottom: 10px;
  }
  header.header .btn {
    margin: 10px 0;
  }
  header.header {
    background-color: #70212F;
    padding: 10px 20px;
    box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px;
    max-height: 100vh;
    overflow-y: auto;
  }
  .header-placeholder {
    padding-top: 63px;
  }
  header.header .nav {
    grid-template-columns: 1fr;
  }
  header.header .nav .left-navbar,
  header.header .nav .right-navbar {
    display: none;
    padding: 0 10px;
    text-align: left;
    width: auto;
  }
  .header.open .nav .left-navbar,
  .header.open .nav .right-navbar {
    display: block;
  }
  header.header .nav .left-navbar > li,
  header.header .nav .right-navbar > li {
    padding: 10px 0;
  }
  header.header .nav .left-navbar {
    padding-top: 30px;
    order: 2;
  }
  header.header .nav .logo-wrapper {
    order: 1;
    justify-content: space-between;
    padding: 0 10px;
    width: auto;
  }
  header.header .nav .right-navbar {
    padding-bottom: 30px;
    order: 3;
  }
  header.header .nav .logo-wrapper img {
    height: 40px;
    width: auto;
  }
  .space-filler,
  header.header .nav .logo-wrapper > .toggle-close,
  header.header .nav .logo-wrapper > .toggle-open {
    opacity: 1;
    display: block;
  }
  header.header .nav .logo-wrapper > .toggle-close {
    display: none;
  }
  .header.open .nav .logo-wrapper > .toggle-open {
    display: none;
  }
  .header.open .nav .logo-wrapper > .toggle-close {
    display: block;
  }
  header.header .nav .left-navbar > li > .sub-menu,
  header.header .nav .right-navbar > li > .sub-menu {
    display: block;
    position: relative;
    border: none;
    padding: 10px 30px 0;
    box-shadow: none;
  }  
  header.header .nav .left-navbar > li > .sub-menu > li,
  header.header .nav .right-navbar > li > .sub-menu > li {
    padding: 10px 0;
  }  
  header.header .nav .left-navbar > li > .sub-menu > li:last-child,
  header.header .nav .right-navbar > li > .sub-menu > li:last-child {
    padding-bottom: 0;
  } 
  header.header .nav .right-navbar > li {
    justify-content: start;
  }   
}