.customer-wrapper {
  padding: 70px 15px;
  background-color: #262626;
  margin-bottom: -1px;
  max-width: 100vw;
  overflow: hidden;
}
.customer {
  max-width: 850px;
  margin: auto;
}
.customer hr {
  border: 1px solid #434343;
  margin: 50px auto;
  max-width: 550px;
}
.customer .cus-img-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.customer-wrapper img {
  max-width: 100%;
  margin: auto;
  display: block;
}
.customer-rating {
  max-width: 1200px;
  margin: auto;
}
.customer-rating h2 {
  font-weight: 700;
  font-size: 40px;
  letter-spacing: -1px;
  color: #fff;
  margin: 0 auto 30px;
  max-width: 400px;
  text-align: center;
}
.customer-rating .customer-ratings {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 40px;
  padding: 40px 0;
}
.customer-rating .customer-ratings  .customer-single-rating {
  background: #FFFFFF;
  border-radius: 13px;
  padding: 15px;
}
.customer-rating .customer-ratings  .customer-single-rating .customer-single-rating-details {
  display: flex;
  justify-content: center;
  align-items: center;
}
.customer-rating .customer-ratings  .customer-single-rating .customer-single-rating-details .customer-single-rating-img {
  flex: 0 1 auto;
  width: 43px;
  height: 43px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.customer-rating .customer-ratings  .customer-single-rating .customer-single-rating-details .customer-single-rating-info {
  flex: 1 1 auto;
  padding: 10px;
}
.customer-rating .customer-ratings  .customer-single-rating label {
  font-weight: 600;
  font-size: 14px;
  display: block;
  color: #000000;
  margin: 0;
}
.customer-rating .customer-ratings .customer-single-rating span {
  font-weight: 400;
  font-size: 12px;
  display: block;
  color: #939393;
  margin: 0;
}
.customer-rating .customer-ratings .customer-single-rating p {
  font-weight: 400;
  font-size: 12px;
  display: block;
  color: #000000;
  margin: 20px 0;
}

@media (max-width: 1023px) {
  .customer-rating .customer-ratings {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 767px) {
  .customer {
    display: block;
  }
  .customer-rating h2 {
    font-size: 32px;
    margin: 0 auto 20px;
  }
  .customer .customer-input {
    padding-right: 0;
    border-right: none;
    padding-bottom: 40px;
    border-bottom: 1px solid #5E5E5E;
    margin-bottom: 40px;
  }
  .customer-rating .customer-ratings {
    grid-template-columns: 1fr;
  }
}