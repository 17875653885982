.features-list {
  background-color: #70212f;
  padding: 50px 20px;
  overflow: auto;
  
  .features-list-inner {
    max-width: 1024px;
    margin: 20px auto;

    h2 {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 52px;
      line-height: 1.2;
      color: #FFFFFF;
      margin: 0 0 20px 0;
      text-align: center;
    }

    .features-wrapper {
      display: flex;
      align-items: center;
  
      .features-image {
        flex: 0 1 auto;
        width: 60%;
        text-align: center;
  
        img {
          max-width: 80%;
        }
      }
  
      .features {
        flex: 0 1 auto;
        width: 40%;
        padding: 20px;
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 30px;
        padding: 50px 0;
  
        .feature-image {
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            max-width: 40px;
          }
        }

        .feature-text {
          padding: 10px;

          label {
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 1.2;
            color: #FFFFFF;
          }
        }
      }
  
      .features-image,
      .features {
        &:first-child {
          &:last-child {
            flex: 0 1 auto;
            width: 100%;
          }
        }
      }
    }

    @media (max-width: 1023px) {
      h2 {
        font-size: 32px;
      }

      @media (max-width: 767px) {
        h2 {
          font-size: 28px;
        }

        .features-wrapper {
          display: block;

          .features-image,
          .features {
            width: 100%;
          }
        }
      }
    }
  }
}