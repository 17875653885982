.grow-wrapper {
  background-color: #70212F;
  background: linear-gradient(180deg, #E73F5E 0%, #AE2E45 71%);;
  margin-bottom: -1px;
  max-width: 100vw;
  overflow: hidden;
}
.grow {
  max-width: 1036px;
  margin: auto;
  padding: 42px 15px;
}
.grow h2 {
  font-weight: 600;
  font-size: 36px;
  color: #fff;
  margin: 0 0 10px;
  text-align: center;
}
.grow h3 {
  font-weight: 700;
  font-size: 128px;
  color: #fff;
  margin: 0 0 10px;
  text-align: center;
}
.grow p {
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  margin: 0 0 10px;
  text-align: center;
}
.grow .grow-count-wrapper {
  max-width: 780px;
  margin: 50px auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
}
.grow-red-transparent {
  background-color: transparent;
  margin-bottom: -1px;
}
.grow-red-transparent > div {
  clip-path: polygon(0 0, 0 100%, 100% 100%);
  background: #70212F;
  height: 160px;
  margin-bottom: -1px;
}
.grow-transparent-red {
  border-top: 1px solid #70212F;
  background-color: transparent;
  position: relative;
}
.grow-transparent-red::before {
  content: " ";
  border-top: 1px solid #70212F;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}
.grow-transparent-red > div {
  clip-path: polygon(100% 100%, 100% 0, 0 0);
  background: #70212F;
  height: 160px;
  margin-bottom: -1px;
}

@media (max-width: 1023px) {
  .grow-red-transparent > div,
  .grow-transparent-red > div {
    height: 80px;
  }
}

@media (max-width: 767px) {
  .grow h2 {
    font-size: 32px;
  }
  .grow .grow-count-wrapper {
    display: block;
  }
  .grow-red-transparent > div,
  .grow-transparent-red > div {
    height: 40px;
  }
}