.banner-wrapper {
  background-color: #70212F;
  background: linear-gradient(180deg, #70212F 15.03%, #FF385C 102.01%);
  transform: matrix(-1, 0, 0, 1, 0, 0);
  margin-bottom: -1px;
  max-width: 100vw;
  overflow: hidden;
}
.banner {
  max-width: 1140px;
  margin: auto;
  padding: 142px 0 40px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  display: grid;
  grid-template-columns: 1.18fr 1.3fr;
}
.banner .banner-image .banner-image-inner {
  display: inline-block;
  margin: auto;
  position: relative;
}
.banner .banner-image .banner-image-inner img {
  max-width: 100%;
}
.banner .banner-image .banner-image-inner iframe {
  -ms-zoom: .75;
  background: #fff;
  border: none;
  border-radius: 3.2%;
  height: 112%;
  left: 44.1%;
  outline: none;
  position: absolute;
  top: 3.9%;
  -moz-transform: scale(.5);
  -o-transform: scale(.5);
  -webkit-transform: scale(.5);
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  width: 103.9%;
}
.banner-red-transparent {
  background-color: transparent;
  margin-bottom: 0;
  position: relative;
}
.banner-red-transparent::before {
  content: " ";
  background-color: #70212F;
  height: 1px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.banner-red-transparent > div {
  clip-path: polygon(0 0, 0 100%, 100% 100%);
  transform: matrix(-1, 0, 0, 1, 0, 0);
  background: #70212F;
  height: 160px;
  margin-bottom: -1px;
}
.banner .banner-text {
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.banner .banner-image {
  padding: 30px 15px;
  text-align: center;
}
.banner h1 {
  font-weight: 700;
  font-size: 48px;
  color: #fff;
  margin: 0 0 20px;
}
.banner h3 {
  font-weight: 700;
  font-size: 20px;
  color: #fff;
  margin: 0 0 15px;
}
.banner p {
  font-weight: 400;
  font-size: 20px;
  color: #fff;
  margin: 0 0 35px;
}
.red-wrapper {
  background-color: #70212F;
  padding: 42px 15px;
  margin: -2px 0;
  max-width: 100vw;
  overflow: hidden;
}
.red-wrapper hr {
  background: #872134;
  border: 1px solid #872134;
  max-width: 1036px;
  margin: auto;
}
.rates,
.we-are-title,
.we-are,
.how-it-works-title,
.how-it-works {
  max-width: 1036px;
  margin: auto;
}
.we-are img {
  margin: auto;
  display: block;
}
.red-wrapper h2 {
  font-weight: 600;
  font-size: 36px;
  color: #fff;
  margin: 0 0 20px;
  text-align: center;
}
.red-wrapper h3 {
  font-weight: 400;
  font-size: 20px;
  color: #fff;
  margin: 0 0 20px;
  text-align: center;
}
.red-wrapper p {
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  margin: 0 0 20px;
  text-align: center;
}
.red-wrapper h5 {
  font-weight: 400;
  font-size: 20px;
  color: #fff;
  margin: 20px 0;
  text-align: center;
}
.red-wrapper h4 {
  font-weight: 500;
  font-size: 24px;
  color: #8C2A3C;
  margin: 20px 0;
  text-align: center;
}
.red-wrapper label {
  font-weight: 500;
  font-size: 16px;
  color: #8C2A3C;
  margin: 0 0 20px;
  text-align: center;
}
.red-wrapper .qoute {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  min-height: 100px;
  color: #fff;
  margin: 0 0 30px;
  display: block;
}
.red-wrapper .qoutee {
  font-weight: 500;
  font-size: 15px;
  color: #fff;
  margin: 0 0 20px;
  display: block;
}
.how-it-works {
  padding: 40px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  max-width: 910px;
  margin: auto;
}
.how-it-works-single {
  background: #FFFFFF;
  border-radius: 20px;
  padding: 40px 20px;
  text-align: center;
  transition: transform .2s;
  display: block;
  position: relative;
}
.how-it-works-single:hover {
  transform: scale(1.1)
}
.how-it-works-single.coming-soon h4,
.how-it-works-single.coming-soon label {
  color: #B3B3B3!important;
}
.how-it-works-single.coming-soon h2 {
  font-weight: 700;
  font-size: 48px;
  color: #484848;
  text-align: center;
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.why-us {
  padding: 40px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
  max-width: 910px;
  margin: auto;
}
.why-us-single {
  border-radius: 20px;
  padding: 40px 20px;
  text-align: center;
}
.rates {
  padding: 20px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.rate {
  padding: 0 25px;
  border-right: 2px solid #5B1623;
}
.rate:last-child {
  border-right: none;
}

.connected-networks {
  position: relative;
  display: inline-block;
  max-width: 100%;
}
.connected-networks img,
.connected-networks svg {
  max-width: 100%;
  transition: all 0.3s linear;
}
.connected-networks img.hidden {
  opacity: 0;
}
.connected-networks .moving-lines {
  animation: dash 5s infinite;
}
@keyframes dash {
  to {
    stroke-dashoffset: 700;
  }
  from {
    stroke-dashoffset: 1000;
  }
}
.connected-networks .heartbeat-opacity {
  animation: heartbeatopacity 1.2s infinite;
}
@keyframes heartbeatopacity {
  0% {
    /* transform: rotate(45deg) scale(0.8); */
    opacity: 1;
  }
  5% {
    /* transform: rotate(45deg) scale(0.9); */
    opacity: .3;
  }
  10% {
    /* transform: rotate(45deg) scale(0.8); */
    opacity: .7;
  }
  15% {
    /* transform: rotate(45deg) scale(1); */
    opacity: .3;
  }
  50% {
    /* transform: rotate(45deg) scale(0.8); */
    opacity: 1;
  }
  100% {
    /* transform: rotate(45deg) scale(0.8); */
    opacity: 1;
  }
}
.connected-networks .cn-phone-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 40%;
}
.connected-networks .cn-phone-video {
  position: absolute;
  top: 3.4%;
  left: 3.5%;
  width: 32.3%;
  height: 96.2%;
  object-fit: cover;
  border-top-left-radius: 11%;
  border-top-right-radius: 6%;
  border-bottom-left-radius: 6%;
  border-bottom-right-radius: 8%;
}
.connected-networks .cn-r {
  transition: all 0.3s linear;
}
.connected-networks .cn-r1-p {
  position: absolute;
  right: 1.3%;
  top: 9.4%;
  width: 6.7%;
}
.connected-networks .cn-r2-p {
  position: absolute;
  right: 1.3%;
  top: 26.7%;
  width: 6.7%;
}
.connected-networks .cn-r3-p {
  position: absolute;
  right: 1.3%;
  top: 44.6%;
  width: 6.7%;
}
.connected-networks .cn-r4-p {
  position: absolute;
  right: 1.3%;
  top: 62.3%;
  width: 6.7%;
}
.connected-networks .cn-r5-p {
  position: absolute;
  right: 1.3%;
  top: 79.3%;
  width: 6.7%;
}
.connected-networks .cn-r1-l {
  position: absolute;
  right: 15.3%;
  top: 9.4%;
  width: 6.7%;
}
.connected-networks .cn-r2-l {
  position: absolute;
  right: 15.3%;
  top: 26.7%;
  width: 6.7%;
}
.connected-networks .cn-r3-l {
  position: absolute;
  right: 15.3%;
  top: 44.6%;
  width: 6.7%;
}
.connected-networks .cn-r4-l {
  position: absolute;
  right: 15.3%;
  top: 62.3%;
  width: 6.7%;
}
.connected-networks .cn-r5-l {
  position: absolute;
  right: 15.3%;
  top: 79.3%;
  width: 6.7%;
}

.banner-image .multi-award-image-wrapper img {
  max-width: 100%;
}
.banner-image .multi-award-image-wrapper .pop-up {
  margin-top: -7%;
  opacity: 0;
}
.banner-image .multi-award-image-wrapper:hover .pop-up {
  opacity: 1;
}
.banner-buttons {
  display: flex;
  gap: 10px;
}

@media (max-width: 1023px) {
  .why-us,
  .how-it-works,
  .banner {
    padding: 40px 0;
    display: block;
  }
  .how-it-works-single {
    margin: 20px 0;
  }
  .banner-red-transparent > div {
    height: 80px;
  }
  .rates {
    grid-template-columns: 1fr;
  }
  .rate {
    padding: 0 25px;
    border-right: none;
    margin-bottom: 30px;
    border-bottom: 2px solid #5B1623;
  }
  .rate:last-child {
    border-bottom: none;
  }
}

@media (max-width: 767px) {
  .banner-red-transparent > div {
    height: 40px;
  }
  .grow h1 {
    font-size: 32px;
  }
  .banner-buttons {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }
  .banner-buttons button {
    display: block;
    width: 100%;
  }
}