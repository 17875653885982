.section-ai-centre-wrapper {
  padding: 42px 15px;
  background-color: #ffffff;
  margin-bottom: -1px;
  max-width: 100vw;
  overflow: hidden;
}
.section-ai-centre-title,
.section-ai-centre-button {
  max-width: 1036px;
  margin: auto;
}
.section-ai-centre {
  max-width: 1036px;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 50px;
  margin: 50px auto 60px;
}
.section-ai-centre-wrapper h2 {
  font-weight: 600;
  font-size: 48px;
  letter-spacing: -1px;
  color: #393939;
  margin: 0;
}
.section-ai-centre-wrapper h3 {
  font-weight: 600;
  font-size: 24px;
  color: #393939;
  margin: 0 0 23px;
}
.section-ai-centre-wrapper p {
  font-weight: 500;
  font-size: 14px;
  color: #393939;
  margin: 0 0 23px;
}
.section-ai-centre-wrapper a {
  font-weight: 500;
  font-size: 14px;
  color: #EF4747;
  margin: 0 0 23px;
  text-transform: uppercase;
}
.section-ai-centre-single img {
  width: 100%;
  margin-bottom: 40px;
}
.section-ai-centre-single {
  margin-bottom: 50px;
}
.section-ai-centre-wrapper .section-ai-centre-button {
  text-align: center;
}
.section-ai-centre-wrapper .section-ai-centre-button a {
  background: #FF385C;
  color: #fff;
  border-radius: 30.5px;
  font-weight: 700;
  font-size: 16px;
  display: block;
  padding: 12px 20px;
  text-align: center;
  margin: 15px auto;
  width: 100%;
  max-width: 230px;
  text-transform: none;
}

@media (max-width: 1023px) {
  
}

@media (max-width: 767px) {
  .section-ai-centre {
    display: block;
  }
  .section-ai-centre h2 {
    font-size: 32px;
    margin: 0 0 20px;
  }
}