.banner-to-book-a-demo-wrapper {
  padding: 0 30px;
}
.banner-to-book-a-demo {
  max-width: 1298px;
  margin: auto;
}
.banner-to-book-a-demo-inner {
  display: grid;
  grid-template-columns: 7fr 9fr;
}

@media (max-width: 1023px) {
  .banner-to-book-a-demo-inner {
    display: block;
  }
  .banner-to-book-a-demo-inner .basic-banner-card-text {
    padding: 10px 0 30px;
  }
}

@media (max-width: 767px) {
}