.features-grid {
  background-color: #70212f;
  padding: 50px 20px;
  overflow: auto;
  
  .features-grid-inner {
    max-width: 1024px;
    margin: 20px auto;

    h2 {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 52px;
      line-height: 1.2;
      color: #FFFFFF;
      margin: 0 0 20px 0;
      text-align: center;
    }

    .features {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 30px;
      padding: 50px 0;

      .feature {
        min-height: 148px;
        padding: 50px;
        background: #FFFFFF;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        label {
          font-family: 'Montserrat', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 1.2;
          color: #FF385C;
          text-align: center;
        }
      }
    }

    @media (max-width: 1023px) {
      h2 {
        font-size: 32px;
      }

      .features {
        grid-template-columns: 1fr 1fr;
      }

      @media (max-width: 767px) {
        h2 {
          font-size: 28px;
        }

        .features {
          grid-template-columns: 1fr;
        }
      }
    }
  }
}