.multi-award-wrapper {
  padding: 42px 15px;
  background-color: #70212F;
  margin-bottom: -1px;
  max-width: 100vw;
  overflow: hidden;
}
.multi-award {
  max-width: 1036px;
  margin: auto;
}
.multi-award h2 {
  font-weight: 600;
  font-size: 64px;
  color: #fff;
  margin: 0 0 80px;
}
.multi-award .multi-award-image-wrapper img {
  max-width: 100%;
}
.multi-award .multi-award-image-wrapper .pop-up {
  margin-top: -7%;
  opacity: 0;
}
.multi-award .multi-award-image-wrapper:hover .pop-up {
  opacity: 1;
}

@media (max-width: 1023px) {
  
}

@media (max-width: 767px) {
  .multi-award h2 {
    font-size: 32px;
    margin: 0 0 20px;
  }
}