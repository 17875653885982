.banner-wrapper {
  background-color: #70212F;
  background: linear-gradient(180deg, #70212F 15.03%, #FF385C 102.01%);
  transform: matrix(-1, 0, 0, 1, 0, 0);
  margin-bottom: -1px;
  max-width: 100vw;
  overflow: hidden;
}
.banner {
  max-width: 1140px;
  margin: auto;
  padding: 142px 0 40px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  display: grid;
  grid-template-columns: 1fr 1.3fr;
}
.banner .banner-image .banner-image-inner {
  display: inline-block;
  margin: auto;
  position: relative;
}
.banner .banner-image .banner-image-inner img {
  max-width: 100%;
}
.banner .banner-image .banner-image-inner iframe {
  -ms-zoom: .75;
  background: #fff;
  border: none;
  border-radius: 3.2%;
  height: 112%;
  left: 44.1%;
  outline: none;
  position: absolute;
  top: 3.9%;
  -moz-transform: scale(.5);
  -o-transform: scale(.5);
  -webkit-transform: scale(.5);
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  width: 103.9%;
}
.banner-red-transparent {
  background-color: transparent;
  margin-bottom: 0;
  position: relative;
}
.banner-red-transparent::before {
  content: " ";
  background-color: #70212F;
  height: 1px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.banner-red-transparent > div {
  clip-path: polygon(0 0, 0 100%, 100% 100%);
  transform: matrix(-1, 0, 0, 1, 0, 0);
  background: #70212F;
  height: 160px;
  margin-bottom: -1px;
}
.banner .banner-text {
  padding: 0 15px;
}
.banner .banner-image {
  padding: 30px 15px;
  text-align: center;
}
.banner h1 {
  font-weight: 700;
  font-size: 48px;
  color: #fff;
  margin: 0 0 20px;
}
.banner h3 {
  font-weight: 700;
  font-size: 20px;
  color: #fff;
  margin: 0 0 15px;
}
.banner p {
  font-weight: 400;
  font-size: 20px;
  color: #fff;
  margin: 0 0 35px;
}
.red-wrapper {
  background-color: #70212F;
  padding: 42px 15px;
  margin: -2px 0;
  max-width: 100vw;
  overflow: hidden;
}
.red-wrapper hr {
  background: #872134;
  border: 1px solid #872134;
  max-width: 1036px;
  margin: auto;
}
.rates,
.we-are-title,
.we-are,
.how-it-works-title,
.how-it-works {
  max-width: 1036px;
  margin: auto;
}
.we-are .swiper-wrapper {
  align-items: center;
}
.we-are .img-wrap {
  padding: 20px;
}
.we-are img {
  margin: auto;
  display: block;
  max-width: 100%;
  max-height: 120px;
}
.red-wrapper h2 {
  font-weight: 600;
  font-size: 36px;
  color: #fff;
  margin: 0 0 20px;
  text-align: center;
}
.red-wrapper h3 {
  font-weight: 400;
  font-size: 20px;
  color: #fff;
  margin: 0 0 20px;
  text-align: center;
}
.red-wrapper p {
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  margin: 0 0 20px;
  text-align: center;
}
.red-wrapper h5 {
  font-weight: 400;
  font-size: 20px;
  color: #fff;
  margin: 20px 0;
  text-align: center;
}
.red-wrapper h4 {
  font-weight: 500;
  font-size: 24px;
  color: #8C2A3C;
  margin: 20px 0;
  text-align: center;
}
.red-wrapper label {
  font-weight: 500;
  font-size: 16px;
  color: #8C2A3C;
  margin: 0 0 20px;
  text-align: center;
}
.red-wrapper .qoute {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  min-height: 100px;
  color: #fff;
  margin: 0 0 30px;
  display: block;
}
.red-wrapper .qoutee {
  font-weight: 500;
  font-size: 15px;
  color: #fff;
  margin: 0 0 20px;
  display: block;
}
.how-it-works {
  padding: 40px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  max-width: 910px;
  margin: auto;
}
.how-it-works-single {
  background: #FFFFFF;
  border-radius: 20px;
  padding: 40px 20px;
  text-align: center;
  transition: transform .2s;
  display: block;
  position: relative;
}
.how-it-works-single:hover {
  transform: scale(1.1)
}
.how-it-works-single.coming-soon h4,
.how-it-works-single.coming-soon label {
  color: #B3B3B3!important;
}
.how-it-works-single.coming-soon h2 {
  font-weight: 700;
  font-size: 48px;
  color: #484848;
  text-align: center;
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.why-us {
  padding: 40px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
  max-width: 910px;
  margin: auto;
}
.why-us-single {
  border-radius: 20px;
  padding: 40px 20px;
  text-align: center;
}
.rates {
  padding: 20px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.rate {
  padding: 0 25px;
  border-right: 2px solid #5B1623;
}
.rate:last-child {
  border-right: none;
}

.communicate-3p-animation {
  position: relative;
  display: inline-block;
  max-width: 100%;
}
.communicate-3p-animation img,
.communicate-3p-animation svg {
  max-width: 100%;
  transition: all 0.3s linear;
}
.communicate-3p-animation img.hidden {
  opacity: 0;
}
.communicate-3p-animation .moving-lines {
  animation: dash 5s infinite;
}
@keyframes dash {
  to {
    stroke-dashoffset: 700;
  }
  from {
    stroke-dashoffset: 1000;
  }
}
.ai-hb .heartbeat-opacity,
.communicate-3p-animation .heartbeat-opacity {
  animation: heartbeatopacity 1.2s infinite;
}
@keyframes heartbeatopacity {
  0% {
    /* transform: rotate(45deg) scale(0.8); */
    opacity: 1;
  }
  5% {
    /* transform: rotate(45deg) scale(0.9); */
    opacity: .3;
  }
  10% {
    /* transform: rotate(45deg) scale(0.8); */
    opacity: .7;
  }
  15% {
    /* transform: rotate(45deg) scale(1); */
    opacity: .3;
  }
  50% {
    /* transform: rotate(45deg) scale(0.8); */
    opacity: 1;
  }
  100% {
    /* transform: rotate(45deg) scale(0.8); */
    opacity: 1;
  }
}
.ai-hb {
  position: relative;
  display: inline-block;
}
.ai-hb .heartbeat-opacity {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.communicate-3p-animation .c3p-phone-image {
  position: absolute;
  top: 18%;
  left: 0;
  width: 56%;
  z-index: -1;
}
.communicate-3p-animation .c3p-phone-video {
  position: absolute;
  top: 3.91%;
  left: 4.1%;
  width: 36.09%;
  height: 107.2%;
  object-fit: cover;
  border-top-left-radius: 8%;
  border-top-right-radius: 8%;
  border-bottom-left-radius: 6%;
  border-bottom-right-radius: 6%;
}
.communicate-3p-animation .c3p-r {
  transition: all 0.3s linear;
}
.communicate-3p-animation .c3p-r1-p {
  position: absolute;
  right: 1.3%;
  top: 9.4%;
  width: 6.7%;
}
.communicate-3p-animation .c3p-r2-p {
  position: absolute;
  right: 1.3%;
  top: 26.7%;
  width: 6.7%;
}
.communicate-3p-animation .c3p-r3-p {
  position: absolute;
  right: 1.3%;
  top: 44.6%;
  width: 6.7%;
}
.communicate-3p-animation .c3p-r4-p {
  position: absolute;
  right: 1.3%;
  top: 62.3%;
  width: 6.7%;
}
.communicate-3p-animation .c3p-r5-p {
  position: absolute;
  right: 1.3%;
  top: 79.3%;
  width: 6.7%;
}
.communicate-3p-animation .c3p-r1-l {
  position: absolute;
  right: 15.3%;
  top: 9.4%;
  width: 6.7%;
}
.communicate-3p-animation .c3p-r2-l {
  position: absolute;
  right: 15.3%;
  top: 26.7%;
  width: 6.7%;
}
.communicate-3p-animation .c3p-r3-l {
  position: absolute;
  right: 15.3%;
  top: 44.6%;
  width: 6.7%;
}
.communicate-3p-animation .c3p-r4-l {
  position: absolute;
  right: 15.3%;
  top: 62.3%;
  width: 6.7%;
}
.communicate-3p-animation .c3p-r5-l {
  position: absolute;
  right: 15.3%;
  top: 79.3%;
  width: 6.7%;
}

@media (max-width: 1023px) {
  .why-us,
  .how-it-works,
  .banner {
    padding: 40px 0;
    display: block;
  }
  .how-it-works-single {
    margin: 20px 0;
  }
  .banner-red-transparent > div {
    height: 80px;
  }
  .rates {
    grid-template-columns: 1fr;
  }
  .rate {
    padding: 0 25px;
    border-right: none;
    margin-bottom: 30px;
    border-bottom: 2px solid #5B1623;
  }
  .rate:last-child {
    border-bottom: none;
  }
}

@media (max-width: 767px) {
  .banner-red-transparent > div {
    height: 40px;
  }
  .grow h1 {
    font-size: 32px;
  }
}

/** anim **/
@keyframes pt-1-chat-fade-in {
  0% {
    transform: translateY(20px);
    opacity: 0
  }
  10% {
    transform: translateY(20px);
    opacity: 0
  }
  11% {
    transform: translateY(0);
    opacity: 1
  }
  99% {
    transform: translateY(0);
    opacity: 1
  }
  100% {
    transform: translateY(0);
    opacity: 0
  }
}
@keyframes pt-2-chat-fade-in {
  0% {
    transform: translateY(20px);
    opacity: 0
  }
  20% {
    transform: translateY(20px);
    opacity: 0
  }
  21% {
    transform: translateY(0);
    opacity: 1
  }
  99% {
    transform: translateY(0);
    opacity: 1
  }
  100% {
    transform: translateY(0);
    opacity: 0
  }
}
@keyframes pt-3-chat-fade-in {
  0% {
    transform: translateY(20px);
    opacity: 0
  }
  40% {
    transform: translateY(20px);
    opacity: 0
  }
  41% {
    transform: translateY(0);
    opacity: 1
  }
  99% {
    transform: translateY(0);
    opacity: 1
  }
  100% {
    transform: translateY(0);
    opacity: 0
  }
}
@keyframes pt-4-chat-fade-in {
  0% {
    transform: translateY(20px);
    opacity: 0
  }
  50% {
    transform: translateY(20px);
    opacity: 0
  }
  51% {
    transform: translateY(0);
    opacity: 1
  }
  99% {
    transform: translateY(0);
    opacity: 1
  }
  100% {
    transform: translateY(0);
    opacity: 0
  }
}
@keyframes pt-5-chat-fade-in {
  0% {
    transform: translateY(20px);
    opacity: 0
  }
  70% {
    transform: translateY(20px);
    opacity: 0
  }
  71% {
    transform: translateY(0);
    opacity: 1
  }
  99% {
    transform: translateY(0);
    opacity: 1
  }
  100% {
    transform: translateY(0);
    opacity: 0
  }
}
@keyframes pt-6-chat-fade-in {
  0% {
    transform: translateY(20px);
    opacity: 0
  }
  80% {
    transform: translateY(20px);
    opacity: 0
  }
  81% {
    transform: translateY(0);
    opacity: 1
  }
  99% {
    transform: translateY(0);
    opacity: 1
  }
  100% {
    transform: translateY(0);
    opacity: 0
  }
}
@keyframes pt-7-chat-fade-in {
  0% {
    transform: translateY(20px);
    opacity: 0
  }
  90% {
    transform: translateY(20px);
    opacity: 0
  }
  91% {
    transform: translateY(0);
    opacity: 1
  }
  99% {
    transform: translateY(0);
    opacity: 1
  }
  100% {
    transform: translateY(0);
    opacity: 0
  }
}
.pt-teams-animation-wrapper { text-align: right; }
.pt-teams-animation { display: inline-block; position: relative; }
.pt-teams-animation-image { max-width: 100%; }
.pt-teams-animation-image-mobile { display: none; max-width: 100%; }
.pt-teams-animation-desktop-chat-wrapper {
  position: absolute;
  left: 66%;
  right: 5%;
  bottom: 24%;
  top: 27%;
}
#pt-d-1-chat {
  max-width: 49%;
  margin-left: auto;
  margin-bottom: 1%;
  animation: pt-1-chat-fade-in 10s ease-in-out infinite;
}
#pt-d-2-chat {
  width: 100%;
  max-width: 54%;
  padding-right: 3%;
  margin-right: auto;
  margin-left: 0;
  margin-bottom: 1%;
  animation: pt-2-chat-fade-in 10s ease-in-out infinite;
}
#pt-d-3-chat {
  width: 100%;
  max-width: 54%;
  padding-right: 3%;
  margin-right: auto;
  margin-left: 0;
  margin-bottom: 1%;
  animation: pt-3-chat-fade-in 10s ease-in-out infinite;
}
#pt-d-4-chat {
  max-width: 49%;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 1.5%;
  animation: pt-4-chat-fade-in 10s ease-in-out infinite
}
#pt-d-5-chat {
  width: 100%;
  max-width: 54%;
  padding-right: 3%;
  margin-right: auto;
  margin-left: 0;
  margin-bottom: 1%;
  animation: pt-5-chat-fade-in 10s ease-in-out infinite;
}
#pt-d-6-chat {
  max-width: 30%;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 1.5%;
  animation: pt-6-chat-fade-in 10s ease-in-out infinite;
}
#pt-d-7-chat {
  max-width: 41%;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 2%;
  animation: pt-7-chat-fade-in 10s ease-in-out infinite
}
.pt-teams-animation-mobile-chat-wrapper {
  position: absolute;
  left: 3.5%;
  right: 74.5%;
  bottom: 15%;
  top: 13%;
}
#pt-p-1-chat {
  max-width: 87%;
  margin-left: auto;
  margin-bottom: 2%;
  animation: pt-1-chat-fade-in 10s ease-in-out infinite;
}
#pt-p-2-chat {
  max-width: 78%;
  margin-right: auto;
  margin-left: 9%;
  margin-bottom: 2%;
  animation: pt-2-chat-fade-in 10s ease-in-out infinite;
}
#pt-p-3-chat {
  max-width: 87%;
  margin-right: auto;
  margin-bottom: 2%;
  animation: pt-3-chat-fade-in 10s ease-in-out infinite;
}
#pt-p-4-chat {
  max-width: 87%;
  margin-left: auto;
  margin-bottom: 2%;
  animation: pt-4-chat-fade-in 10s ease-in-out infinite;
}
#pt-p-5-chat {
  max-width: 87%;
  margin-right: auto;
  margin-bottom: 2%;
  animation: pt-5-chat-fade-in 10s ease-in-out infinite;
}
#pt-p-6-chat {
  max-width: 30%;
  margin-left: auto;
  margin-bottom: 2%;
  animation: pt-6-chat-fade-in 10s ease-in-out infinite;
}
#pt-p-7-chat {
  max-width: 54%;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 2%;
  animation: pt-7-chat-fade-in 10s ease-in-out infinite;
}
.communicate-3p,
.assistant-3p {
  display: flex;
  padding: 20px;
  align-items: center;
}
.communicate-3p .communicate-3p-text,
.communicate-3p .communicate-3p-animation, 
.assistant-3p .assistant-3p-text,
.assistant-3p .assistant-3p-animation {
  flex: 1 1 auto;
  width: 35%;
}
.communicate-3p .communicate-3p-animation,
.assistant-3p .assistant-3p-animation {
  width: 65%;
}
.communicate-3p .communicate-3p-text h3,
.assistant-3p .assistant-3p-text h3 {
  font-weight: 700;
  font-size: 36px;
  color: #fff;
  margin: 0 0 25px;
}
.communicate-3p .communicate-3p-text h3 span,
.assistant-3p .assistant-3p-text h3 span {
  color: #FF385C;
  font-weight: 700;
  font-size: 50px;
}
.communicate-3p .communicate-3p-text p,
.assistant-3p .assistant-3p-text p {
  font-weight: 500;
  font-size: 23px;
  color: #fff;
  margin: 0 0 15px;
  line-height: 1.56;
}
.communicate-3p .communicate-3p-text a,
.assistant-3p .assistant-3p-text a {
  display: block;
  margin: auto;
  max-width: 100%;
  width: max-content;
}

.products-pow-ai-wrapper {
  max-width: 1300px;
  margin: auto;
}

.products-pow-ai {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
}

.products-pow-ai .product-pow-ai {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@keyframes moving-line {
  0% {
    background-position: 0 0, 20px 100%, 0 20px, 100% 0;
  }
  100% {
    background-position: 20px 0, 0 100%, 0 0, 100% 20px;
  }
}

.the-moving-line {
  background-image: linear-gradient(90deg, #FF6884 50%, transparent 50%), linear-gradient(90deg, #FF6884 50%, transparent 50%), linear-gradient(0, #FF6884 50%, transparent 50%), linear-gradient(0, #FF6884 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 20px 4px, 20px 4px, 4px 20px, 4px 20px;
  animation: moving-line 300ms infinite linear;
}

.products-pow-ai-animation {
  position: absolute;
  inset: 0;
  z-index: -1;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
}
.products-pow-ai-animation .ppa-1 {
  position: absolute;
  left: 1%;
  top: 50%;
  width: 64%;
  height: 50%;
  background-image: linear-gradient(90deg, #1A8C33 50%, transparent 50%), linear-gradient(90deg, #1A8C33 50%, transparent 50%), linear-gradient(0, #1A8C33 50%, transparent 50%), linear-gradient(0, #1A8C33 50%, transparent 50%);
}
.products-pow-ai-animation .ppa-1-cover {
  position: absolute;
  left: 0;
  top: 49%;
  width: 64%;
  height: calc(51% - 4px);
  background: #70212F;
}
.products-pow-ai-animation .ppa-2 {
  position: absolute;
  left: -4px;
  top: 0;
  width: 1%;
  min-width: 8px;
  height: 50%;
  background-image: linear-gradient(90deg, #E211C1 50%, transparent 50%), linear-gradient(90deg, #E211C1 50%, transparent 50%), linear-gradient(0, #E211C1 50%, transparent 50%), linear-gradient(0, #E211C1 50%, transparent 50%);
}
.products-pow-ai-animation .ppa-2-cover {
  position: absolute;
  left: -4px;
  top: 0;
  width: calc(1% - 4px);
  min-width: 4px;
  height: 50%;
  background: #70212F;
}
.products-pow-ai-animation .ppa-3 {
  position: absolute;
  left: 1%;
  top: 50%;
  width: 64%;
  height: 50%;
  background-image: linear-gradient(90deg, #E211C1 50%, transparent 50%), linear-gradient(90deg, #E211C1 50%, transparent 50%), linear-gradient(0, #E211C1 50%, transparent 50%), linear-gradient(0, #E211C1 50%, transparent 50%);
}
.products-pow-ai-animation .ppa-3-cover {
  position: absolute;
  left: 0;
  top: calc(50% + 4px);
  width: 64%;
  height: calc(50% - 8px);
  background: #70212F;
}
.products-pow-ai-animation .ppa-3-cover-mobile {
  display: none;
}
.products-pow-ai-animation .ppa-4 {
  position: absolute;
  left: -4px;
  top: 0;
  width: 1%;
  min-width: 8px;
  height: 50%;
  background-image: linear-gradient(90deg, #FF385C 50%, transparent 50%), linear-gradient(90deg, #FF385C 50%, transparent 50%), linear-gradient(0, #FF385C 50%, transparent 50%), linear-gradient(0, #FF385C 50%, transparent 50%);
}
.products-pow-ai-animation .ppa-4-cover {
  position: absolute;
  left: -4px;
  top: 0;
  width: calc(1% - 4px);
  min-width: 4px;
  height: 50%;
  background: #70212F;
}
.products-pow-ai-animation .ppa-5 {
  position: absolute;
  left: 1%;
  top: 50%;
  width: 50%;
  height: calc(1% + 4px);
  background-image: linear-gradient(90deg, #FF385C 50%, transparent 50%), linear-gradient(90deg, #FF385C 50%, transparent 50%), linear-gradient(0, #FF385C 50%, transparent 50%), linear-gradient(0, #FF385C 50%, transparent 50%);
}
.products-pow-ai-animation .ppa-5-cover {
  position: absolute;
  left: 1%;
  top: calc(50% + 4px);
  width: 50%;
  height: calc(1% + 4px);
  background: #70212F;
}

@media (max-width: 1023px) {
  .tools-images .tools-images-inner,
  .communicate-3p,
  .assistant-3p {
    flex-flow: column;
  }
  .communicate-3p .communicate-3p-text,
  .communicate-3p .communicate-3p-animation,
  .assistant-3p .assistant-3p-text,
  .assistant-3p .assistant-3p-animation {
    width: auto;
    order: 1;
    padding: 0;
  }
  .communicate-3p .communicate-3p-text,
  .assistant-3p .assistant-3p-text {
    order: 2;
  }

  .products-pow-ai-animation .ppa-1,
  .products-pow-ai-animation .ppa-3 {
    width: 99%;
  }
  .products-pow-ai-animation .ppa-1-cover,
  .products-pow-ai-animation .ppa-3-cover {
    width: 98%;
  }
  .products-pow-ai-animation .ppa-5 {
    width: calc(3% + 4px);
  }
  .products-pow-ai-animation .ppa-5-cover {
    width: calc(3% + 4px);
  }
  .products-pow-ai-animation .ppa-3-cover-mobile {
    position: absolute;
    left: calc(3% + 4px);
    top: calc(50% - 12px);;
    width: calc(94% - 8px);
    height: calc(50% - 8px);
    background: #70212F;
    display: block;
  }
}

@media (max-width: 767px) {
  .pt-teams-animation-image-mobile {
    display: block;
  }
  .pt-teams-animation-image {
     display: none;
  }
  .communicate-3p .communicate-3p-text h3 span,
  .assistant-3p .assistant-3p-text h3 span {
    font-size: 40px;
  }
}
