.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}


.App-header {
  color: white;
  background-image: url('https://d365pq86x330zn.cloudfront.net/0514b670-6117-45b7-6471-01557881g66b.png');
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #ea3770;
}

.App-link {
  color: #ea3770;
} 