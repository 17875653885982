.game-changing-wrapper {
  padding: 0;    
  background-color: #fff;
  background-image: url(https://d365pq86x330zn.cloudfront.net/216i7661-6669-4116-6701-15551Bu6ii97.png);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: -1px;
  max-width: 100vw;
  overflow: hidden;
}
.game-changing {
  padding: 10px 15px;   
  max-width: 1036px;
  margin: auto;
}
.game-changing h2 {
  font-weight: 700;
  font-size: 96px;
  color: #393939;
  margin: 0;
}
.game-changing img {
  max-width: 80%;
  margin-left: auto;
  display: block;
}
.gamec-transparent-red {
  border-top: 1px solid #70212F;
  background-color: transparent;
  position: relative;
}
.gamec-transparent-red::before {
  content: " ";
  border-top: 1px solid #70212F;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}
.gamec-transparent-red > div {
  clip-path: polygon(100% 100%, 100% 0, 0 0);
  background: #70212F;
  height: 160px;
  margin-bottom: -1px;
}

.game-changing a {
  background: #FF385C;
  border-radius: 30.5px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #FFFFFF;
  padding: 12px 50px;
  display: block;
  width: max-content;
  margin: 50px 0 0;
}

@media (max-width: 1023px) {
  .gamec-red-transparent > div,
  .gamec-transparent-red > div {
    height: 80px;
  }
}

@media (max-width: 767px) {
  .game-changing h2 {
    font-size: 32px;
    margin: 0 0 20px;
  }
  .gamec-red-transparent > div,
  .gamec-transparent-red > div {
    height: 40px;
  }
}