.lbanner h2.plugins-buttons-title {
  font-weight: 700;
  font-size: 64px;
}

.plugins-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 30px;
  max-width: 1024px;
  margin: auto;
}
.lbanner .plugins-buttons .btn.btn-success {
  display: block;
  width: 100%;
  text-align: center;
  padding: 20px;
  font-weight: 500;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1023px) {
  .lbanner h2.plugins-buttons-title {
    font-size: 44px;
  }
}
@media (max-width: 767px) {
  .lbanner h2.plugins-buttons-title {
    font-size: 28px;
  }
  .plugins-buttons {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
}
