.parker-in-action-wrapper {
  padding: 42px 15px;
  background-color: #70212F;
  margin-bottom: -1px;
}
.parker-in-action {
  max-width: 1036px;
  margin: auto;
}
.parker-in-action h2 {
  font-weight: 600;
  font-size: 36px;
  color: #fff;
  margin: 0 0 30px;
  text-align: center;
}
.parker-in-action p {
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  margin: 0 auto 30px;
  text-align: center;
  max-width: 500px;
}
.parker-in-action .iframe-container {
  overflow: hidden;
  /* 16:9 aspect ratio */
  padding-top: 56.25%;
  position: relative;
  width: 100%;
  margin: 50px 0 60px;
}
.parker-in-action .iframe-container iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

@media (max-width: 1023px) {
  
}

@media (max-width: 767px) {
  .parker-in-action h2 {
    font-size: 32px;
    margin: 0 0 20px;
  }
}

/** animation **/
.parker-in-action-animation-proxy {
  display: none;
}
.parker-in-action-animation {
  max-width: 600px;
  margin: auto;
}
.parker-in-action-phone-animation-wrapper {
  text-align: right;
  position: relative;
}

.parker-in-action-notif-animation {
  position: absolute;
  top: 28px;
  left: 10px;
}

@media (max-width: 600px) {
  .parker-in-action-notif-animation {
    display: none;
  }
}

.parker-in-action-notif-wrap {
  position: relative;
  background-color: #F0F0F0;
  color: #565A5C;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 11px;
  margin: 30px 20px;
  padding: 10px;
  text-align: left;
  width: 210px;
  box-sizing: border-box;
}

.parker-in-action-notif-image {
  width: 32px;
  height: auto;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
  position: absolute;
  right: -10px;
  top: -10px;
}

.parker-in-action-notif-head {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.parker-in-action-notif-head-image {
  width: 35px;
  height: auto;
}

.parker-in-action-notif-head-name {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  padding-left: 16px;
}

.parker-in-action-notif-head-label {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.parker-in-action-notif-head-label .online {
  color: #31a24c;
  font-size: 30px;
  line-height: 12px;
}

.parker-in-action-notif-foot {
  display: flex;
  position: relative;
}

.parker-in-action-notif-foot-line {
  height: 12px;
  border-radius: 9px;
  background-color: #fff;
  flex: 1;
  margin: 0 3px;
}
/* whatsapp animation start */

@keyframes notif-animate-wa {
  0% {
    background-color: #F0F0F0;
    color: #565A5C;
    transform: translateX(0px);
  }
  1% {
    background-color: #FF385C;
    color: #FFFFFF;
    transform: translateX(32px);
  }
  24% {
    background-color: #FF385C;
    color: #FFFFFF;
    transform: translateX(32px);
  }
  25% {
    background-color: #F0F0F0;
    color: #565A5C;
    transform: translateX(0px);
  }
  26% {
    background-color: #F0F0F0;
    color: #565A5C;
    transform: translateX(0px);
  }
  50% {
    background-color: #F0F0F0;
    color: #565A5C;
    transform: translateX(0px);
  }
  51% {
    background-color: #F0F0F0;
    color: #565A5C;
    transform: translateX(0px);
  }
  75% {
    background-color: #F0F0F0;
    color: #565A5C;
    transform: translateX(0px);
  }
  76% {
    background-color: #F0F0F0;
    color: #565A5C;
    transform: translateX(0px);
  }
  100% {
    background-color: #F0F0F0;
    color: #565A5C;
    transform: translateX(0px);
  }
}

#parker-in-action-notif-wa {
  animation: notif-animate-wa 40s ease-in-out infinite
}

@keyframes notif-animate-wa-online {
  0% {
    opacity: 1;
  }
  1% {
    opacity: 0;
  }
  24% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  26% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  76% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

#parker-in-action-notif-wa .parker-in-action-notif-head-label {
  animation: notif-animate-wa-online 40s ease-in-out infinite
}

@keyframes notif-animate-wa-bar {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  26% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  76% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

#parker-in-action-notif-wa .parker-in-action-notif-foot-line {
  animation: notif-animate-wa-bar 40s ease-in-out infinite
}
/* whatsapp animation end */
/* instagram animation start */

@keyframes notif-animate-ig {
  0% {
    background-color: #F0F0F0;
    color: #565A5C;
    transform: translateX(0px);
  }
  25% {
    background-color: #F0F0F0;
    color: #565A5C;
    transform: translateX(0px);
  }
  26% {
    background-color: #F0F0F0;
    color: #565A5C;
    transform: translateX(0px);
  }
  27% {
    background-color: #FF385C;
    color: #FFFFFF;
    transform: translateX(32px);
  }
  49% {
    background-color: #FF385C;
    color: #FFFFFF;
    transform: translateX(32px);
  }
  50% {
    background-color: #F0F0F0;
    color: #565A5C;
    transform: translateX(0px);
  }
  51% {
    background-color: #F0F0F0;
    color: #565A5C;
    transform: translateX(0px);
  }
  75% {
    background-color: #F0F0F0;
    color: #565A5C;
    transform: translateX(0px);
  }
  76% {
    background-color: #F0F0F0;
    color: #565A5C;
    transform: translateX(0px);
  }
  100% {
    background-color: #F0F0F0;
    color: #565A5C;
    transform: translateX(0px);
  }
}

#parker-in-action-notif-ig {
  animation: notif-animate-ig 40s ease-in-out infinite
}

@keyframes notif-animate-ig-online {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  26% {
    opacity: 1;
  }
  27% {
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  76% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

#parker-in-action-notif-ig .parker-in-action-notif-head-label {
  animation: notif-animate-ig-online 40s ease-in-out infinite
}

@keyframes notif-animate-ig-bar {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  26% {
    opacity: 0;
  }
  27% {
    opacity: 1;
  }
  49% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  76% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

#parker-in-action-notif-ig .parker-in-action-notif-foot-line {
  animation: notif-animate-ig-bar 40s ease-in-out infinite
}
/* instagram animation end */
/* tiktok animation start */

@keyframes notif-animate-tk {
  0% {
    background-color: #F0F0F0;
    color: #565A5C;
    transform: translateX(0px);
  }
  25% {
    background-color: #F0F0F0;
    color: #565A5C;
    transform: translateX(0px);
  }
  26% {
    background-color: #F0F0F0;
    color: #565A5C;
    transform: translateX(0px);
  }
  50% {
    background-color: #F0F0F0;
    color: #565A5C;
    transform: translateX(0px);
  }
  51% {
    background-color: #F0F0F0;
    color: #565A5C;
    transform: translateX(0px);
  }
  52% {
    background-color: #FF385C;
    color: #FFFFFF;
    transform: translateX(32px);
  }
  74% {
    background-color: #FF385C;
    color: #FFFFFF;
    transform: translateX(32px);
  }
  75% {
    background-color: #F0F0F0;
    color: #565A5C;
    transform: translateX(0px);
  }
  76% {
    background-color: #F0F0F0;
    color: #565A5C;
    transform: translateX(0px);
  }
  100% {
    background-color: #F0F0F0;
    color: #565A5C;
    transform: translateX(0px);
  }
}

#parker-in-action-notif-tk {
  animation: notif-animate-tk 40s ease-in-out infinite
}

@keyframes notif-animate-tk-online {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  26% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 1;
  }
  52% {
    opacity: 0;
  }
  74% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  76% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

#parker-in-action-notif-tk .parker-in-action-notif-head-label {
  animation: notif-animate-tk-online 40s ease-in-out infinite
}

@keyframes notif-animate-tk-bar {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  26% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  51% {
    opacity: 0;
  }
  52% {
    opacity: 1;
  }
  74% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  76% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

#parker-in-action-notif-tk .parker-in-action-notif-foot-line {
  animation: notif-animate-tk-bar 40s ease-in-out infinite
}
/* tiktok animation start */
/* messenger animation start */

@keyframes notif-animate-mg {
  0% {
    background-color: #F0F0F0;
    color: #565A5C;
    transform: translateX(0px);
  }
  25% {
    background-color: #F0F0F0;
    color: #565A5C;
    transform: translateX(0px);
  }
  26% {
    background-color: #F0F0F0;
    color: #565A5C;
    transform: translateX(0px);
  }
  50% {
    background-color: #F0F0F0;
    color: #565A5C;
    transform: translateX(0px);
  }
  51% {
    background-color: #F0F0F0;
    color: #565A5C;
    transform: translateX(0px);
  }
  75% {
    background-color: #F0F0F0;
    color: #565A5C;
    transform: translateX(0px);
  }
  76% {
    background-color: #F0F0F0;
    color: #565A5C;
    transform: translateX(0px);
  }
  77% {
    background-color: #FF385C;
    color: #FFFFFF;
    transform: translateX(32px);
  }
  99% {
    background-color: #FF385C;
    color: #FFFFFF;
    transform: translateX(32px);
  }
  100% {
    background-color: #F0F0F0;
    color: #565A5C;
    transform: translateX(0px);
  }
}

#parker-in-action-notif-mg {
  animation: notif-animate-mg 40s ease-in-out infinite
}

@keyframes notif-animate-mg-online {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  26% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  76% {
    opacity: 1;
  }
  77% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#parker-in-action-notif-mg .parker-in-action-notif-head-label {
  animation: notif-animate-mg-online 40s ease-in-out infinite
}

@keyframes notif-animate-mg-bar {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  26% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  51% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  76% {
    opacity: 0;
  }
  77% {
    opacity: 1;
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

#parker-in-action-notif-mg .parker-in-action-notif-foot-line {
  animation: notif-animate-mg-bar 40s ease-in-out infinite
}
/* messenger animation start */

.message-wrapper {
  display: inline-block;
  max-width: 70%;
  margin: 5px;
  padding: 6px 10px;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 20px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #565a5c;
  text-align: left;
}

.message-image {
  padding-top: 50%;
  margin-top: 5px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.parker-message-wrapper {
  position: relative;
  text-align: left;
  padding-left: 22px;
}

.parker-message-wrapper::before {
  content: " ";
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 7px;
  left: 0;
  background-image: url('https://d365pq86x330zn.cloudfront.net/11260818-1488-404C-86C5-O13I11101118.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.client-message-wrapper {
  text-align: right;
}

.client-message-wrapper .message-wrapper {
  background-color: #f3f3f3
}

.parker-in-action-phone-animation {
  position: relative;
  display: inline-block;
}
/* whatsapp animation start */

@keyframes phone-bg-animate-wa {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  26% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  51% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  76% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

#wa-phone-bg {
  animation: phone-bg-animate-wa 40s ease-in-out infinite;
  height: 695px;
  position: absolute;
  top: 0;
  right: 0;
}

#wa-phone-anim-space {
  position: absolute;
  top: 123px;
  left: 148px;
  bottom: 86px;
  right: 42px;
}

#wa-phone-anim-space .client-message-wrapper .message-wrapper {
  background-color: #E2FFC7;
}
/* add 5 per msg */

@keyframes wa-phone-anim-space-msg-fade-in-1 {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  1% {
    transform: translateY(0);
    opacity: 1;
  }
  24% {
    transform: translateY(0);
    opacity: 1;
  }
  25% {
    transform: translateY(-20px);
    opacity: 0;
  }
  26% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  50% {
    transform: translateY(-20px);
    opacity: 0;
  }
  51% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  75% {
    transform: translateY(-20px);
    opacity: 0;
  }
  76% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  100% {
    transform: translateY(-20px);
    opacity: 0;
  }
}

@keyframes wa-phone-anim-space-msg-fade-in-2 {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  5% {
    transform: translateY(-20px);
    opacity: 0;
  }
  6% {
    transform: translateY(0);
    opacity: 1;
  }
  24% {
    transform: translateY(0);
    opacity: 1;
  }
  25% {
    transform: translateY(-20px);
    opacity: 0;
  }
  26% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  50% {
    transform: translateY(-20px);
    opacity: 0;
  }
  51% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  75% {
    transform: translateY(-20px);
    opacity: 0;
  }
  76% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  100% {
    transform: translateY(-20px);
    opacity: 0;
  }
}

@keyframes wa-phone-anim-space-msg-fade-in-3 {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  10% {
    transform: translateY(-20px);
    opacity: 0;
  }
  11% {
    transform: translateY(0);
    opacity: 1;
  }
  24% {
    transform: translateY(0);
    opacity: 1;
  }
  25% {
    transform: translateY(-20px);
    opacity: 0;
  }
  26% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  50% {
    transform: translateY(-20px);
    opacity: 0;
  }
  51% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  75% {
    transform: translateY(-20px);
    opacity: 0;
  }
  76% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  100% {
    transform: translateY(-20px);
    opacity: 0;
  }
}

@keyframes wa-phone-anim-space-msg-fade-in-4 {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  15% {
    transform: translateY(-20px);
    opacity: 0;
  }
  16% {
    transform: translateY(0);
    opacity: 1;
  }
  24% {
    transform: translateY(0);
    opacity: 1;
  }
  25% {
    transform: translateY(-20px);
    opacity: 0;
  }
  26% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  50% {
    transform: translateY(-20px);
    opacity: 0;
  }
  51% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  75% {
    transform: translateY(-20px);
    opacity: 0;
  }
  76% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  100% {
    transform: translateY(-20px);
    opacity: 0;
  }
}

#wa-phone-anim-space>div:nth-child(1) {
  animation: wa-phone-anim-space-msg-fade-in-1 40s ease-in-out infinite;
}

#wa-phone-anim-space>div:nth-child(2) {
  animation: wa-phone-anim-space-msg-fade-in-2 40s ease-in-out infinite;
}

#wa-phone-anim-space>div:nth-child(3) {
  animation: wa-phone-anim-space-msg-fade-in-3 40s ease-in-out infinite;
}

#wa-phone-anim-space>div:nth-child(4) {
  animation: wa-phone-anim-space-msg-fade-in-4 40s ease-in-out infinite;
}
/* instagram animation end */
/* instagram animation start */

@keyframes phone-bg-animate-ig {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  26% {
    opacity: 0;
  }
  27% {
    opacity: 1;
  }
  49% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  51% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  76% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

#ig-phone-bg {
  animation: phone-bg-animate-ig 40s ease-in-out infinite;
  height: 695px;
}

#ig-phone-anim-space {
  position: absolute;
  top: 282px;
  left: 148px;
  bottom: 86px;
  right: 42px;
}
/* add 5 per msg */

@keyframes ig-phone-anim-space-msg-fade-in-1 {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  25% {
    transform: translateY(-20px);
    opacity: 0;
  }
  26% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  27% {
    transform: translateY(0);
    opacity: 1;
  }
  49% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(-20px);
    opacity: 0;
  }
  51% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  75% {
    transform: translateY(-20px);
    opacity: 0;
  }
  76% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  100% {
    transform: translateY(-20px);
    opacity: 0;
  }
}

@keyframes ig-phone-anim-space-msg-fade-in-2 {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  25% {
    transform: translateY(-20px);
    opacity: 0;
  }
  26% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  31% {
    transform: translateY(-20px);
    opacity: 0;
  }
  32% {
    transform: translateY(0);
    opacity: 1;
  }
  49% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(-20px);
    opacity: 0;
  }
  51% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  75% {
    transform: translateY(-20px);
    opacity: 0;
  }
  76% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  100% {
    transform: translateY(-20px);
    opacity: 0;
  }
}

@keyframes ig-phone-anim-space-msg-fade-in-3 {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  25% {
    transform: translateY(-20px);
    opacity: 0;
  }
  26% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  36% {
    transform: translateY(-20px);
    opacity: 0;
  }
  37% {
    transform: translateY(0);
    opacity: 1;
  }
  49% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(-20px);
    opacity: 0;
  }
  51% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  75% {
    transform: translateY(-20px);
    opacity: 0;
  }
  76% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  100% {
    transform: translateY(-20px);
    opacity: 0;
  }
}

@keyframes ig-phone-anim-space-msg-fade-in-4 {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  25% {
    transform: translateY(-20px);
    opacity: 0;
  }
  26% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  41% {
    transform: translateY(-20px);
    opacity: 0;
  }
  42% {
    transform: translateY(0);
    opacity: 1;
  }
  49% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(-20px);
    opacity: 0;
  }
  51% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  75% {
    transform: translateY(-20px);
    opacity: 0;
  }
  76% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  100% {
    transform: translateY(-20px);
    opacity: 0;
  }
}

#ig-phone-anim-space>div:nth-child(1) {
  animation: ig-phone-anim-space-msg-fade-in-1 40s ease-in-out infinite;
}

#ig-phone-anim-space>div:nth-child(2) {
  animation: ig-phone-anim-space-msg-fade-in-2 40s ease-in-out infinite;
}

#ig-phone-anim-space>div:nth-child(3) {
  animation: ig-phone-anim-space-msg-fade-in-3 40s ease-in-out infinite;
}

#ig-phone-anim-space>div:nth-child(4) {
  animation: ig-phone-anim-space-msg-fade-in-4 40s ease-in-out infinite;
}
/* instagram animation end */
/* tiktok animation start */

@keyframes phone-bg-animate-tk {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  26% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  51% {
    opacity: 0;
  }
  52% {
    opacity: 1;
  }
  74% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  76% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

#tk-phone-bg {
  animation: phone-bg-animate-tk 40s ease-in-out infinite;
  height: 695px;
  position: absolute;
  top: 0;
  right: 0;
}

#tk-phone-video {
  animation: phone-bg-animate-tk 40s ease-in-out infinite;
}

#tk-phone-anim-space {
  position: absolute;
  top: 123px;
  left: 148px;
  bottom: 86px;
  right: 42px;
}

#tk-phone-anim-space-2 {
  position: absolute;
  top: 123px;
  left: 148px;
  bottom: 86px;
  right: 42px;
}
/* add 5 per msg */

@keyframes tk-phone-anim-space-msg-fade-in-1 {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  25% {
    transform: translateY(-20px);
    opacity: 0;
  }
  26% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  50% {
    transform: translateY(-20px);
    opacity: 0;
  }
  51% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  52% {
    transform: translateY(0);
    opacity: 1;
  }
  74% {
    transform: translateY(0);
    opacity: 1;
  }
  75% {
    transform: translateY(-20px);
    opacity: 0;
  }
  76% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  100% {
    transform: translateY(-20px);
    opacity: 0;
  }
}

@keyframes tk-phone-anim-space-msg-fade-in-2 {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  25% {
    transform: translateY(-20px);
    opacity: 0;
  }
  26% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  50% {
    transform: translateY(-20px);
    opacity: 0;
  }
  51% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  57% {
    transform: translateY(-20px);
    opacity: 0;
  }
  58% {
    transform: translateY(0);
    opacity: 1;
  }
  74% {
    transform: translateY(0);
    opacity: 1;
  }
  75% {
    transform: translateY(-20px);
    opacity: 0;
  }
  76% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  100% {
    transform: translateY(-20px);
    opacity: 0;
  }
}

@keyframes tk-phone-anim-space-msg-fade-in-3 {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  25% {
    transform: translateY(-20px);
    opacity: 0;
  }
  26% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  50% {
    transform: translateY(-20px);
    opacity: 0;
  }
  51% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  62% {
    transform: translateY(-20px);
    opacity: 0;
  }
  63% {
    transform: translateY(0);
    opacity: 1;
  }
  74% {
    transform: translateY(0);
    opacity: 1;
  }
  75% {
    transform: translateY(-20px);
    opacity: 0;
  }
  76% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  100% {
    transform: translateY(-20px);
    opacity: 0;
  }
}

@keyframes tk-phone-anim-space-msg-fade-in-4 {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  25% {
    transform: translateY(-20px);
    opacity: 0;
  }
  26% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  50% {
    transform: translateY(-20px);
    opacity: 0;
  }
  51% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  67% {
    transform: translateY(-20px);
    opacity: 0;
  }
  68% {
    transform: translateY(0);
    opacity: 1;
  }
  74% {
    transform: translateY(0);
    opacity: 1;
  }
  75% {
    transform: translateY(-20px);
    opacity: 0;
  }
  76% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  100% {
    transform: translateY(-20px);
    opacity: 0;
  }
}

#tk-phone-anim-space>div:nth-child(1) {
  animation: tk-phone-anim-space-msg-fade-in-1 40s ease-in-out infinite;
}

#tk-phone-anim-space>div:nth-child(2) {
  animation: tk-phone-anim-space-msg-fade-in-2 40s ease-in-out infinite;
}

#tk-phone-anim-space>div:nth-child(3) {
  animation: tk-phone-anim-space-msg-fade-in-3 40s ease-in-out infinite;
}

#tk-phone-anim-space>div:nth-child(4) {
  animation: tk-phone-anim-space-msg-fade-in-4 40s ease-in-out infinite;
}
/* tiktok animation end */
/* messenger animation start */

@keyframes phone-bg-animate-mg {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  26% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  51% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  76% {
    opacity: 0;
  }
  77% {
    opacity: 1;
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

#mg-phone-bg {
  animation: phone-bg-animate-mg 40s ease-in-out infinite;
  height: 695px;
  position: absolute;
  top: 0;
  right: 0;
}

#mg-phone-anim-space {
  position: absolute;
  top: 282px;
  left: 148px;
  bottom: 86px;
  right: 42px;
}
/* add 5 per msg */

@keyframes mg-phone-anim-space-msg-fade-in-1 {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  25% {
    transform: translateY(-20px);
    opacity: 0;
  }
  26% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  50% {
    transform: translateY(-20px);
    opacity: 0;
  }
  51% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  75% {
    transform: translateY(-20px);
    opacity: 0;
  }
  76% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  77% {
    transform: translateY(0);
    opacity: 1;
  }
  99% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-20px);
    opacity: 0;
  }
}

@keyframes mg-phone-anim-space-msg-fade-in-2 {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  25% {
    transform: translateY(-20px);
    opacity: 0;
  }
  26% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  50% {
    transform: translateY(-20px);
    opacity: 0;
  }
  51% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  75% {
    transform: translateY(-20px);
    opacity: 0;
  }
  76% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  81% {
    transform: translateY(-20px);
    opacity: 0;
  }
  82% {
    transform: translateY(0);
    opacity: 1;
  }
  99% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-20px);
    opacity: 0;
  }
}

@keyframes mg-phone-anim-space-msg-fade-in-3 {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  25% {
    transform: translateY(-20px);
    opacity: 0;
  }
  26% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  50% {
    transform: translateY(-20px);
    opacity: 0;
  }
  51% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  75% {
    transform: translateY(-20px);
    opacity: 0;
  }
  76% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  86% {
    transform: translateY(-20px);
    opacity: 0;
  }
  87% {
    transform: translateY(0);
    opacity: 1;
  }
  99% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-20px);
    opacity: 0;
  }
}

@keyframes mg-phone-anim-space-msg-fade-in-4 {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  25% {
    transform: translateY(-20px);
    opacity: 0;
  }
  26% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  50% {
    transform: translateY(-20px);
    opacity: 0;
  }
  51% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  75% {
    transform: translateY(-20px);
    opacity: 0;
  }
  76% {
    transform: translateY(-20px);
    opacity: 0;
  }
  /* anim here*/
  91% {
    transform: translateY(-20px);
    opacity: 0;
  }
  92% {
    transform: translateY(0);
    opacity: 1;
  }
  99% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-20px);
    opacity: 0;
  }
}

#mg-phone-anim-space>div:nth-child(1) {
  animation: mg-phone-anim-space-msg-fade-in-1 40s ease-in-out infinite;
}

#mg-phone-anim-space>div:nth-child(2) {
  animation: mg-phone-anim-space-msg-fade-in-2 40s ease-in-out infinite;
}

#mg-phone-anim-space>div:nth-child(3) {
  animation: mg-phone-anim-space-msg-fade-in-3 40s ease-in-out infinite;
}

#mg-phone-anim-space>div:nth-child(4) {
  animation: mg-phone-anim-space-msg-fade-in-4 40s ease-in-out infinite;
}
/* messenger animation end */

.message-button {
  border-radius: 12px;
  background-color: #ff5e64;
  color: #fff;
  font-size: 14px;
  text-align: center;
  padding: 5px;
  font-weight: 700;
  margin-top: 9px
}

@media (max-width: 767px) {
  .parker-in-action-animation {
    display: none;
  }
  .parker-in-action-animation-proxy {
    display: block;
    max-width: 100%;
  }
}
/** animation - end **/

.why-us {
  padding: 40px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
  max-width: 910px;
  margin: auto;
}
.why-us-single {
  border-radius: 20px;
  padding: 40px 20px;
  text-align: center;
}
@media (max-width: 1023px) {
  .why-us {
    padding: 40px 0;
    display: block;
  }
}