.banner-hpac-assistant-wrapper {
  padding: 0;
  background-color: #ffffff;
  max-width: 100vw;
  overflow: hidden;
}
.banner-hpac-assistant-wrapper .banner-hpac-assistant {
  max-width: 100vw;
  margin: auto;
  background-image: url('https://d365pq86x330zn.cloudfront.net/5133x560-3x07-4657-0160-57x707775700.jpg');
  background-image: linear-gradient(180deg, rgba(0,0,0,.5) 0%, rgba(0,0,0,0) 78%), url('https://d365pq86x330zn.cloudfront.net/5133x560-3x07-4657-0160-57x707775700.jpg');
  background-position: bottom center;
  background-size: cover;
  height: calc(100vh - 100px);
  display: flex;
  flex-flow: column;
  justify-content: center;
  position: relative;
}
.banner-hpac-assistant-wrapper .banner-hpac-assistant .banner-hpac-assistant-head {
  padding: 25px 25px;
  flex: 0 1 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.banner-hpac-assistant-wrapper .banner-hpac-assistant .banner-hpac-assistant-body {
  padding: 15px;
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.banner-hpac-assistant-wrapper h2 {
  font-weight: 600;
  font-size: 78px;
  color: #4b6460;
  font-family: 'Times New Roman', Times, serif;
  margin: 0 0 10px;
  text-transform: uppercase;
}
.banner-hpac-assistant-wrapper p {
  font-weight: 500;
  font-size: 12px;
  color: #fff;
  margin: 0 0 10px;
}
.banner-hpac-assistant-wrapper label {
  font-weight: 400;
  font-size: 36px;
  color: #4b6460;
  margin: 0;
  display: block;
}
.banner-hpac-assistant-wrapper input {
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 16px;
  color: #000;
  background-color: #fff;
  padding: 10px 15px;
  border-radius: 5px;
  display: block;
  width: 100%;
  cursor: pointer;
}
.banner-hpac-assistant-wrapper img {
  max-width: 100%;
}
.banner-hpac-assistant-wrapper a {
  color: #35A3C1;
}
.banner-hpac-assistant-wrapper .banner-hpac-assistant-body a {
  border: 1px solid #fff;
  outline: none;
  background-color: transparent;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  padding: 15px 35px;
  display: inline-block;
  cursor: pointer;
  margin: 0 20px 15px;
}
.banner-hpac-assistant-wrapper span {
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  display: block;
  cursor: pointer;
}
.banner-hpac-assistant-wrapper .socials-btns.messenger {
  position: absolute;
  bottom: 20px;
  left: calc(50% - 60px);
  display: block;
}
.banner-hpac-assistant-wrapper .socials-btns.twitter {
  position: absolute;
  bottom: 5px;
  left: calc(50% - 200px);
  display: block;
}
.banner-hpac-assistant-wrapper .socials-btns.whatsapp {
  position: absolute;
  bottom: 0;
  left: calc(50% + 75px);
  display: block;
}


.banner-hpac-assistant-wrapper .socials-btns.messenger img {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  5% { transform: translate(-1px, -2px) rotate(-1deg); }
  10% { transform: translate(-3px, 0px) rotate(1deg); }
  15% { transform: translate(3px, 2px) rotate(0deg); }
  20% { transform: translate(1px, -1px) rotate(1deg); }
  25% { transform: translate(-1px, 2px) rotate(-1deg); }
  30% { transform: translate(-3px, 1px) rotate(0deg); }
  35% { transform: translate(3px, 1px) rotate(-1deg); }
  40% { transform: translate(-1px, -1px) rotate(1deg); }
  45% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

@media (max-width: 1023px) {
  .banner-hpac-assistant-wrapper h2 {
    font-size: 50px;
  }
}

@media (max-width: 767px) {
  .banner-hpac-assistant-wrapper span {
    font-size: 12px;
  }
  .banner-hpac-assistant-wrapper {
    padding: 0;
  }
  .banner-hpac-assistant-wrapper .banner-hpac-assistant {
    grid-template-columns: 1fr;
  }
  .banner-hpac-assistant-wrapper .socials-btns.messenger {
    position: absolute;
    bottom: 0;
    left: calc(50% - 50px);
    display: block;
    max-width: 100px;
  }
  .banner-hpac-assistant-wrapper .socials-btns.twitter {
    position: absolute;
    bottom: 5px;
    left: 0;
    display: block;
    max-width: 100px;
  }
  .banner-hpac-assistant-wrapper .socials-btns.whatsapp {
    position: absolute;
    bottom: 0;
    left: initial;
    right: -5px;
    display: block;
    max-width: 110px;
  }
}