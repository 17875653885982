.thank-you{
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  background-image: url('https://d365pq86x330zn.cloudfront.net/3m483m14-3918-4317-8m4a-11116179i197.png');
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  box-sizing: border-box;
  padding: 50px;
}
.thank-you-inner {
  background: #FFFFFF;
  border: 1px solid #D1D1D1;
  border-radius: 20px;
  text-align: center;
  max-width: 1168px;
  margin: auto;
  overflow: hidden;
  box-sizing: border-box;
}
.thank-you-inner-text {
  padding: 80px 50px 50px;
}
.thank-you-inner-image {
  width: 100%;
  height: auto;
  margin-bottom: -10px;
}
.thank-you-inner-text h1 {
  font-size: 64px;
  color: #FF385C;
  max-width: 500px;
  margin: 40px auto 50px;
}
.thank-you-inner-text h2 {
  font-size: 32px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 50px;
}
.thank-you-inner-text a,
.thank-you-inner-text button {
  border: none;
  outline: none;
  background: none;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 40px;
  padding: 20px 40px;
  display: flex;
  align-items: center;
  margin: auto;
  font-weight: 600;
  font-size: 32px;
  color: #FF385C;
  max-width: max-content;
}