footer.footer.parker-in-action-p {
  padding: 42px 15px;
  background-color: #fff;
  color: #262626;
}
footer.footer.parker-in-action-p .footer-navbar {
  max-width: 1036px;
  display: block;
  text-align: center;
}
footer.footer.parker-in-action-p .footer-navbar .footer-links {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
footer.footer.parker-in-action-p .footer-navbar .footer-links li h4 {
  font-size: 20px;
  font-weight: 400;
  margin: 0;
  margin-bottom: 10px;
}
footer.footer.parker-in-action-p .footer-navbar .footer-links li a {
  padding: 10px 0;
  color: #fff;
  text-decoration: none;
  display: inline-block;
}
footer.footer.parker-in-action-p .footer-notes {
  padding: 20px;
  text-align: center;
}
footer.footer.parker-in-action-p .company-image {
  max-width: 1036px;
  margin: auto;
  text-align: center;
}
footer.footer.parker-in-action-p .company-image img {
  max-width: 100%;
  margin: auto;
}
footer.footer.parker-in-action-p .company-details h4 {
  font-size: 20px;
  font-weight: 400;
  margin: 10px 0;
}
footer.footer.parker-in-action-p .company-details p {
  font-size: 16px;
  font-weight: 300;
  margin: 25px auto 45px;
  max-width: 622px;
}
footer.footer.parker-in-action-p .company-community h4 {
  font-size: 20px;
  font-weight: 400;
  margin: 0;
  padding-bottom: 10px;
}
footer.footer.parker-in-action-p .company-community input::placeholder {
  color: #FFFFFF;
}
footer.footer.parker-in-action-p .company-community input {
  background: #AD4054;
  border-radius: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #FFFFFF;
  outline: none;
  border: none;
  padding: 10px 15px;
  display: block;
  width: 100%;
  margin-bottom: 15px;
}
footer.footer.parker-in-action-p .company-community button {
  outline: none;
  border: none;
  background: #FF385C;
  color: #fff;
  border-radius: 30.5px;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  display: block;
  padding: 12px;
  text-align: center;
  margin: 15px 0;
  width: 100%;
}
footer.footer.parker-in-action-p .icon {
  margin: 12px;
}

@media (max-width: 767px) {
  footer.footer.parker-in-action-p .footer-navbar .footer-links {
    flex-flow: column;
  }
  footer.footer.parker-in-action-p .footer-navbar {
    display: block;
  }
  footer.footer.parker-in-action-p .company-details {
    margin-bottom: 50px;
  }
  footer.footer.parker-in-action-p .company-community {
    margin-top: 50px;
  }
}