.pos-relative {
  position: relative;
}

/** MP PLUGIN PRODUCT **/
.mp-plugin-product-wrapper {
  position: relative;
  margin-bottom: 20px;
}
.mp-plugin-product {
  background: #FFFFFF;
  border-radius: 10px;
  padding: 30px;
}
.mp-plugin-product-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.mp-plugin-product-header-text {
  flex: 1 1 auto;
}
.mp-plugin-product-header-text .name {
  font-weight: 600;
  font-size: 32px;
  color: #1B1B1B;
}
.mp-plugin-product-header-control {
  flex: 0 1 auto;
}
.mp-plugin-product-header-control .title {
  font-weight: 600;
  font-size: 16px;
  color: #1B1B1B;
}
.mp-plugin-product-header-control .color {
  font-weight: 400;
  font-size: 16px;
  color: #8E8E8E;
  margin: 0 0 7px;

  background: transparent;
  border-radius: 10px;
  border: 1px solid #D9D9D9;
  padding: 10px 10px;
}
.mp-plugin-product-inner {
  display: grid;
  grid-template-columns: 1.2fr .8fr;
  grid-gap: 40px;
}
.mp-plugin-product-image {
  box-sizing: border-box;
  padding-top: 100%;border: 1px solid #9C9C9C;
  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.mp-plugin-product-iframe {
  box-sizing: border-box;
  padding-top: 80%;
  height:100%;
  border: 1px solid #9C9C9C;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}
.mp-plugin-product-iframe iframe {
  position: absolute;
  inset: 0;
  outline: 0;
  border: 0;
  width: 133%;
  height: 133%;

  -ms-zoom: 0.75;
  -moz-transform: scale(0.75);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.75);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.75);
  -webkit-transform-origin: 0 0;
}
.mp-plugin-product-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.mp-plugin-product-info .type {
  display: block;
  font-weight: 700;
  font-size: 20px;
  color: #FF385C;
  margin: 0 0 16px;
  text-transform: capitalize;
}
.mp-plugin-product-info .detail {
  display: block;
  font-weight: 600;
  font-size: 24px;
  color: #1B1B1B;
  line-height: 1.7;
  margin: 0 0 24px;
  text-transform: capitalize;
}
.mp-plugin-product-info .list {
  padding: 0;
  list-style-type: none;
  margin-bottom: 50px;
}
.mp-plugin-product-info .list > li {
  display: flex;
  margin-bottom: 25px;
}
.mp-plugin-product-info .list > li > svg {
  min-width: 35px;
  margin-right: 15px;
}
.mp-plugin-product-info .list > li > label {
  flex: 1 1 auto;
  display: block;
  font-weight: 400;
  font-size: 19px;
  color: #000000;
  line-height: 1.7;
  text-transform: capitalize;
}
.mp-plugin-product-info .add-to-basket {
  cursor: pointer;
  display: inline-block;
  padding: 17px 45px;
  font-weight: 700;
  font-size: 16px;
  color: #FF385C;
  border-radius: 30px;
  border: none;
  width: max-content;
  user-select: none;
  background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
  box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.25);
  border-radius: 28px;
}
.mp-plugin-product-info .add-to-basket.disabled {
  opacity: .7;
  pointer-events: none;
}
.mp-plugin-product-info .add-to-basket.grey {
  background-color: #C5C5C5;
}
.mp-plugin-product-info .pricing {
  cursor: pointer;
  display: inline-block;
  padding: 20px 30px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 16px;
  border-radius: 30px;
  border: none;
  color: #fff;
  text-transform: uppercase;
  background-color: #FF385C;
  width: max-content;
  margin: 7px;
}
.mp-plugin-product-info-button-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.mp-plugin-product-info-button-wrap .green-button {
  border: none;
  outline: none;
  background: linear-gradient(180deg, #9BC853 0%, #6A991F 100%);
  box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.25);
  border-radius: 28px;
  padding: 7px 20px;
  cursor: pointer;
  text-align: center;
}
.mp-plugin-product-info-button-wrap .green-button label {
  font-weight: 700;
  font-size: 20px;
  color: #FFFFFF;
  padding: 10px 0;
  display: block;
  text-align: center;
  user-select: none;
  cursor: pointer;
}
.mpr {
  font-weight: 700;
  font-size: 14px;
  color: #FFFFFF;
  padding: 15px 30px;
  background: #FF385C;
  position: absolute;
  top: -23px;
  left: 30px;
}

@media (max-width: 1023px) {
  .mp-plugin-product-header {
    display: block;
  }
  .mp-plugin-product-header-text {
    margin-bottom: 10px;
  }
  .mp-plugin-product-inner {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 767px) {
  .mp-plugin-product-info-button-wrap {
    grid-template-columns: 1fr;
  }
  .mp-plugin-product-info .add-to-basket {
    display: block;
    width: 100%;
    text-align: center;
  }
  .bsw-plugins {
    padding: 15px;
  }
  .bsw-plugins .basic-store-controls-title {
    margin: 25px 0;
  }
  .bsw-plugins .basic-store-products {
    margin: 50px 0;
  }
  .mp-plugin-product {
    padding: 30px 15px 15px;
  }
  .mp-plugin-product-iframe {
    padding-top: 120%;
  }
  .mpr {
    left: 15px
  }
}