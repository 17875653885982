.book-a-demo-form-wrapper {
  background: linear-gradient(180deg, #70212F 12.58%, #DD3251 93.53%);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
.book-a-demo-form-wrapper > * {
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
.book-a-demo-form-wrapper,
.book-a-demo-posts-wrapper {
  background-color: #fff;
  box-sizing: border-box;
}
.book-a-demo-form-wrapper img,
.book-a-demo-posts-wrapper img {
  max-width: 100%;
}
.book-a-demo-form,
.book-a-demo-posts {
  max-width: 1036px;
  padding: 15px;
  margin: auto;
}
.book-a-demo-form-text {
  max-width: 1036px;
  margin: auto;
  padding: 30px 0 20px;
}
.book-a-demo-form-text h1 {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 40px;
  color: #fff;
  margin: 0 0 25px;
}
.book-a-demo-form-text p {
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  margin: 0 0 20px;
}
.book-a-demo-form-fields {
  max-width: 1036px;
  margin: auto;
}
.book-a-demo-form-fields .form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 70px;
}
.book-a-demo-form-fields .form-col {
  padding: 10px;
  margin-bottom: 10px;
}
.book-a-demo-form-fields .form-group {
  display: flex;
}
.book-a-demo-form-fields .form-group label {
  min-width: 100px;
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  text-align: left;
  padding: 10px 10px 10px 0;
}
.book-a-demo-form-fields .form-group input {
  flex: 1 1 auto;
  background: #FFFFFF;
  border: 1px solid #C9C9C9;
  border-radius: 5px;
  padding: 10px;
}


.book-a-demo-posts-wrapper {
  padding: 90px 30px;
  background: #fff;
}
.book-a-demo-posts-intro {
  margin-top: 0;
  display: flex;
  background-color: #424242;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.book-a-demo-posts-intro-img {
  width: 47%;
  background-image: url('https://d365pq86x330zn.cloudfront.net/c1670102-7125-47e8-0518-75c111R0267e.png');
  background-image: url('https://d365pq86x330zn.cloudfront.net/681GGo44-55o2-438G-24o5-138643o444o4.png');
  background-size: cover;
  background-position: top left;

}
.book-a-demo-posts-intro-text {
  width: 53%;
  padding: 70px 50px;
}
.book-a-demo-posts-intro-text h2 {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 32px;
  color: #fff;
  margin: 0 0 35px;
}
.book-a-demo-posts-intro-text p {
  font-weight: 400;
  font-size: 20px;
  color: #fff;
  line-height: 1.7;
  margin: 0 0 40px;
}
.book-a-demo-posts-intro-text .b2c {
  padding: 17px;
  border-color: #fff;
  background-color: transparent;
  color: #fff;
  margin-right: 20px;
  margin-bottom: 20px;
}
.book-a-demo-posts-intro-text .b2b {
  padding: 17px;
  color: #FF385C;
  background-color: #fff;
}

.book-a-demo-posts-single {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
}
.book-a-demo-posts-single-text,
.book-a-demo-posts-single-img {
  flex: 1 1 auto;
  width: 50%;
}
.book-a-demo-posts-single-text {
  padding: 50px 30px;
}
.book-a-demo-posts-single-text h2 {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 32px;
  color: #565A5C;
  margin: 0 0 35px;
}
.book-a-demo-posts-single-text p {
  font-weight: 400;
  font-size: 20px;
  color: #565A5C;
  line-height: 1.7;
  margin: 0 0 40px;
}
.book-a-demo-posts-single.reverse .book-a-demo-posts-single-img {
  order: 1;
}
.book-a-demo-posts-single.reverse .book-a-demo-posts-single-text {
  order: 2;
}
.book-a-demo-posts-single.reverse .book-a-demo-posts-single-text .btn {
  margin-left: auto;
  display: block;
  width: fit-content;
}


.appointment-date-row {
  margin: 28px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  column-gap: 16px;
  row-gap: 16px;
}
.appointment-time-row {
  margin: 28px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 16px;
  row-gap: 16px;
}
.appointment-hour-row {
  display: none;
}
.appointment-hour-row.active {
  margin: 28px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 16px;
  row-gap: 16px;
}
.yes-no-row {
  margin: 28px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 16px;
  row-gap: 16px;
}
.action-row {
  margin: 28px 0;
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 16px;
  row-gap: 16px;
}
.appointment-date-button {
  width: 100%;
  border: 1px solid #FF385C;
  text-align: center;
  padding-top: 16px;
  border-radius: 8px;
  padding-bottom: 16px;
  background-color: #fff;
}
.appointment-date-button.active {
  background-color: #FF385C;
}
.appointment-date-button.active > * {
  color: #fff;
}
.appointment-date-month {
  line-height: 1.334;
  letter-spacing: 0;
  margin: 0;
  font-size: 14px;
  font-style: normal;
  color: #FF385C;
  font-weight: 500;
}
.appointment-date-day {
  line-height: 1.2;
  letter-spacing: 0;
  margin: 0;
  font-size: 20px;
  font-style: normal;
  color:#FF385C;
  font-weight: 500;
}

.checkbox-wrapper {
  background: #FBFBFB;
  border: 1px solid #C4C4C4;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  align-items: center;
}
.checkbox-wrapper .checkbox {
  background: #FFFFFF;
  border: 1px solid #D6D6D6;
  border-radius: 5px;
  padding: 5px;
  display: inline-block;
  flex: 0 1 auto;
}
.checkbox-wrapper .checkbox .checkbox-inner {
  background: #FF385C;
  border: 1px solid #FF385C;
  border-radius: 5px;
  height: 20px;
  width: 20px;
  opacity: 0;
}
.checkbox-wrapper .checkbox.active .checkbox-inner {
  opacity: 1;
}
.checkbox-wrapper .checkbox-label {
  display: block;
  padding: 0 20px;
  font-weight: 400;
  font-size: 14px;
  color: #5E5E5E;
  flex: 1 1 auto;
}

.book-a-demo-form-fields .submit {
  width: 100%;
}
.book-a-demo-form-fields .submit.disabled {
  pointer-events: none;
  opacity: .7;
}


@media (max-width: 1023px) {
  .book-a-demo-form-wrapper,
  .book-a-demo-posts-wrapper {
    padding: 0;
  }
  .book-a-demo-posts-intro {
    margin-top: 0;
  }
  .book-a-demo-posts-intro {
    flex-direction: column;
  }
  .book-a-demo-posts-intro-img,
  .book-a-demo-posts-intro-text {
    width: auto;
  }
  .book-a-demo-posts-intro-img {
    padding-top: 70%;
  }
  .book-a-demo-posts-single {
    flex-direction: column;
  }
  .book-a-demo-posts-single .book-a-demo-posts-single-img,
  .book-a-demo-posts-single.reverse .book-a-demo-posts-single-img {
    order: 1;
    width: auto;
  }
  .book-a-demo-posts-single .book-a-demo-posts-single-text,
  .book-a-demo-posts-single.reverse .book-a-demo-posts-single-text {
    order: 2;
    width: auto;
  }
  .book-a-demo-posts-single.reverse .book-a-demo-posts-single-text .btn {
    margin-left: 0;
    display: block;
  }
  .book-a-demo-form-fields .form-row {
    flex-direction: column;
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
  .book-a-demo-form-fields .form-col {
    width: auto;
  }
}

@media (max-width: 767px) {
}