
@keyframes moving-animation {
  from {
    left: -120vw;
  }

  to {
    left: 120vw;
  }
}

@keyframes message-left-in {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes message-right-in {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes typing-ball {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(5px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes typing-wrapper-fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes message-show {
  0% {
    width: 0;
    height: 0;
  }

  100% {
    width: 100%;
    height: 100%;
  }
}

@keyframes button-slide-up {
  0% {
    opacity: 0;
    transform: translateY(28px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes avatar-slide-down {
  0% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes input-slide-up {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.top-banner .conversation-wrapper {
  padding: 40px 0px;
  overflow-y: auto;
  max-height: calc(100% - 30px);
  height: 100%;
}

.top-banner .conversation-wrapper.max-height {
  max-height: 100% !important;
}

.top-banner .conversation-wrapper .conversation-body {
  margin: auto;
}

.top-banner .conversation-wrapper .conversation-body .conversation-message {
  display: flex;
  margin-bottom: 10px;
  transition: opacity 0.5s, background-color 0.75s, color 0.75s;
}

.top-banner .conversation-wrapper .conversation-body .conversation-message:last-child .conversation-message-button {
  display: block !important;
}

.top-banner .conversation-wrapper .conversation-body .conversation-message .conversation-message-avatar {
  min-width: 40px;
  min-height: 40px;
  background-repeat: no-repeat;
  position: relative;
  align-self: flex-end;
  opacity: 0;
  animation: avatar-slide-down 0.2s ease-in-out;
  animation-fill-mode: forwards;
  box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 30%);
  background-color: white;
  border-radius: 50%;
  background-size: cover;
}

.top-banner .conversation-wrapper .conversation-body .conversation-message .conversation-message-avatar.visible {
  opacity: 1;
}

.top-banner .conversation-wrapper .conversation-body .conversation-message.conversation-message-bot .conversation-message-avatar {
  margin-right: 20px;
}

.top-banner .conversation-wrapper .conversation-body .conversation-message.conversation-message-bot .conversation-message-bubble {
  background-color: #fff;
  animation: message-left-in 0.5s ease-in-out;
  box-shadow: 0 1px 28px 0 rgba(0, 0, 0, 0.22);
}

.top-banner .conversation-wrapper .conversation-body .conversation-message.conversation-message-bot .conversation-message-bubble .conversation-message-bubble-video,
.top-banner .conversation-wrapper .conversation-body .conversation-message.conversation-message-bot .conversation-message-bubble .conversation-message-bubble-text {
  color: #000;
}

.top-banner .conversation-wrapper .conversation-body .conversation-message.conversation-message-user {
  flex-direction: row-reverse;
}

.top-banner .conversation-wrapper .conversation-body .conversation-message.conversation-message-user .conversation-message-avatar {
  display: none;
}

.top-banner .conversation-wrapper .conversation-body .conversation-message.conversation-message-user .conversation-message-bubble {
  background-color: rgb(255, 56, 92);
  animation: message-right-in 0.5s ease-in-out;
  box-shadow: 0 1px 28px 0 rgba(255, 56, 92, .4);
}


.top-banner .conversation-wrapper .conversation-body .conversation-message.conversation-message-user .conversation-message-bubble .typing-animation-wrapper .typing-spinner .ball {
  background: #fff;
}

.top-banner .conversation-wrapper .conversation-body .conversation-message.conversation-message-user .conversation-message-bubble .conversation-message-bubble-video,
.top-banner .conversation-wrapper .conversation-body .conversation-message.conversation-message-user .conversation-message-bubble .conversation-message-bubble-text {
  color: #fff;
}

.top-banner .conversation-wrapper .conversation-body .conversation-message .conversation-message-bubble {
  padding: 15px 20px;
  max-width: 60%;
  height: auto;
  border-radius: 17px;
  min-width: 80px;
  min-height: 52px;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  /* // box-sizing: content-box; */
}


.top-banner .conversation-wrapper .conversation-body .conversation-message .conversation-message-bubble .conversation-message-bubble-video {
  font-size: 17px;
  font-weight: 500;
  width: 100%;
  height: 100%;
  overflow: hidden;
  max-width: 420px;
}


.top-banner .conversation-wrapper .conversation-body .conversation-message .conversation-message-bubble .conversation-message-bubble-text {
  font-size: 17px;
  font-weight: 500;
  width: 0;
  height: 0;
  overflow: hidden;
  animation: message-show 0s ease-in-out;
  animation-delay: 0.8s;
  animation-fill-mode: forwards;
}
.top-banner .conversation-wrapper .conversation-body .conversation-message .conversation-message-bubble .conversation-message-bubble-text > span > div  {
  display: inline!important;
}

.typing-animation-wrapper {
  position: absolute;
}

.typing-animation-wrapper:not(.infinite) {
  animation: typing-wrapper-fade-out 0.3s ease-in-out;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
}

.typing-animation-wrapper .typing-spinner .ball {
  display: inline-block;
  width: 9px;
  height: 9px;
  margin-right: 5px;
  border-radius: 50%;
  background-color: rgb(255, 56, 92);
}

.typing-animation-wrapper .typing-spinner .ball:nth-child(1) {
  opacity: 0.9;
  animation: typing-ball 0.9s linear infinite;
}

.typing-animation-wrapper .typing-spinner .ball:nth-child(2) {
  opacity: 0.9;
  animation: typing-ball 0.9s 0.225s linear infinite;
}

.typing-animation-wrapper .typing-spinner .ball:nth-child(3) {
  opacity: 0.6;
  animation: typing-ball 0.9s 0.45s linear infinite;
}