footer.footer {
  padding: 42px 15px;
  background-color: #70212F;
  color: #fff;
}
footer.footer .footer-navbar {
  max-width: 1036px;
  margin: auto;
  display: grid;
  grid-template-columns: 434fr 280fr 319fr;
}
footer.footer .footer-navbar .footer-links {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
footer.footer .footer-navbar .footer-links li h4 {
  font-size: 20px;
  font-weight: 400;
  margin: 0;
  margin-bottom: 10px;
}
footer.footer .footer-navbar .footer-links li a {
  padding: 10px 0;
  color: #fff;
  text-decoration: none;
  display: inline-block;
}
footer.footer .footer-notes {
  padding: 20px;
  text-align: center;
}
footer.footer .company-image {
  max-width: 1036px;
  margin: auto;
}
footer.footer .company-image img {
  max-width: 180px;
}
footer.footer .company-details h4 {
  font-size: 20px;
  font-weight: 400;
  margin: 10px 0;
}
footer.footer .company-details p {
  font-size: 16px;
  font-weight: 300;
  max-width: 330px;
  margin: 25px 0 45px;
}
footer.footer .company-community h4 {
  font-size: 20px;
  font-weight: 400;
  margin: 0;
  padding-bottom: 10px;
}
footer.footer .company-community input::placeholder {
  color: #FFFFFF;
}
footer.footer .company-community input {
  background: #AD4054;
  border-radius: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #FFFFFF;
  outline: none;
  border: none;
  padding: 10px 15px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 15px;
}
footer.footer .company-community button,
footer.footer .company-community .fbtn {
  outline: none;
  border: none;
  background: #FF385C;
  color: #fff;
  border-radius: 30.5px;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  display: block;
  padding: 12px;
  text-align: center;
  margin: 15px 0;
  width: 100%;
  box-sizing: border-box;
}
footer.footer .icon {
  margin-right: 24px;
}



@media (max-width: 767px) {
  footer.footer .footer-navbar .footer-links {
    flex-flow: column;
  }
  footer.footer .footer-navbar {
    display: block;
  }
  footer.footer .company-details {
    margin-bottom: 50px;
  }
  footer.footer .company-community {
    margin-top: 50px;
  }
}