
.about-form-wrapper,
.about-contents-wrapper {
  padding: 70px 30px;
  background-color: #fff;
  background-image: url('https://d365pq86x330zn.cloudfront.net/0o991167-110r-4rr9-19r6-196r09109161.png'), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #131313 70%);
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  box-sizing: border-box;
}
.about-form-wrapper img,
.about-contents-wrapper img {
  max-width: 100%;
}
.about-form,
.about-contents {
  max-width: 1047px;
  margin: auto;
}
.about-form-text {
  max-width: 579px;
  margin-right: auto;
  text-align: left;
  padding: 30px 0 20px;
}
.about-form-text h1 {
  font-weight: 600;
  font-size: 48px;
  color: #ffffff;
  margin: 0 0 14px;
  line-height: .7;
}
.about-form-text h1 span {
  font-size: 64px;
  color: #FF385C;
}
.about-form-text p {
  font-weight: 600;
  font-size: 24px;
  color: #ffffff;
  margin: 0 0 25px;
}
.about-form-fields {
  max-width: 900px;
  margin: auto;
}
.about-form-fields .form-row {
  display: flex;
}
.about-form-fields .form-col {
  flex: 1 1 auto;
  width: 50%;
  padding: 20px 10px;
}
.about-form-fields .form-group {
  display: flex;
}
.about-form-fields .form-group label {
  min-width: 85px;
  font-weight: 400;
  font-size: 16px;
  color: #565A5C;
  text-align: right;
  padding: 10px;
}
.about-form-fields .form-group input {
  flex: 1 1 auto;
  background: #FFFFFF;
  border: 1px solid #C9C9C9;
  border-radius: 5px;
  padding: 10px;
}

.about-contents-laptop {
  max-width: 100%;
}

.about-contents-wrapper {
  padding: 70px 30px 40vh;
  background-color: #F8F8F8;
  background-image: url('https://d365pq86x330zn.cloudfront.net/r203G166-21r8-4o92-06o0-66o0G46GoG62.png');
  background-position: bottom;
  background-size: auto 50vh;
  background-repeat: repeat-x;
}
.about-contents-intro {
  margin-top: -140px;
  display: flex;
  background-color: #FF385C;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.about-contents-intro-img {
  width: 47%;
  background-image: url('https://d365pq86x330zn.cloudfront.net/c1670102-7125-47e8-0518-75c111R0267e.png');
  background-size: cover;
  background-position: top left;

}
.about-contents-intro-text {
  width: 53%;
  padding: 70px 50px;
}
.about-contents-intro-text h2 {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 32px;
  color: #fff;
  margin: 0 0 35px;
}
.about-contents-intro-text p {
  font-weight: 400;
  font-size: 20px;
  color: #fff;
  line-height: 1.7;
  margin: 0 0 40px;
}
.about-contents-intro-text .b2c {
  padding: 17px;
  border-color: #fff;
  background-color: transparent;
  color: #fff;
  margin-right: 20px;
  margin-bottom: 20px;
}
.about-contents-intro-text .b2b {
  padding: 17px;
  color: #FF385C;
  background-color: #fff;
}

.about-contents-single {
  display: flex;
  justify-content: center;
  padding: 50px 0;
}
.about-contents-single-text,
.about-contents-single-img {
  flex: 1 1 auto;
  width: 50%;
  margin: 20px 0 0;
}
.about-contents-single-text {
  padding: 0;
}
.about-contents-single-text h2 {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 32px;
  color: #565A5C;
  margin: 0 0 35px;
}
.about-contents-single-text p {
  font-weight: 600;
  font-size: 18px;
  color: #565A5C;
  line-height: 1.7;
  margin: 0 0 40px;
}
.about-contents-single.reverse .about-contents-single-img {
  order: 1;
}
.about-contents-single.reverse .about-contents-single-text {
  order: 2;
}
.about-contents-single.reverse .about-contents-single-text .btn {
  margin-right: auto;
  display: block;
  width: fit-content;
}

.about-form-fields .submit {
  width: 100%;
}
.about-form-fields .submit.disabled {
  pointer-events: none;
  opacity: .7;
}


@media (max-width: 1023px) {
  .about-form-wrapper,
  .about-contents-wrapper {
    padding: 30px;
  }
  .about-contents-intro {
    margin-top: 0;
  }
  .about-contents-intro {
    flex-direction: column;
  }
  .about-contents-intro-img,
  .about-contents-intro-text {
    width: auto;
  }
  .about-contents-intro-img {
    padding-top: 70%;
  }
  .about-contents-single {
    flex-direction: column;
  }
  .about-contents-single .about-contents-single-img,
  .about-contents-single.reverse .about-contents-single-img {
    order: 1;
    width: auto;
  }
  .about-contents-single .about-contents-single-text,
  .about-contents-single.reverse .about-contents-single-text {
    order: 2;
    width: auto;
  }
  .about-contents-single.reverse .about-contents-single-text .btn {
    margin-left: 0;
    display: block;
  }
  .about-form-fields .form-row {
    flex-direction: column;
  }
  .about-form-fields .form-col {
    width: auto;
  }
}

@media (max-width: 767px) {
}