.new-store {
  background-color: #70212F;
  overflow: auto;

  .mp-plugin-product {
    background-color: transparent;

    .mp-plugin-product-iframe {
      background-color: #fff;
    }

    .mp-plugin-product-info .detail,
    .mp-plugin-product-header-text .name,
    .mp-plugin-product-info .list > li > label {
      color: #fff;
    }

    .mp-plugin-product-info .list > li > svg path {
      stroke: #fff;
    }

    .mp-plugin-product-info .add-to-basket,
    .mp-plugin-product-info-button-wrap .green-button {
      min-height: 85px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .mp-plugin-product-info .add-to-basket {
      background-image: none;
      background-color: #FF385C!important;
      color: #fff;
    }
  }
}