.gdpr-wrapper {
  background: linear-gradient(180deg, #70212F 15.03%, #FF385C 102.01%);
  transform: matrix(-1, 0, 0, 1, 0, 0);
  padding: 50px 20px;
}
.gdpr-wrapper .gdpr {
  transform: matrix(-1, 0, 0, 1, 0, 0);
  max-width: 905px;
  background: #FFFFFF;
  border-radius: 20px;
  margin: auto;
  padding: 20px;
}
.gdpr .gdpr-title {
  text-align: center;
  padding-bottom: 35px;
  border-bottom: 2px solid #DADADA;
  max-width: 756px;
  margin: 0 auto 20px;
}
.gdpr h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 1.2;
  color: #FF385C;
  max-width: 700px;
  margin: 30px auto 20px;
}
.gdpr h2 .success {
  color: #30C036;
}
.gdpr .ctrl-btn {
  border: none;
  outline: none;
  background: #FF385C;
  border-radius: 5px;
  padding: 15px 25px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
  text-align: center;
  color: #FFFFFF;
  margin: 0 auto 20px;
}
.gdpr-preferences {
  max-width: 756px;
  margin: 20px auto;
}
.gdpr-preferences .gdpr-preference {
  background: #F4F4F4;
  border: 1px solid #DADADA;
  border-radius: 10px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px auto;
  
}
.gdpr-preference .radio {
  width: 80px;
  height: 40px;
  padding: 5px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  background-color: #53D958;
  user-select: none;
  cursor: pointer;
}
.gdpr-preference .radio.off {
  background-color: #F25454;
}
.gdpr-preference .radio span {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #FFFFFF;
  text-align: center;
  flex: 1 1 auto;
}
.gdpr-preference .radio .radio-sign {
  background-color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}