header.header.parker-in-action-p {
  padding: 10px 30px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  position: fixed;
  width: 100%;
  z-index: 99999999;
  box-sizing: border-box;
}
.header-placeholder {
  background-color: #FFFFFF;
  padding-top: 92px;
}
header.header.parker-in-action-p label,
header.header.parker-in-action-p a {
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.2;
  color: #262626;
  letter-spacing: 1px;
}
header.header.parker-in-action-p a.red {
  color: #FF385C;
}
header.header.parker-in-action-p .nav {
  max-width: 1360px;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
}
header.header.parker-in-action-p.titled .nav {
  grid-template-columns: 3fr 1fr 3fr;
}
header.header.parker-in-action-p .nav .left-navbar,
header.header.parker-in-action-p .nav .logo-wrapper,
header.header.parker-in-action-p .nav .right-navbar {
  flex: 1 1 auto;
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0;
  margin: 0;
}
header.header.parker-in-action-p .nav .logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
}
.space-filler,
header.header.parker-in-action-p .nav .logo-wrapper > .toggle-close,
header.header.parker-in-action-p .nav .logo-wrapper > .toggle-open {
  opacity: 0;
  display: none;
}
header.header.parker-in-action-p .nav .right-navbar {
  grid-template-columns: 1fr;
  text-align: right;
}
header.header.parker-in-action-p .nav .left-navbar > li ,
header.header.parker-in-action-p .nav .right-navbar > li {
  flex: 1 1 auto;
  position: relative;
}

header.header.parker-in-action-p .nav .left-navbar > li > .sub-menu,
header.header.parker-in-action-p .nav .right-navbar > li > .sub-menu {
  position: absolute;
  top: 100%;
  right: 0;
  text-align: left;
  list-style: none;
  min-width: max-content;
  padding: 10px;
  background: #fff;
  border: 1px solid #565A5C;
  border-bottom: 3px solid #FF385C;
  display: none;
}

header.header.parker-in-action-p .nav .left-navbar > li:hover > .sub-menu,
header.header.parker-in-action-p .nav .right-navbar > li:hover > .sub-menu {
  display: block;
}

header.header.parker-in-action-p .nav .left-navbar > li > .sub-menu > li,
header.header.parker-in-action-p .nav .right-navbar > li > .sub-menu > li {
  padding: 5px 20px;
}

header.header.parker-in-action-p .btn {
  text-transform: none;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2;
  padding: 14px 18px;
  margin: 0 0 0 10px;
  color: #fff;
  display: inline-block;
}
header.header.parker-in-action-p .icon {
  margin: auto 12px;
}
header.header.parker-in-action-p .icon:last-child {
  margin-right: 0;
}
header.header.parker-in-action-p .pac-btn {
  background: #FF385C;
  box-shadow: 0px 4px 19px rgba(242, 77, 107, 0.6);
  border-radius: 30.5px;
  display: inline-block;
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  margin: 10px;
  padding: 10px 22px;
}
header.header.parker-in-action-p .pac-btn.black {
  background: #262626;
  box-shadow: none;
}
header.header.parker-in-action-p h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  color: #000000;
  margin: 0 10px;
  padding-left: 10px;
  border-left: 1px solid #9C9C9C;
}
header.header.parker-in-action-p.titled .left-navbar li {
  display: flex;
  align-items: center;
}
header.header.parker-in-action-p.titled .logo {
  display: none;
}

@media (max-width: 1023px) {
  header.header.parker-in-action-p.titled .logo {
    display: block;
  }
  header.header.parker-in-action-p.titled .logo-intro {
    display: none;
  }
  header.header.parker-in-action-p h2 {
    padding-left: 0;
    border-left: none;
  }
  header.header.parker-in-action-p label {
    display: block;
    margin-bottom: 10px;
  }
  header.header.parker-in-action-p .btn {
    margin: 10px 0;
  }
  header.header.parker-in-action-p {
    padding: 10px 20px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    max-height: 100vh;
    overflow-y: auto;
  }
  .header-placeholder {
    padding-top: 63px;
  }
  header.header.parker-in-action-p.titled .nav, 
  header.header.parker-in-action-p .nav {
    grid-template-columns: 1fr;
  }
  header.header.parker-in-action-p .nav .left-navbar,
  header.header.parker-in-action-p .nav .right-navbar {
    display: none;
    padding: 0 10px;
    text-align: left;
    width: auto;
  }
  .header.parker-in-action-p.open .nav .left-navbar,
  .header.parker-in-action-p.open .nav .right-navbar {
    display: block;
  }
  header.header.parker-in-action-p .nav .left-navbar > li,
  header.header.parker-in-action-p .nav .right-navbar > li {
    padding: 10px 0;
  }
  header.header.parker-in-action-p .nav .left-navbar {
    padding-top: 30px;
    order: 2;
  }
  header.header.parker-in-action-p .nav .logo-wrapper {
    order: 1;
    justify-content: space-between;
    padding: 0 10px;
    width: auto;
  }
  header.header.parker-in-action-p .nav .right-navbar {
    padding-bottom: 30px;
    order: 3;
  }
  header.header.parker-in-action-p .nav .logo-wrapper img {
    height: 40px;
    width: auto;
  }
  .space-filler,
  header.header.parker-in-action-p .nav .logo-wrapper > .toggle-close,
  header.header.parker-in-action-p .nav .logo-wrapper > .toggle-open {
    opacity: 1;
    display: block;
  }
  header.header.parker-in-action-p .nav .logo-wrapper > .toggle-close {
    display: none;
  }
  .header.parker-in-action-p.open .nav .logo-wrapper > .toggle-open {
    display: none;
  }
  .header.parker-in-action-p.open .nav .logo-wrapper > .toggle-close {
    display: block;
  }
  header.header.parker-in-action-p .nav .left-navbar > li > .sub-menu,
  header.header.parker-in-action-p .nav .right-navbar > li > .sub-menu {
    display: block;
    position: relative;
    border: none;
    padding: 10px 30px 0;
  }  
  header.header.parker-in-action-p .nav .left-navbar > li > .sub-menu > li,
  header.header.parker-in-action-p .nav .right-navbar > li > .sub-menu > li {
    padding: 10px 0;
  }  
  header.header.parker-in-action-p .nav .left-navbar > li > .sub-menu > li:last-child,
  header.header.parker-in-action-p .nav .right-navbar > li > .sub-menu > li:last-child {
    padding-bottom: 0;
  }  
}