.sign-up-wrapper {
  padding: 30px;
}
.sign-up {
  max-width: 1160px;
  margin: auto;
  display: flex;
  align-items: center;
}
.sign-up-text {
  flex: 1 1 auto;
  width: 50%;
  padding: 10px;
}
.sign-up-text h2 {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 48px;
  color: #FF385C;
  margin: 0 0 25px;
}
.sign-up-text p {
  font-weight: 600;
  font-size: 17px;
  color: #565A5C;
  margin: 0 0 20px;
}
.sign-up-phone {
  flex: 1 1 auto;
  width: 50%;
  padding: 10px;
  text-align: center;
}
.phone-wrapper {
  display: inline-block;
  position: relative;
  margin: auto;
}
.phone-wrapper .phone {
  max-width: 100%;
  margin: auto;
}
.phone-content {
  position: absolute;
  top: 40%;
  left: 2%;
  right: 0;
  display: flex;
  justify-content: center;
}
.phone-content .btn {
  padding: 12px 24px;
  font-size: 14px;
}

@media (max-width: 1023px) {
  .sign-up {
    flex-flow: column;
  }
  .sign-up-text,
  .sign-up-phone {
    width: auto;
  }
}

@media (max-width: 767px) {
  .phone-wrapper {
    width: auto;
  }
  .phone-wrapper .phone {
    opacity: 0;
    display: none;
  }
  .phone-wrapper .phone-content {
    position: relative;
  }
}