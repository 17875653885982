.banner-hpac-com-wrapper {
  padding: 50px 30px;
  background-color: #f3f3f3;
  max-width: 100vw;
  overflow-x: hidden;
  position: relative;
}
.banner-hpac-com-wrapper .banner-hpac-com {
  max-width: 1160px;
  margin: auto;
}
.banner-hpac-com-wrapper .banner-hpac-com h2 {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 32px;
  color: #000;
  margin: 0 auto 35px;
  text-align: center;
  max-width: 900px;
}
.banner-hpac-com-wrapper .banner-hpac-com h3 {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 32px;
  color: #FF385C;
  margin: 0 auto 15px;
  text-align: left;
}
.banner-hpac-com-wrapper .banner-hpac-com img {
  max-width: 100%;
  margin: auto;
}
.left-house-anim {
  border: none;
  outline: none;
  position: absolute;
  top: calc(100% - 50vw);
  left: -30vw;
  width: 60vw;
  height: 40vw;
  filter: grayscale(1);
}
.right-house-anim {
  border: none;
  outline: none;
  position: absolute;
  top: calc(100% - 50vw);
  right: -30vw;
  width: 60vw;
  height: 40vw;
  filter: grayscale(1);
}
.hga-outer {
  position: relative;
}
.hga-outer .pac-btn {
  position: relative;
}
.hga-outer .pac-btn > img {
  position: absolute;
  bottom: 30%;
  left: 80%;
}
.house-glass-animation-wrapper {
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: -31%;
}
.house-glass-animation-wrapper.right {
  left: unset;
  right: -31%;
}
.house-glass-animation {
  position: relative;
  display: inline-block;
  filter: grayscale(1);
}
.house-glass-animation.mobile {
  display: none;
}
.hga-main-image {
  max-width: 100%;
}
.hga-pie-chart {
  position: absolute;
  top: 52%;
  left: 4%;
  width: 14.11%;
  height: 15.41%;
  transform-origin: 13% 60%;
  animation: 1s ease-in-out infinite alternate scale;
}
.hga-bar-chart {
  position: absolute;
  top: 47%;
  left: 77%;
  width: 15.69%;
  height: 38.11%;
  transform-origin: 73% 60%;
  animation: 1s ease-in-out infinite alternate scale;
  animation-delay: 0.3s;
}
.hga-sms-image {
  position: absolute;
  top: 36%;
  left: 10%;
  width: 6.99%;
  height: 10.91%;
  animation: 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite both shake;
  transform-origin: 13% 60%;
}
.hga-2cn-image {
  position: absolute;
  top: 30%;
  left: 76%;
  width: 9.12%;
  height: 10.26%;
  animation: 1s ease-in-out infinite alternate gup;
}
.hga-5cn-image {
  position: absolute;
  top: 71%;
  left: 13%;
  width: 8.96%;
  height: 10.36%;
  animation: 1s ease-in-out 0.3s infinite alternate gdown;
}
@keyframes scale {
  0% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes gup {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(28%);
  }
}
@keyframes gdown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-28%);
  }
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@media (max-width: 1024px) {
  .house-glass-animation-wrapper {
    left: -51%;
  }
  .house-glass-animation-wrapper.right {
    right: -51%;
  }
}

@media (max-width: 767px) {
  .banner-hpac-com-wrapper {
    padding: 20px;
  }
  .banner-hpac-com-wrapper .banner-hpac-com h3,
  .banner-hpac-com-wrapper .banner-hpac-com h2 {
    font-size: 28px;
  }
  .banner-hpac-com-wrapper .banner-hpac-com p {
    font-size: 16px;
  }
  .house-glass-animation-wrapper,
  .house-glass-animation-wrapper.right {
    display: none;
  }
  .house-glass-animation.mobile {
    display: inline-block;
  }
}