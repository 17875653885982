.iframe-modal-wrapper {
  position: fixed;
  inset: 0;
  z-index: 99999999;
  background-color: rgba(255, 255, 255, .7);
  display: flex;
  justify-content: center;
  align-items: center;
}
.iframe-modal {
  width: 90vw;
  max-width: 100%;
  height: 90vh;
  max-height: 100%;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.iframe-modal button.close {
  width: 30px;
  font-size: 20px;
  color: #c0c5cb;
  align-self: flex-end;
  background-color: transparent;
  border: none;
  margin-bottom: 10px;
  cursor: pointer;
}
.iframe-modal iframe {
  border: none;
  width: 100%;
  height: 100%;
}
  