.join-team-parker-wrapper {
  background: linear-gradient(180deg, #70212F 15.03%, #FF385C 102.01%);
  transform: matrix(-1, 0, 0, 1, 0, 0);
  padding: 50px 20px;
}
.join-team-parker-wrapper .join-team-parker {
  transform: matrix(-1, 0, 0, 1, 0, 0);
  max-width: 1036px;
  margin: auto;
}
.join-team-parker-wrapper .join-team-parker h2 {
  font-weight: 600;
  font-size: 48px;
  line-height: 1.3;
  color: #FFFFFF;
}
.join-team-parker-wrapper .join-team-parker h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.3;
  color: #70212F;
  margin: 0 0 15px;
}
.join-team-parker-wrapper .join-team-parker h4 {
  font-weight: 500;
  font-size: 20px;
  line-height: 1.3;
  color: #282828;
  margin: 0 0 5px;
}
.join-team-parker-wrapper .join-team-parker .join-team-parker-inner {
  background: #FFFFFF;
  border-radius: 20px;
  padding: 20px;
}
.join-team-parker-wrapper .join-team-parker .join-team-parker-forms {
  display: grid;
  grid-template-columns: 1fr 1.3fr;
  grid-gap: 40px;
}
.join-team-parker-wrapper .join-team-parker .join-team-parker-form {
  padding: 10px 20px;
  border-top: 2px solid #CDCDCD;
}
.join-team-parker-wrapper .join-team-parker .join-team-parker-form .join-team-parker-form-inner {
  padding: 12px 0;
}
.join-team-parker-wrapper .join-team-parker .join-team-parker-form input {
  border: none;
  outline: none;
  background: #EBEBEB;
  border-radius: 5px;
  padding: 9px 15px;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.3;
  color: #282828;
  display: block;
  width: 100%;
}
.join-team-parker-wrapper .join-team-parker .join-team-parker-form-view {
  background: #E4E4E4;
  padding: 20px;
}
.join-team-parker-form button {
  border: none;
  outline: none;
  background: #FF385C;
  border-radius: 20px;
  padding: 12px;
  font-family: 'Montserrat',sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #FFFFFF;
  min-width: 250px;
  margin: 40px 0 0;
}
.join-team-parker-form button:disabled {
  opacity: .7;
}
.join-team-parker-form-view .file-upload {
  border: none;
  outline: none;
  background: #70212F;
  border-radius: 20px;
  padding: 12px 25px;
  font-family: 'Montserrat',sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #FFFFFF;
  margin: 5px;
  display: flex;
  align-items: center;
  position: relative;
  min-width: 222px;
}
.join-team-parker-form-view .file-upload .file {
  position: absolute;
  inset: 0;
  background-color: red;
  opacity: 0;
  cursor: pointer;
}
.join-team-parker-form-view .bottom-control {
  display: flex;
  align-items: center;
}
.join-team-parker-form-view .bottom-control h6 {
  flex: 1 1 auto;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  text-decoration-line: underline;
  margin: 0;
  color: #000000;
}
.join-team-parker-form-view .bottom-control button {
  flex: 0 1 auto;
}
.join-team-parker-form-view .embed {
  width: 100%;
  min-height: 80vh;
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .join-team-parker-wrapper .join-team-parker .join-team-parker-forms {
    grid-template-columns: 1fr;
  }
}