.twenty7tec-calendar {
  background-color: #fff;
  max-width: 1024px;
  margin: auto;
  overflow: auto;
  padding: 50px 20px 20px;

  h2 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 1.2;
    color: #0C4584;
    margin: 0 0 10px 0;
    text-align: center;
  }

  p {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 1.2;
    color: #0C4584;
    margin: 0;
    text-align: center;
  }

  @media (max-width: 1023px) {
    h2 {
      font-size: 32px;
    }

    p {
      font-size: 24px;
    }

    @media (max-width: 767px) {
      h2 {
        font-size: 24px;
      }
  
      p {
        font-size: 16px;
      }
    }
  }
}
.twenty7tec-calendar-iframe-wrapper {
  position: relative;
  width: 100%;
  height: 746px;
  overflow: hidden;
  
  .twenty7tec-calendar-iframe {
    outline: none;
    border: none;
    box-shadow: none;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2000px;
    margin-top: -42px;
  }

  @media (max-width: 899px) {
    overflow: unset;
    height: 1064px;
  
    .twenty7tec-calendar-iframe {
      margin-top: 0;
      height: 100%;
      position: relative;
    }

    @media (max-width: 767px) {
      height: 1115px;

      @media (max-width: 573px) {
        height: 1523px;
  
        @media (max-width: 349px) {
          height: 1546px;
  
        }
      }
    }
  }
}