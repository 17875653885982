@keyframes cp-moving-line {
  0% {
    background-position: 0 0, 40px 100%, 0 40px, 100% 0;
  }
  100% {
    background-position: 40px 0, 0 100%, 0 0, 100% 40px;
  }
}

@keyframes rotation-to-right {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes rotation-to-left {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-359deg);
  }
}

.the-cp-moving-line {
  background-image: linear-gradient(90deg, #FF6884 50%, transparent 50%), linear-gradient(90deg, #FF6884 50%, transparent 50%), linear-gradient(0, #FF6884 50%, transparent 50%), linear-gradient(0, #FF6884 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 40px 8px, 40px 8px, 8px 40px, 8px 40px;
  animation: cp-moving-line 600ms infinite linear;
}

.cp-wrap {
  aspect-ratio: 15 / 15;
  position: relative;
  max-width: 1200px;
  margin: auto;
  display: block;

  // Fallback
  @supports not (aspect-ratio: 15 / 15) {
    &::before {
      float: left;
      padding-top: calc(100% * 15 / 15);
      content: "";
    }

    &::after {
      display: block;
      content: "";
      clear: both;
    }
  }
}

.cp-wrap .cp-anim h3 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 1;
  color: #FFFFFF;
  margin: 0;
}

.cp-wrap .cp-anim h2 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 1;
  color: #FFFFFF;
  margin: 0;
  margin-bottom: 20px;
}

.cp-wrap .cp-anim p {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.2;
  color: #FFFFFF;
  margin: 0;
  margin-bottom: 20px;
}

.cp-wrap .cp-anim label {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.2;
  color: #FFFFFF;
  margin: 0 0 3px 0;
  width: 100%;
  display: block;
}

.cp-wrap .cp-anim .green {
  color: #1A8C33;
}
.cp-wrap .cp-anim .red {
  color: #D10000;
}

.cp-wrap .cp-anim .try {
  display: block;
  width: max-content;
  margin: auto;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 1;
  color: #FFFFFF;
  padding: 15px 25px;
  background: linear-gradient(180deg, #81AE37 0%, #709D27 100%);
  border-radius: 30.5px;
}

.cp-wrap .cp-anim {
  position: absolute;
  inset: 0;
  display: grid;
  grid-template-columns: repeat(15, 1fr);
  grid-template-rows: repeat(15, 1fr);
}

.cp-wrap .cp-anim .cmp-anim-wrap {
  pointer-events: none;
  grid-column-start: 1;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 6;
}

.cp-wrap .cp-anim .wphn-anim-wrap {
  pointer-events: none;
  grid-column-start: 13;
  grid-column-end: 16;
  grid-row-start: 1;
  grid-row-end: 7;
}

.cp-wrap .cp-anim .wpdt-anim-wrap {
  pointer-events: none;
  grid-column-start: 1;
  grid-column-end: 7;
  grid-row-start: 7;
  grid-row-end: 11;
}

.cp-wrap .cp-anim .chtapp-anim-wrap {
  pointer-events: none;
  grid-column-start: 8;
  grid-column-end: 16;
  grid-row-start: 8;
  grid-row-end: 13;
  position: relative;
}

.cp-wrap .cp-anim .outbound-text {
  background-color: #70212F;
  grid-column-start: 9;
  grid-column-end: 13;
  grid-row-start: 2;
  grid-row-end: 6;
  padding: 10px;
  z-index: 1;
}

.cp-wrap .cp-anim .inbound-text {
  background-color: #70212F;
  grid-column-start: 2;
  grid-column-end: 6;
  grid-row-start: 11;
  grid-row-end: 14;
  padding: 10px;
  z-index: 1;
}

.cp-wrap .cp-anim .chtapp-anim-wrap img {
  max-width: 100%;
}
.cp-wrap .cp-anim .green-line-anim-wrap {
  position: relative;
  grid-column-start: 8;
  grid-column-end: 15;
  grid-row-start: 1;
  grid-row-end: 7;
}
.cp-wrap .cp-anim .green-line-anim-wrap .line {
  background-image: linear-gradient(90deg, #1A8C33 50%, transparent 50%), linear-gradient(90deg, #1A8C33 50%, transparent 50%), linear-gradient(0, #1A8C33 50%, transparent 50%), linear-gradient(0, #1A8C33 50%, transparent 50%);
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100% / 12 - 4px);
  bottom: calc(100% / 12 - 4px);
}
.cp-wrap .cp-anim .green-line-anim-wrap .line-cover {
  background-color: #70212F;
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100% / 12 + 4px);
  bottom: calc(100% / 12 + 4px);
}
.cp-wrap .cp-anim .red-line-anim-wrap {
  position: relative;
  grid-column-start: 4;
  grid-column-end: 8;
  grid-row-start: 5;
  grid-row-end: 7;
}
.cp-wrap .cp-anim .red-line-anim-wrap .line {
  background-image: linear-gradient(90deg, #FF385C 50%, transparent 50%), linear-gradient(90deg, #FF385C 50%, transparent 50%), linear-gradient(0, #FF385C 50%, transparent 50%), linear-gradient(0, #FF385C 50%, transparent 50%);
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100% / 4 - 4px);
  bottom: calc(100% / 4 - 4px);
}
.cp-wrap .cp-anim .red-line-anim-wrap .line-cover {
  background-color: #70212F;
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100% / 4 - 4px);
  bottom: calc(100% / 4 + 4px);
}
.cp-wrap .cp-anim .red-line-anim-wrap1 {
  position: relative;
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 6;
  grid-row-end: 8;
}
.cp-wrap .cp-anim .red-line-anim-wrap1 .line {
  background-image: linear-gradient(90deg, #FF385C 50%, transparent 50%), linear-gradient(90deg, #FF385C 50%, transparent 50%), linear-gradient(0, #FF385C 50%, transparent 50%), linear-gradient(0, #FF385C 50%, transparent 50%);
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100% / 4 - 4px);
  bottom: calc(100% / 4 - 4px);
}
.cp-wrap .cp-anim .red-line-anim-wrap1 .line-cover {
  background-color: #70212F;
  position: absolute;
  left: 0;
  right: 8px;
  top: calc(100% / 4 - 4px);
  bottom: calc(100% / 4 + 4px);
}
.cp-wrap .cp-anim .red-line-anim-wrap2 {
  position: relative;
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 8;
  grid-row-end: 12;
}
.cp-wrap .cp-anim .red-line-anim-wrap2 .line {
  background-image: linear-gradient(90deg, #FF385C 50%, transparent 50%), linear-gradient(90deg, #FF385C 50%, transparent 50%), linear-gradient(0, #FF385C 50%, transparent 50%), linear-gradient(0, #FF385C 50%, transparent 50%);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: calc(100% / 4 - 4px);
}
.cp-wrap .cp-anim .red-line-anim-wrap2 .line-cover {
  background-color: #70212F;
  position: absolute;
  top: 0;
  bottom: 0;
  left: calc(100% / 4 + 4px);
  right: calc(100% / 4 - 4px);
}
.cp-wrap .cp-anim .red-circle-anim-wrap {
  position: relative;
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 6;
  grid-row-end: 8;
}
.cp-wrap .cp-anim .red-circle-anim-wrap .circ-wrap {
  position: absolute;
  inset: calc(100% / 4 - 7px);
  animation: rotation-to-left 7s infinite linear;
}
.cp-wrap .cp-anim .red-circle-anim-wrap svg {
  width: 100%;
  aspect-ratio: 1/1;
  stroke-dasharray: calc(100% / 6);
  stroke: #FF385C;
}
.cp-wrap .cp-anim .red-circle-anim-wrap .circle-cover {
  position: absolute;
  inset: 0;
  background-color: #70212F;
  clip-path: polygon(50% 50%, 50% 0, 100% 0, 100% 100%, 0 100%, 0 50%);
}
.cp-wrap .cp-anim .dred-line-anim-wrap {
  position: relative;
  grid-column-start: 4;
  grid-column-end: 14;
  grid-row-start: 10;
  grid-row-end: 14;
}
.cp-wrap .cp-anim .dred-line-anim-wrap .line {
  background-image: linear-gradient(90deg, #D10000 50%, transparent 50%), linear-gradient(90deg, #D10000 50%, transparent 50%), linear-gradient(0, #D10000 50%, transparent 50%), linear-gradient(0, #D10000 50%, transparent 50%);
  position: absolute;
  top: calc(100% / 8 - 4px);
  bottom: calc(100% / 8 - 4px);
  left: calc(100% / 20 - 4px);
  right: calc(100% / 20 - 4px);
}
.cp-wrap .cp-anim .dred-line-anim-wrap .line-cover {
  background-color: #70212F;
  position: absolute;
  top: calc(100% / 8 - 4px);
  bottom: calc(100% / 8 + 4px);
  left: calc(100% / 20 - 4px);
  right: calc(100% / 20 + 4px);
}

.campaigns-3p-animation {
  // aspect-ratio: 7 / 4;
  padding-top: calc(100% * 4 / 7);
  position: relative;
  display: block;
  width: 100%;
}
.campaigns-3p-animation .main-svg {
  width: auto;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}
.campaigns-3p-animation img {
  max-width: 100%;
  transition: all 0.3s linear;
}
.campaigns-3p-animation img.hidden {
  opacity: 0;
}
.campaigns-3p-animation .cp-moving-lines {
  animation: dash 5s infinite;
}
@keyframes dash {
  to {
    stroke-dashoffset: 700;
  }
  from {
    stroke-dashoffset: 1000;
  }
}
.campaigns-3p-animation .heartbeat-opacity {
  animation: heartbeatopacity 1.2s infinite;
}
@keyframes heartbeatopacity {
  0% {
    /* transform: rotate(45deg) scale(0.8); */
    opacity: 1;
  }
  5% {
    /* transform: rotate(45deg) scale(0.9); */
    opacity: .3;
  }
  10% {
    /* transform: rotate(45deg) scale(0.8); */
    opacity: .7;
  }
  15% {
    /* transform: rotate(45deg) scale(1); */
    opacity: .3;
  }
  50% {
    /* transform: rotate(45deg) scale(0.8); */
    opacity: 1;
  }
  100% {
    /* transform: rotate(45deg) scale(0.8); */
    opacity: 1;
  }
}
.campaigns-3p-animation .c3p-phone-image {
  position: absolute;
  top: 15%;
  left: 0;
  width: 72%;
  z-index: -1;
}
.campaigns-3p-animation .c3p-phone-video {
  position: absolute;
  top: 3.91%;
  left: 4.1%;
  width: 36.09%;
  height: 107.2%;
  object-fit: cover;
  border-top-left-radius: 8%;
  border-top-right-radius: 8%;
  border-bottom-left-radius: 6%;
  border-bottom-right-radius: 6%;
}
.campaigns-3p-animation .c3p-r {
  transition: all 0.3s linear;
}
.campaigns-3p-animation .c3p-r1-p {
  position: absolute;
  right: 1%;
  top: 9.8%;
  width: 5%;
}
.campaigns-3p-animation .c3p-r2-p {
  position: absolute;
  right: 1%;
  top: 27.2%;
  width: 5%;
}
.campaigns-3p-animation .c3p-r3-p {
  position: absolute;
  right: 1%;
  top: 45.6%;
  width: 5%;
}
.campaigns-3p-animation .c3p-r4-p {
  position: absolute;
  right: 1%;
  top: 63.2%;
  width: 5%;
}
.campaigns-3p-animation .c3p-r5-p {
  position: absolute;
  right: 1%;
  top: 80.7%;
  width: 5%;
}
.campaigns-3p-animation .c3p-r1-l {
  position: absolute;
  right: 12.3%;
  top: 9.4%;
  width: 5%;
}
.campaigns-3p-animation .c3p-r2-l {
  position: absolute;
  right: 12.3%;
  top: 27%;
  width: 5%;
}
.campaigns-3p-animation .c3p-r3-l {
  position: absolute;
  right: 12.3%;
  top: 45.3%;
  width: 5%;
}
.campaigns-3p-animation .c3p-r4-l {
  position: absolute;
  right: 12.3%;
  top: 63.3%;
  width: 5%;
}
.campaigns-3p-animation .c3p-r5-l {
  position: absolute;
  right: 12.3%;
  top: 80.6%;
  width: 5%;
}

@keyframes wphn-1-chat-fade-in {
  0% {
    transform: translateY(20px);
    opacity: 0
  }
  10% {
    transform: translateY(20px);
    opacity: 0
  }
  11% {
    transform: translateY(0);
    opacity: 1
  }
  99% {
    transform: translateY(0);
    opacity: 1
  }
  100% {
    transform: translateY(0);
    opacity: 0
  }
}
@keyframes wphn-2-chat-fade-in {
  0% {
    transform: translateY(20px);
    opacity: 0
  }
  20% {
    transform: translateY(20px);
    opacity: 0
  }
  21% {
    transform: translateY(0);
    opacity: 1
  }
  99% {
    transform: translateY(0);
    opacity: 1
  }
  100% {
    transform: translateY(0);
    opacity: 0
  }
}
@keyframes wphn-3-chat-fade-in {
  0% {
    transform: translateY(20px);
    opacity: 0
  }
  40% {
    transform: translateY(20px);
    opacity: 0
  }
  41% {
    transform: translateY(0);
    opacity: 1
  }
  99% {
    transform: translateY(0);
    opacity: 1
  }
  100% {
    transform: translateY(0);
    opacity: 0
  }
}
@keyframes wphn-4-chat-fade-in {
  0% {
    transform: translateY(20px);
    opacity: 0
  }
  50% {
    transform: translateY(20px);
    opacity: 0
  }
  51% {
    transform: translateY(0);
    opacity: 1
  }
  99% {
    transform: translateY(0);
    opacity: 1
  }
  100% {
    transform: translateY(0);
    opacity: 0
  }
}
@keyframes wphn-5-chat-fade-in {
  0% {
    transform: translateY(20px);
    opacity: 0
  }
  70% {
    transform: translateY(20px);
    opacity: 0
  }
  71% {
    transform: translateY(0);
    opacity: 1
  }
  99% {
    transform: translateY(0);
    opacity: 1
  }
  100% {
    transform: translateY(0);
    opacity: 0
  }
}
@keyframes wphn-6-chat-fade-in {
  0% {
    transform: translateY(20px);
    opacity: 0
  }
  80% {
    transform: translateY(20px);
    opacity: 0
  }
  81% {
    transform: translateY(0);
    opacity: 1
  }
  99% {
    transform: translateY(0);
    opacity: 1
  }
  100% {
    transform: translateY(0);
    opacity: 0
  }
}
@keyframes wphn-7-chat-fade-in {
  0% {
    transform: translateY(20px);
    opacity: 0
  }
  90% {
    transform: translateY(20px);
    opacity: 0
  }
  91% {
    transform: translateY(0);
    opacity: 1
  }
  99% {
    transform: translateY(0);
    opacity: 1
  }
  100% {
    transform: translateY(0);
    opacity: 0
  }
}
.wphn-teams-animation-wrapper { text-align: center; }
.wphn-teams-animation { display: block; position: relative;}
.wphn-teams-animation-image { max-width: 100%; width: 100%; max-height: 100%; }
.wphn-teams-animation-image-mobile { display: none; max-width: 100%; }
.wphn-teams-animation-desktop-chat-wrapper {
  position: absolute;
  left: 53%;
  right: 16%;
  bottom: 24%;
  top: 19%;
}
#wphn-d-1-chat {
  max-width: 49%;
  margin-left: auto;
  margin-bottom: 1%;
  animation: wphn-1-chat-fade-in 10s ease-in-out infinite;
}
#wphn-d-2-chat {
  width: 100%;
  max-width: 54%;
  padding-right: 3%;
  margin-right: auto;
  margin-left: 0;
  margin-bottom: 1%;
  animation: wphn-2-chat-fade-in 10s ease-in-out infinite;
}
#wphn-d-3-chat {
  width: 100%;
  max-width: 54%;
  padding-right: 3%;
  margin-right: auto;
  margin-left: 0;
  margin-bottom: 1%;
  animation: wphn-3-chat-fade-in 10s ease-in-out infinite;
}
#wphn-d-4-chat {
  max-width: 49%;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 1.5%;
  animation: wphn-4-chat-fade-in 10s ease-in-out infinite
}
#wphn-d-5-chat {
  width: 100%;
  max-width: 54%;
  padding-right: 3%;
  margin-right: auto;
  margin-left: 0;
  margin-bottom: 1%;
  animation: wphn-5-chat-fade-in 10s ease-in-out infinite;
}
#wphn-d-6-chat {
  max-width: 30%;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 1.5%;
  animation: wphn-6-chat-fade-in 10s ease-in-out infinite;
}
#wphn-d-7-chat {
  max-width: 41%;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 2%;
  animation: wphn-7-chat-fade-in 10s ease-in-out infinite
}
.wphn-teams-animation-mobile-chat-wrapper {
  position: absolute;
  left: 12%;
  right: 12.5%;
  bottom: 15%;
  top: 13%;
}
#wphn-p-1-chat {
  max-width: 87%;
  margin-left: auto;
  margin-bottom: 2%;
  animation: wphn-1-chat-fade-in 10s ease-in-out infinite;
}
#wphn-p-2-chat {
  max-width: 78%;
  margin-right: auto;
  margin-left: 9%;
  margin-bottom: 2%;
  animation: wphn-2-chat-fade-in 10s ease-in-out infinite;
}
#wphn-p-3-chat {
  max-width: 87%;
  margin-right: auto;
  margin-bottom: 2%;
  animation: wphn-3-chat-fade-in 10s ease-in-out infinite;
}
#wphn-p-4-chat {
  max-width: 87%;
  margin-left: auto;
  margin-bottom: 2%;
  animation: wphn-4-chat-fade-in 10s ease-in-out infinite;
}
#wphn-p-5-chat {
  max-width: 87%;
  margin-right: auto;
  margin-bottom: 2%;
  animation: wphn-5-chat-fade-in 10s ease-in-out infinite;
}
#wphn-p-6-chat {
  max-width: 30%;
  margin-left: auto;
  margin-bottom: 2%;
  animation: wphn-6-chat-fade-in 10s ease-in-out infinite;
}
#wphn-p-7-chat {
  max-width: 54%;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 2%;
  animation: wphn-7-chat-fade-in 10s ease-in-out infinite;
}


@media (max-width: 993px){
  .cp-wrap .cp-anim .red-line-anim-wrap1 {
    display: none;
  }
  
  .cp-wrap {
    aspect-ratio: unset;

    // Fallback
    &::before,
    &::after  {
      display: none;
    }
  }

  .cp-wrap .cp-anim {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(15, auto);
    gap: 50px;
  }

  .cp-wrap .cp-anim .cmp-anim-wrap {
    aspect-ratio: 8 / 5;
    pointer-events: none;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
    z-index: 1;
  }

  .cp-wrap .cp-anim .wphn-anim-wrap {
    pointer-events: none;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 4;
    grid-row-end: 6;
  }
  
  .cp-wrap .cp-anim .wpdt-anim-wrap {
    pointer-events: none;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 6;
    grid-row-end: 8;
  }
  
  .cp-wrap .cp-anim .chtapp-anim-wrap {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 10;
    grid-row-end: 12;
  }
  
  .cp-wrap .cp-anim .outbound-text {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
  }
  
  .cp-wrap .cp-anim .inbound-text {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 8;
    grid-row-end: 10;
  }

  .cp-wrap .cp-anim .green-line-anim-wrap {
    position: relative;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 5;
  }
  .cp-wrap .cp-anim .green-line-anim-wrap .line {
    left: 0;
    right: calc(50% - 4px);
    top: 0;
    bottom: 0;
  }
  .cp-wrap .cp-anim .green-line-anim-wrap .line-cover {
    left: 0;
    right: calc(50% + 4px);
    top: 0;
    bottom: 0;
  }
  .cp-wrap .cp-anim .red-line-anim-wrap {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 5;
    grid-row-end: 7;
  }
  .cp-wrap .cp-anim .red-line-anim-wrap .line {
    left: 0;
    right: calc(50% - 4px);
    top: 0;
    bottom: 0;
  }
  .cp-wrap .cp-anim .red-line-anim-wrap .line-cover {
    left: 0;
    right: calc(50% + 4px);
    top: 0;
    bottom: 0;
  }
  .cp-wrap .cp-anim .red-line-anim-wrap2 {
    display: none;
  }
  .cp-wrap .cp-anim .red-circle-anim-wrap {
    display: none;
  }
  .cp-wrap .cp-anim .dred-line-anim-wrap {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 7;
    grid-row-end: 11;
  }
  .cp-wrap .cp-anim .dred-line-anim-wrap .line {
    right: 0;
    left: calc(50% - 4px);
    top: 0;
    bottom: 0;
  }
  .cp-wrap .cp-anim .dred-line-anim-wrap .line-cover {
    right: 0;
    left: calc(50% + 4px);
    top: 0;
    bottom: 0;
  }
  .wphn-anim-wrap .wphn-teams-animation-wrapper { 
    max-width: 240px;
    margin: auto;
   }
}