.links-wrapper {
  background-color: #70212f;
  padding: 50px 20px;
  overflow: auto;
  
  .links-inner {
    max-width: 700px;
    margin: 20px auto;

    .links {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      margin: 0 0 50px 0;

      a {
        background: linear-gradient(180deg, #9BC853 0%, #6A991F 100%);
        box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.25);
        border-radius: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;

        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 1;
        color: #FFFFFF;
        min-height: 115px;

        &.disable {
          pointer-events: none;
          background: linear-gradient(180deg, #EBEBF1 0%, #4D4D4F 96.62%, #4D4D4F 100%);
        }
      }
    }

    @media (max-width: 1023px) {
      .links {
        margin: 0 0 20px 0;

        a {
          font-size: 24px;
          min-height: 75px;
        }
      }

      @media (max-width: 767px) {
        .links {
          grid-template-columns: 1fr;

          a {
            font-size: 24px;
            min-height: 75px;
          }
        }
      }
    }
  }
}