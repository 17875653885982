.bottom-content-wrapper {
  background-image: url('https://d365pq86x330zn.cloudfront.net/216i7661-6669-4116-6701-15551Bu6ii97.png');
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  box-sizing: border-box;
  padding: 70px 30px;
}
.phone-to-book-a-demo {
  max-width: 1160px;
  margin: auto;
  text-align: center;
}
.your-host-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 50px;
  max-width: 1160px;
  margin: auto;
  padding-bottom: 120px;
}
.your-host-image img {
  max-width: 100%;
}
.single-host {
  margin: 0 0 50px;
}
.your-host-title {
  max-width: 1160px;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 40px;
  color: #565A5C;
  margin: 0 auto 25px;
}
.your-host-wrapper h4 {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 34px;
  color: #565A5C;
  margin: 0 0 15px;

  display: flex;
  align-items: center;
}
.your-host-wrapper em {
  text-transform: capitalize;
  font-weight: 400;
  font-size: 20px;
  color: #565A5C;
  margin: 0 0 15px;
  display: block;
}
.your-host-wrapper p {
  font-weight: 500;
  font-size: 20px;
  color: #565A5C;
  line-height: 1.4;
  margin: 0 0 15px;
}
.your-host-wrapper h4 a {
  display: flex;
}
.phone-to-book-a-demo h2 {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 48px;
  color: #FF385C;
  margin: 0 0 25px;
}
.phone-to-book-a-demo-phone {
  width: 50%;
  padding: 10px;
  text-align: center;
  margin: auto;
}
.phone-wrapper {
  display: inline-block;
  position: relative;
  margin: auto;
}
.phone-wrapper .phone {
  max-width: 100%;
  margin: auto;
}
.phone-content {
  position: absolute;
  top: 40%;
  left: 2%;
  right: 0;
  display: flex;
  justify-content: center;
}
.phone-content .btn {
  padding: 12px 24px;
  font-size: 14px;
}

@media (max-width: 1023px) {
  .phone-to-book-a-demo {
    flex-flow: column;
  }
  .phone-to-book-a-demo-phone {
    width: auto;
  }
}

@media (max-width: 767px) {
  .phone-wrapper {
    width: auto;
  }
  .phone-wrapper .phone {
    opacity: 0;
    display: none;
  }
  .phone-wrapper .phone-content {
    position: relative;
  }
  .your-host-wrapper {
    display: block;
  }
  .your-host-image {
    text-align: center;
    margin-bottom: 50px;
  }
}