
.ai-centre-form-wrapper,
.ai-centre-posts-wrapper {
  padding: 50px 30px;
  background-color: #fff;
  background-image: url('https://d365pq86x330zn.cloudfront.net/o3rr160r-r440-41G0-4612-71661422G6r7.png');
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  box-sizing: border-box;
}
.ai-centre-form-wrapper img,
.ai-centre-posts-wrapper img {
  max-width: 100%;
}
.ai-centre-form,
.ai-centre-posts {
  max-width: 1047px;
  margin: auto;
}
.ai-centre-form-text {
  max-width: 980px;
  margin: auto;
  text-align: left;
  padding: 30px 0;
}
.ai-centre-form-text .splitter {
  width: 225px;
  height: 10px;
  background: #FF385C;
  margin: 20px 0 30px;
}
.ai-centre-form-text h1 {
  font-weight: 700;
  font-size: 64px;
  color: #ffffff;
  margin: 0;
}
.ai-centre-form-text h1 .red {
  color: #FF385C;
}
.ai-centre-form-text p {
  font-weight: 400;
  font-size: 24px;
  color: #ffffff;
  margin: 0 0 2px;
  max-width: 450px;
}
.ai-centre-form-fields {
  max-width: 900px;
  margin: auto;
}
.ai-centre-form-fields .form-row {
  display: flex;
}
.ai-centre-form-fields .form-col {
  flex: 1 1 auto;
  width: 50%;
  padding: 20px 10px;
}
.ai-centre-form-fields .form-group {
  display: flex;
}
.ai-centre-form-fields .form-group label {
  min-width: 85px;
  font-weight: 400;
  font-size: 16px;
  color: #565A5C;
  text-align: right;
  padding: 10px;
}
.ai-centre-form-fields .form-group input {
  flex: 1 1 auto;
  background: #FFFFFF;
  border: 1px solid #C9C9C9;
  border-radius: 5px;
  padding: 10px;
}


.ai-centre-posts-wrapper {
  padding: 70px 30px;
  background: #F8F8F8;
}
.ai-centre-posts-intro {
  margin-top: -140px;
  display: flex;
  background-color: #FF385C;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.ai-centre-posts-intro-img {
  width: 47%;
  background-image: url('https://d365pq86x330zn.cloudfront.net/c1670102-7125-47e8-0518-75c111R0267e.png');
  background-size: cover;
  background-position: top left;

}
.ai-centre-posts-intro-text {
  width: 53%;
  padding: 70px 50px;
}
.ai-centre-posts-intro-text h2 {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 32px;
  color: #fff;
  margin: 0 0 35px;
}
.ai-centre-posts-intro-text p {
  font-weight: 400;
  font-size: 20px;
  color: #fff;
  line-height: 1.7;
  margin: 0 0 40px;
}
.ai-centre-posts-intro-text .b2c {
  padding: 17px;
  border-color: #fff;
  background-color: transparent;
  color: #fff;
  margin-right: 20px;
  margin-bottom: 20px;
}
.ai-centre-posts-intro-text .b2b {
  padding: 17px;
  color: #FF385C;
  background-color: #fff;
}

.ai-centre-posts-single {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
}
.ai-centre-posts-single-text,
.ai-centre-posts-single-img {
  flex: 1 1 auto;
  width: 50%;
}
.ai-centre-posts-single-text {
  padding: 50px 30px;
}
.ai-centre-posts-single-text h2 {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 32px;
  color: #565A5C;
  margin: 0 0 35px;
}
.ai-centre-posts-single-text p {
  font-weight: 400;
  font-size: 20px;
  color: #565A5C;
  line-height: 1.7;
  margin: 0 0 40px;
}
.ai-centre-posts-single.reverse .ai-centre-posts-single-img {
  order: 1;
}
.ai-centre-posts-single.reverse .ai-centre-posts-single-text {
  order: 2;
}
.ai-centre-posts-single.reverse .ai-centre-posts-single-text .btn {
  margin-left: auto;
  display: block;
  width: fit-content;
}

.ai-centre-form-fields .submit {
  width: 100%;
}
.ai-centre-form-fields .submit.disabled {
  pointer-events: none;
  opacity: .7;
}
.ai-centre-posts-wrapper .pagination {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
  padding: 0 5rem;
  max-width: max-content;
  margin: auto;
}
.ai-centre-posts-wrapper .pagination li a {
  border-radius: 0;
  padding: 0.1rem 1rem;
  border: gray 1px solid;
  cursor: pointer;
}
.ai-centre-posts-wrapper .pagination li.previous a,
.ai-centre-posts-wrapper .pagination i.next a,
.ai-centre-posts-wrapper .pagination li.break a {
  border-color: transparent;
}
.ai-centre-posts-wrapper .pagination li.active a {
  background-color: #F5375D;
  border-color: transparent;
  color: white;
  min-width: 32px;
}
.ai-centre-posts-wrapper .pagination li.disabled a {
  color: grey;
}
.ai-centre-posts-wrapper .pagination li.disable,
.ai-centre-posts-wrapper .pagination li.disabled a {
  cursor: default;
}


@media (max-width: 1023px) {
  .ai-centre-form-wrapper,
  .ai-centre-posts-wrapper {
    padding: 30px;
  }
  .ai-centre-form-wrapper {
    background-image: url('https://d365pq86x330zn.cloudfront.net/69I92611-9912-4A2I-8989-29I226289666.png'), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #131313 70%);
  }
  .ai-centre-posts-intro {
    margin-top: 0;
  }
  .ai-centre-posts-intro {
    flex-direction: column;
  }
  .ai-centre-posts-intro-img,
  .ai-centre-posts-intro-text {
    width: auto;
  }
  .ai-centre-posts-intro-img {
    padding-top: 70%;
  }
  .ai-centre-posts-single {
    flex-direction: column;
  }
  .ai-centre-posts-single .ai-centre-posts-single-img,
  .ai-centre-posts-single.reverse .ai-centre-posts-single-img {
    order: 1;
    width: auto;
  }
  .ai-centre-posts-single .ai-centre-posts-single-text,
  .ai-centre-posts-single.reverse .ai-centre-posts-single-text {
    order: 2;
    width: auto;
  }
  .ai-centre-posts-single.reverse .ai-centre-posts-single-text .btn {
    margin-left: 0;
    display: block;
  }
  .ai-centre-form-fields .form-row {
    flex-direction: column;
  }
  .ai-centre-form-fields .form-col {
    width: auto;
  }
}

@media (max-width: 767px) {
  .ai-centre-form-text h1 {
    font-size: 48px;
  }
  .ai-centre-form-text p {
    font-size: 18px;
  }
}