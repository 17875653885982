.pricing-options {
  background-color: #70212f;
  padding: 50px 20px;
  overflow: auto;
  
  .pricing-options-inner {
    max-width: 1024px;
    margin: 20px auto;

    h2 {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 52px;
      line-height: 1.2;
      color: #FFFFFF;
      margin: 0 0 10px 0;
      text-align: center;
    }

    p {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 1.2;
      color: #FFFFFF;
      margin: 0 0 20px 0;
      text-align: center;
    }

    .options {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      padding: 50px 0;
      max-width: 550px;
      margin: auto;

      .option {
        min-height: 148px;
        padding: 50px;
        background: #FFFFFF;
        box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.2);
        border-radius: 20px;
        padding: 20px 15px 15px 15px;
        position: relative;

        .mp-recommends {
          position: absolute;
          top: -20px;
          left: 20px;
          right: 20px;
          width: calc(100% - 40px);
          box-sizing: border-box;
          background: #FF385C;
          padding: 15px 10px;
          text-align: center;

          font-family: 'Montserrat',sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 1;
          color: #FFFFFF;
        }

        h3 {
          font-family: 'Montserrat', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 1.2;
          color: #565A5C;
          margin: 0 0 10px 0;
        }

        p {
          font-family: 'Open Sans', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 1.2;
          color: #565A5C;
          text-align: left;
          margin: 0 0 10px 0;
          min-height: 60px;
        }

        a {
          display: block;
          font-family: 'Open Sans', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 1;
          color: #fff;
          background-color: #565A5C;
          padding: 12px 34px;
          margin: 0 auto 20px auto;
          width: fit-content;
          text-align: center;
          border-radius: 30px;
          text-transform: uppercase;
        }

        span {
          display: block;
          font-family: 'Open Sans', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 1;
          color: #000;
          margin: auto;
          text-align: center;
        }

        .per-month {
          padding: 15px 0 24px;

          h4 {
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 64px;
            line-height: 1;
            color: #565A5C;
            margin: 0;
            text-align: center;
          }
          h5 {
            font-family: 'Open Sans', sans-serif;
            font-style: italic;
            font-weight: 600;
            font-size: 24px;
            line-height: 1;
            color: #565A5C;
            margin: 0;
            text-align: center;
          }
          h6 {
            font-family: 'Open Sans', sans-serif;
            font-style: italic;
            font-weight: 600;
            font-size: 16px;
            line-height: 1;
            color: #565A5C;
            margin: 0;
            text-align: center;
          }
        }

        .splitter {
          background-color: #D1D1D1;
          width: 100%;
          height: 1px;
          margin: 10px 0;
        }
      }
    }

    @media (max-width: 1023px) {
      h2 {
        font-size: 32px;
      }

      p {
        font-size: 20px;
      }

      @media (max-width: 767px) {
        h2 {
          font-size: 28px;
        }

        p {
          font-size: 16px;
        }

        .options {
          grid-template-columns: 1fr;
        }
      }
    }
  }

  &.communicate-outbound {
    h2 {
      font-size: 40px;
    }
    p {
      font-size: 24px;
    }

    .options {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 20px;
      max-width: 1024px;

      .option {
        padding-top: 50px;
        display: flex;
        flex-direction: column;

        span {
          font-size: 16px;
          font-weight: 600;
          display: block;
          padding: 10px 0;
        }

        .listing-spacer {
          flex: 1 1 auto;
        }

        .listing-title {
          font-family: 'Open Sans', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 1.2;
          color: #000;
          padding-top: 20px;
          padding-bottom: 20px;
        }

        .listing {
          display: grid;
          grid-template-columns: 16px auto;
          gap: 16px;
          padding-bottom: 20px;

          .listing-single {
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 1.2;
          }
        }
      }
    }

    @media (max-width: 1023px) {
      h2 {
        font-size: 32px;
      }

      p {
        font-size: 20px;
      }

      .options {
        grid-template-columns: 1fr 1fr;
      }

      @media (max-width: 767px) {
        h2 {
          font-size: 28px;
        }

        p {
          font-size: 16px;
        }

        .options {
          grid-template-columns: 1fr;
        }
      }
    }
  }
}