.iframeds-hpac-portal-wrapper {
  padding: 70px 20px;
  background-color: #fff;
  max-width: 100vw;
  overflow: hidden;
}
.iframeds-hpac-portal-wrapper .iframeds-hpac-portal {
  max-width: 1160px;
  margin: auto;
}
.iframeds-hpac-portal-wrapper .iframeds-hpac-portal-inner {
  display: inline-block;
  margin: auto;
  position: relative;
}
.iframeds-hpac-portal-wrapper .iframeds-hpac-portal-inner > img {
  max-width: 100%;
}
.iframeds-hpac-portal-wrapper .iframeds-hpac-portal iframe {
  border: none;
  outline: none;
  display: block;
  background-color: #ffffff;
  top: 10.8%;
  left: 16.4%;
  width: 92.7%;
  height: 96%;
  border-top-left-radius: 3%;
  border-top-right-radius: 3%;
  position: absolute;
  transform: scale(.75);
  transform-origin: 0 0;
}
.iframeds-hpac-portal-wrapper h2 {
  font-weight: 700;
  font-size: 96px;
  color: #333;
  margin: 0 auto 20px;
  text-align: center;
}
.iframeds-button {
  border: none;
  outline: none;
  background: #303030;
  border-radius: 15px;
  padding: 15px;
  display: block;
  width: 100%;
  max-width: 272px;
  margin: auto;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
}

.qoute-ads {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: center;
}
.qoute-ads img {
  width: 100%;
  max-width: 280px;
  margin: auto;
}
.qoute-ads h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  text-align: center;

  color: #303030;
}

@media (max-width: 767px) {
  .iframeds-hpac-portal-wrapper {
    padding: 20px;
  }
  .iframeds-hpac-portal-wrapper .iframeds-hpac-portal {
    grid-template-columns: 1fr;
  }
  .iframeds-hpac-portal-wrapper h2 {
    font-size: 32px;
  }
  .qoute-ads {
    grid-template-columns: 1fr;
    grid-gap: 40px;
  }
}