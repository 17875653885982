@keyframes ms-1-chat-fade-in {
  0% {
    transform: translateY(20px);
    opacity: 0
  }
  10% {
    transform: translateY(20px);
    opacity: 0
  }
  11% {
    transform: translateY(0);
    opacity: 1
  }
  99% {
    transform: translateY(0);
    opacity: 1
  }
  100% {
    transform: translateY(0);
    opacity: 0
  }
}
@keyframes ms-2-chat-fade-in {
  0% {
    transform: translateY(20px);
    opacity: 0
  }
  20% {
    transform: translateY(20px);
    opacity: 0
  }
  21% {
    transform: translateY(0);
    opacity: 1
  }
  99% {
    transform: translateY(0);
    opacity: 1
  }
  100% {
    transform: translateY(0);
    opacity: 0
  }
}
@keyframes ms-3-chat-fade-in {
  0% {
    transform: translateY(20px);
    opacity: 0
  }
  40% {
    transform: translateY(20px);
    opacity: 0
  }
  41% {
    transform: translateY(0);
    opacity: 1
  }
  99% {
    transform: translateY(0);
    opacity: 1
  }
  100% {
    transform: translateY(0);
    opacity: 0
  }
}
@keyframes ms-4-chat-fade-in {
  0% {
    transform: translateY(20px);
    opacity: 0
  }
  50% {
    transform: translateY(20px);
    opacity: 0
  }
  51% {
    transform: translateY(0);
    opacity: 1
  }
  99% {
    transform: translateY(0);
    opacity: 1
  }
  100% {
    transform: translateY(0);
    opacity: 0
  }
}
@keyframes ms-5-chat-fade-in {
  0% {
    transform: translateY(20px);
    opacity: 0
  }
  70% {
    transform: translateY(20px);
    opacity: 0
  }
  71% {
    transform: translateY(0);
    opacity: 1
  }
  99% {
    transform: translateY(0);
    opacity: 1
  }
  100% {
    transform: translateY(0);
    opacity: 0
  }
}
@keyframes ms-6-chat-fade-in {
  0% {
    transform: translateY(20px);
    opacity: 0
  }
  80% {
    transform: translateY(20px);
    opacity: 0
  }
  81% {
    transform: translateY(0);
    opacity: 1
  }
  99% {
    transform: translateY(0);
    opacity: 1
  }
  100% {
    transform: translateY(0);
    opacity: 0
  }
}
@keyframes ms-7-chat-fade-in {
  0% {
    transform: translateY(20px);
    opacity: 0
  }
  90% {
    transform: translateY(20px);
    opacity: 0
  }
  91% {
    transform: translateY(0);
    opacity: 1
  }
  99% {
    transform: translateY(0);
    opacity: 1
  }
  100% {
    transform: translateY(0);
    opacity: 0
  }
}
.ms-teams-assistants-animation-wrapper { 
  text-align: right; 
  /* position: absolute;
  top: 9%;
  width: 68%;
  left: 2%; */
}
.ms-teams-assistants-animation { display: inline-block; position: relative; }
.ms-teams-assistants-animation-image { max-width: 100%; }
.ms-teams-assistants-animation-desktop-chat-wrapper {
  position: absolute;
  left: 54%;
  right: 4%;
  bottom: 28%;
  top: 20%;
}
#ms-d-1-chat {
  max-width: 83%;
  margin-right: auto;
  margin-bottom: 2%;
  animation: ms-1-chat-fade-in 10s ease-in-out infinite;
}
#ms-d-2-chat {
  max-width: 31%;
  padding-right: 3%;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 2%;
  animation: ms-2-chat-fade-in 10s ease-in-out infinite;
}
#ms-d-3-chat {
  max-width: 62%;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 2%;
  animation: ms-3-chat-fade-in 10s ease-in-out infinite;
}
#ms-d-4-chat {
  max-width: 73%;
  margin-left: 0;
  margin-right: auto;
  margin-bottom: 2%;
  animation: ms-4-chat-fade-in 10s ease-in-out infinite
}
#ms-d-5-chat {
  max-width: 54%;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 2%;
  animation: ms-5-chat-fade-in 10s ease-in-out infinite;
}
#ms-d-6-chat {
  max-width: 72%;
  margin-left: 0;
  margin-right: auto;
  margin-bottom: 2%;
  animation: ms-6-chat-fade-in 10s ease-in-out infinite;
}
#ms-d-7-chat {
  max-width: 42%;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 2%;
  animation: ms-7-chat-fade-in 10s ease-in-out infinite
}
.ms-teams-assistants-animation-mobile-chat-wrapper {
  position: absolute;
  left: 10%;
  right: 73.5%;
  bottom: 15%;
  top: 44%;
}
#ms-p-1-chat {
  max-width: 79%;
  margin-right: auto;
  margin-bottom: 2%;
  animation: ms-1-chat-fade-in 10s ease-in-out infinite;
}
#ms-p-2-chat {
  max-width: 43%;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 2%;
  animation: ms-2-chat-fade-in 10s ease-in-out infinite;
}
#ms-p-3-chat {
  max-width: 70%;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 2%;
  animation: ms-3-chat-fade-in 10s ease-in-out infinite;
}
#ms-p-4-chat {
  max-width: 78%;
  margin-right: auto;
  margin-bottom: 2%;
  animation: ms-4-chat-fade-in 10s ease-in-out infinite;
}
#ms-p-5-chat {
  max-width: 77%;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 2%;
  animation: ms-5-chat-fade-in 10s ease-in-out infinite;
}
#ms-p-6-chat {
  max-width: 32%;
  margin-right: auto;
  margin-bottom: 2%;
  animation: ms-6-chat-fade-in 10s ease-in-out infinite;
}
#ms-p-7-chat {
  max-width: 55%;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 2%;
  animation: ms-7-chat-fade-in 10s ease-in-out infinite;
}