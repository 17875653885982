.take-me-to {
  background-color: #70212f;
  padding: 50px 20px;
  overflow: auto;
  
  .take-me-to-inner {
    max-width: 1024px;
    margin: 20px auto;

    h2 {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 52px;
      line-height: 1.2;
      color: #FFFFFF;
      margin: 0 0 50px 0;
      text-align: center;
    }

    img {
      max-width: 100%;
    }

    .links {
      display: grid;
      grid-template-columns: auto auto auto;
      gap: 20px;
      margin: 0 0 50px 0;

      a {
        background: linear-gradient(180deg, #9BC853 0%, #6A991F 100%);
        box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.25);
        border-radius: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;

        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 1;
        color: #FFFFFF;
        min-height: 115px;

        &.disable {
          pointer-events: none;
          background: linear-gradient(180deg, #EBEBF1 0%, #4D4D4F 96.62%, #4D4D4F 100%);
        }
      }
    }

    @media (max-width: 1023px) {
      h2 {
        font-size: 32px;
        margin: 0 0 20px 0;
      }

      .links {
        margin: 0 0 20px 0;

        a {
          font-size: 24px;
          min-height: 75px;
        }
      }

      @media (max-width: 767px) {
        h2 {
          font-size: 28px;
        }

        .links {
          grid-template-columns: 1fr;

          a {
            font-size: 24px;
            min-height: 75px;
          }
        }
      }
    }
  }
}