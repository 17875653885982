.pricing-banner-wrapper {
  background-color: #FF385C;
}
.pricing-banner {
  text-align: center;
  padding: 50px 20px;
}
.pricing-banner h2 {
  font-weight: 700;
  font-size: 48px;
  color: #fff;
  margin: 0 0 15px;
}
.pricing-banner p {
  font-weight: 400;
  font-size: 20px;
  color: #fff;
  margin: 0 0 15px;
}