@keyframes dasheffect {
  to {
    stroke-dashoffset: 700;
  }
  from {
    stroke-dashoffset: 1000;
  }
}

@keyframes heartbeateffect {
  0% {
    /* transform: rotate(45deg) scale(0.8); */
    opacity: 1;
  }
  5% {
    /* transform: rotate(45deg) scale(0.9); */
    opacity: .3;
  }
  10% {
    /* transform: rotate(45deg) scale(0.8); */
    opacity: .7;
  }
  15% {
    /* transform: rotate(45deg) scale(1); */
    opacity: .3;
  }
  50% {
    /* transform: rotate(45deg) scale(0.8); */
    opacity: 1;
  }
  100% {
    /* transform: rotate(45deg) scale(0.8); */
    opacity: 1;
  }
}

.communicate-animation {
  position: relative;
  display: inline-block;
  max-width: 100%;
}
.communicate-animation img,
.communicate-animation svg {
  max-width: 100%;
  transition: all 0.3s linear;
}
.communicate-animation img.hidden {
  opacity: 0;
}
.communicate-animation .moving-lines {
  animation: dasheffect 5s infinite;
}
.communicate-animation .heartbeat-opacity {
  animation: heartbeateffect 1.2s infinite;
}
.communicate-animation .cn-phone-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 40%;
}
.communicate-animation .cn-phone-video {
  position: absolute;
  top: 3.4%;
  left: 3.5%;
  width: 32.3%;
  height: 96.2%;
  object-fit: cover;
  border-top-left-radius: 11%;
  border-top-right-radius: 6%;
  border-bottom-left-radius: 6%;
  border-bottom-right-radius: 8%;
}
.communicate-animation .cn-r {
  transition: all 0.3s linear;
}
.communicate-animation .cn-r1-p {
  position: absolute;
  right: 1.3%;
  top: 9.4%;
  width: 6.7%;
}
.communicate-animation .cn-r2-p {
  position: absolute;
  right: 1.3%;
  top: 26.7%;
  width: 6.7%;
}
.communicate-animation .cn-r3-p {
  position: absolute;
  right: 1.3%;
  top: 44.6%;
  width: 6.7%;
}
.communicate-animation .cn-r4-p {
  position: absolute;
  right: 1.3%;
  top: 62.3%;
  width: 6.7%;
}
.communicate-animation .cn-r5-p {
  position: absolute;
  right: 1.3%;
  top: 79.3%;
  width: 6.7%;
}
.communicate-animation .cn-r1-l {
  position: absolute;
  right: 15.3%;
  top: 9.4%;
  width: 6.7%;
}
.communicate-animation .cn-r2-l {
  position: absolute;
  right: 15.3%;
  top: 26.7%;
  width: 6.7%;
}
.communicate-animation .cn-r3-l {
  position: absolute;
  right: 15.3%;
  top: 44.6%;
  width: 6.7%;
}
.communicate-animation .cn-r4-l {
  position: absolute;
  right: 15.3%;
  top: 62.3%;
  width: 6.7%;
}
.communicate-animation .cn-r5-l {
  position: absolute;
  right: 15.3%;
  top: 79.3%;
  width: 6.7%;
}