.unfull-image-wrapper {
  padding: 50px 30px;
  background-color: #fff;
  max-width: 100vw;
  overflow: hidden;
}
.unfull-image-wrapper .unfull-image {
  max-width: 1160px;
  margin: auto;
}
.unfull-image-wrapper .unfull-image img {
  max-width: 100%;
}

@media (max-width: 767px) {
  
}